import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import StockQuantityInput from "./StockQuantityInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Period = (props) => {
  // Important pour utiliser la fonction moment day avec le nom des jours
  require("moment/locale/en-gb.js");

  const {
    keyPeriod,
    line,
    productName,
    productId,
    period,
    updateForecastStocksTempo,
  } = props;

  const weekNumber = moment(period.dates.start).format("W");

  // Calcule la date puis envoie la maj de la donnée au component parent avec updateForecastStocksTempo
  const onChangeStock = (stockQty, dayName, periodStart) => {
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    let date = moment(periodStart).day(dayName).week(weekNumber);
    date.add(1, 'days');
    updateForecastStocksTempo({
      productId: productId,
      date: date,
      stockQty: stockQty,
    });
  };

  return (
    <Container>

      <Head type={line}>
        <Row noGutters>
          <Col xs={2}>
            <p>
              {moment(period.dates.start).format("DD/MM/YY")}
              <br />
              {moment(period.dates.end).format("DD/MM/YY")}
            </p>
          </Col>
          <Col xs={2}>St</Col>
          <Col xs={1}>Ajst</Col>

          <Col xs={1}>C@</Col>
          <Col xs={1}>CD</Col>
          <Col xs={1}>Re</Col>

          <Col xs={1}>C@</Col>
          <Col xs={1}>CD</Col>

          <Col xs={1}>Re</Col>
          <Col xs={1}>Tot</Col>
        </Row>
      </Head>
      <BeforeAfter>
        <Row noGutters>
          <Col xs={2}></Col>
          <Col xs={2}></Col>
          <ColSeparator xs={1}></ColSeparator>
          <ColSeparator xs={3}>Av 15h</ColSeparator>
          <ColSeparator xs={3}>Ap 15h</ColSeparator>
          <Col xs={1}></Col>
        </Row>
      </BeforeAfter>
      <Info>
        {Object.entries(period.days).length === 0 && 'Produit indisponible dans cette période' }
        {Object.keys(period.days).map((day, key) => (
          <Day noGutters key={keyPeriod+"-"+key}>
            <Col xs={2}>
              {day === "monday"
                ? "Lu"
                : day === "tuesday"
                ? "Ma"
                : day === "wednesday"
                ? "Me"
                : day === "thursday"
                ? "Je"
                : day === "friday"
                ? "Ve"
                : day === "saturday"
                ? "Sa"
                : day === "sunday"
                ? "Di"
                : null}
            </Col>
            <Stock xs={2}>
              <StockQuantityInput
                quantity={period.days[day].stock}
                // onChangeStock={onChangeStock}
                onChange={(stockQty) => onChangeStock(stockQty, day, period.dates.start)}
                disabled={moment() > moment(period.dates.start).day(day).week(weekNumber)}
                isAvailable={period.days[day].isAvailable}
                index={key + 1}
              />
            </Stock>
            <ColSeparator xs={1}>
              {period.days[day].tweakStock ?
                <Warning>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {period.days[day].tweakStock !== -1 ? period.days[day].tweakStock : "Illimité"}
                </Warning>
                :
                "-"
              }
            </ColSeparator>
            {/* Ajout des colonnes pour commande en ligne et commande ikentoo */}
            {period.days[day].orderByType !== null ?
              <>
                <Col xs={1}>
                  { period.days[day].orderByType.shop.quantityDiff === 0
                    ?
                      period.days[day].orderByType.shop.orderQuantityBefore ? period.days[day].orderByType.shop.orderQuantityBefore : '-'
                    :
                      <Warning>
                        {
                          period.days[day].orderByType.shop.orderQuantityBefore
                        }
                      </Warning>
                  }
                </Col>
                <Col xs={1}>
                  { period.days[day].orderByType.ikentoo.quantityDiff === 0
                    ?
                      period.days[day].orderByType.ikentoo.orderQuantityBefore ? period.days[day].orderByType.ikentoo.orderQuantityBefore : '-'
                    :
                      <Warning>
                        {
                          period.days[day].orderByType.ikentoo.orderQuantityBefore
                        }
                      </Warning>
                  }
                </Col>

                <ColSeparator xs={1}>
                  {period.days[day].tweakStock ?
                      <>
                        {(period.days[day].tweakStock !== -1)
                          ? period.days[day].tweakStock - period.days[day].orderByType.ikentoo.orderQuantityBefore - period.days[day].orderByType.shop.orderQuantityBefore
                          : "-"
                        }
                      </>
                    :
                      <>
                        {(period.days[day].stock !== -1)
                          ? period.days[day].stock - period.days[day].orderByType.ikentoo.orderQuantityBefore - period.days[day].orderByType.shop.orderQuantityBefore
                          : "-"
                        }
                      </>
                  }
                </ColSeparator>
                <Col xs={1}>
                  { period.days[day].orderByType.shop.quantityDiff === 0
                    ?
                      period.days[day].orderByType.shop.orderQuantityAfter ? period.days[day].orderByType.shop.orderQuantityAfter : '-'
                    :
                      <Warning>
                        {
                          period.days[day].orderByType.shop.orderQuantityAfter
                        }
                      </Warning>
                  }
                </Col>
                <Col xs={1}>
                  { period.days[day].orderByType.ikentoo.quantityDiff === 0
                    ?
                      period.days[day].orderByType.ikentoo.orderQuantityAfter ? period.days[day].orderByType.ikentoo.orderQuantityAfter : '-'
                    :
                      <Warning>
                        {
                           period.days[day].orderByType.ikentoo.orderQuantityAfter
                        }
                      </Warning>
                  }
                </Col>
              </>
              :
              <Col xs={4}>
                {
                   period.days[day].order
                }
              </Col>
            }
            <ColSeparator xs={1}>
              {period.days[day].tweakStock ?
                  <>
                    {(period.days[day].tweakStock !== -1)
                      ? period.days[day].tweakStock - period.days[day].order
                      : "-"
                    }
                  </>
                :
                  <>
                    {(period.days[day].stock !== -1)
                      ? period.days[day].stock - period.days[day].order
                      : "-"
                    }
                  </>
              }

            </ColSeparator>

            <Col xs={1}>
              <i>
                <small>
                  {period.days[day].order}
                </small>
              </i>
            </Col>
          </Day>
        ))}
      </Info>
      <Title type={line}>{productName}</Title>
    </Container>
  );
};

const Container = styled.div`
  width: 35%;
  min-width: 470px;
  display: inline-block;
  padding: 0.5rem;
`;


const Warning = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const Day = styled(Row)`
  border-bottom: 1px solid #dedede;
`;

const Stock = styled(Col)`
  color: ${(props) => props.theme.colors.green};
  height: 26px;
`;

const Title = styled.h2`
  text-align: center;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.type === "impair"
      ? props.theme.colors.green
      : props.theme.colors.black};
  color: white;
  margin: 0rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.2);
`;

const Info = styled.div`
  background-color: #f8f8f6;
  padding: 0.5rem;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const Head = styled.div`
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  text-align:center;
  background-color: ${(props) =>
    props.type === "impair"
      ? props.theme.colors.green
      : props.theme.colors.black};
  color: white;
  border-radius: 0.4rem;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
  p {
    margin-bottom: 0rem;
    line-height: 1;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const BeforeAfter = styled.div`
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.2);
  padding: 0.1rem;
  background-color: #ccc;
  color: #111;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
  text-align: center;
  p {
    margin-bottom: 0rem;
    line-height: 1;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ColSeparator = styled(Col)`
  border-right: solid 1px #aaa;
`;

Period.propTypes = {
  line: PropTypes.string,
  productName: PropTypes.string,
  productId: PropTypes.number,
  period: PropTypes.object,
  updateForecastStocksTempo: PropTypes.func,
};

export default Period;
