import * as actionTypes from "./types";
import { createReducer } from "state/utils";

const initialPage = {
  isInit    : false,
  isLoading : true,
  metaData  : [],
};

const reducer = createReducer(initialPage)({

  [actionTypes.POST_META_DATA]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.POST_META_DATA_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
  [actionTypes.POST_META_DATA_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

});

export default reducer;
