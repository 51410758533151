import * as actionTypes from "./types";

export const getOrders = (params) => {
  return {
    type: actionTypes.ORDER_GET,
    meta: {
      async: true,
      blocking: true,
      path: `/v2/store/order/orders.json`,
      method: "GET",
      params: params,
    },
  }
}
