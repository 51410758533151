import React from "react";
import { Text, View } from "@react-pdf/renderer";

export const ProductRow = props => {
  const { product, day, type, styles } = props;
  let productQty;
  if (product.periods[0].days[day].types[type].qty === -1) {
    productQty = "∞";
  }
  else if (product.periods[0].days[day].types[type].active === false) {
    productQty = "inactif";
  }
  else {
    productQty = product.periods[0].days[day].types[type].qty;
  }

  return (
    <View style={styles.stock}>
      <Text style={(product.periods[0].days[day].types[type] !== -1 && type === 'total') ? styles.textTotal : styles.text}>
        {productQty}
      </Text>
    </View>
  );
};
