import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "../../../utils";

/* State shape
{
  customers: [],
  customerPool: {},
  spots: [],
  vehicles: [],
  ticketRestaurants: [],
  isLoading: bool,
  customerAdvantage: []
}
*/
const initData = {
  customers: [],
  customerPool: null,
  spots: [],
  vehicles: [],
  ticketRestaurants: [],
  isLoading: false,
  search: {vehicle: null, spot: null, lastname: null},
  customerAdvantage: []
}

const ticketsRestaurantsReducer = createReducer(initData)({
  [actionTypes.INIT_ADMIN_TICKET_RESTAURANT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.INIT_ADMIN_TICKET_RESTAURANT_COMPLETED]: (state, action) => {
    return { ...state,
      customers: action.payload.result.customers,
      spots: action.payload.result.spots,
      vehicles: action.payload.result.vehicles,
      ticketRestaurants: action.payload.result.ticketRestaurants,
      isLoading: false
    };
  },

  [actionTypes.UPDATE_ADMIN_TICKET_RESTAURANT_COMPLETED]: (state, action) => {
    return {
      ...state,
    };
  },

  [actionTypes.POST_POOL_OPERATIONS]: (state, action) => {
    return {
      ...state,
    };
  },
  [actionTypes.POST_POOL_OPERATIONS_COMPLETED]: (state, action) => {
    return {
      ...state,
    };
  },

  [actionTypes.GET_CUSTOMER_POOL]: (state, action) => {
    return { ...state };
  },

  [actionTypes.GET_CUSTOMER_POOL_COMPLETED]: (state, action) => {
    return { ...state,
      customerPool: action.payload.result,
    };
  },

  [actionTypes.SEARCH_ADMIN_TICKET_RESTAURANT]: (state, action) => {
    const {vehicle, spot, lastname} = action.payload;
    return {
      ...state,
      search: {
        vehicle: vehicle,
        spot: spot,
        lastname: lastname
      }
    };
  },

  [actionTypes.UPDATE_SUSPECT_CUSTOMER_COMPLETED]: (state, action) => {
    return {...state}
  },

  [actionTypes.GET_CUSTOMER_ADVANTAGE]: (state, action) => {
    return { ...state };
  },

  [actionTypes.GET_CUSTOMER_ADVANTAGE_COMPLETED]: (state, action) => {
    return { ...state,
      customerAdvantage: action.payload.result,
    };
  },

});

export default combineReducers({
  managePage: ticketsRestaurantsReducer,
});
