import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { ProductRow } from "./ProductRow";
import { DynamicModelPart } from "./DynamicModelPart";

export const ProductRows = props => {
  const { category, styles } = props;

  const renderProduct = category.products.map((product, productKey) => {
    // Nom du produit
    return (
      <>
        <View style={productKey%2 === 0 ? styles.border : styles.borderAlternate} key={productKey}>
          <View style={styles.product}>
            <Text style={styles.text}>{product.name}</Text>
          </View>
          {Object.keys(product.periods[0].days).map((day, key) => (
            <>
              {Object.keys(product.periods[0].days[day].types).map(
                (type, typeKey) => (
                    <ProductRow
                      product={product}
                      day={day}
                      type={type}
                      styles={styles}
                      key={typeKey}
                    />
                  )
              )}
            </>
          ))}
        </View>

        {category.categoryPdfModel !== 1 &&
          <DynamicModelPart categoryPdfModel={category.categoryPdfModel} styles={styles} productKey={productKey} product={product} />
        }

      </>
    );
  });

  return <>{renderProduct}</>;
};
