import React, { useState } from "react";
import Autosuggest from 'react-autosuggest';
import Select from "react-select";

const CustomerFilter = (props) => {

  const {
    customers,
    handle
  } = props;

  const [customerValue, setCustomerValue]         = useState('');
  const [suggestions, setSuggestions]             = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  /**
   * Fonction qui recherche parmis la liste des clients
   */
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let returnCustomers = [];

    if (inputLength !== 0) {
      returnCustomers = customers.filter((customer) => {
        // Isole chaque string apres un espace
        let values = inputValue.split(" ");
        let customerOk = true;
        if (values.length > 0) {
          values.forEach((v) => {
            if (!customer.lastname.toLowerCase().includes(v.toLowerCase()) && !customer.email.toLowerCase().includes(v.toLowerCase()) && !customer.firstname.toLowerCase().includes(v.toLowerCase())) {
              customerOk = false;
            }
          });
        }
        return customerOk;
      });
    }

    return returnCustomers;
  }

  const getSuggestionValue = suggestion => suggestion.lastname+' '+suggestion.firstname;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.lastname+' '+suggestion.firstname+' - '+suggestion.email}
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Ajoute le client choisi
  const onSuggestionSelected = (event, {suggestion}) => {
    let sc = [...selectedCustomers, {
      label: suggestion.lastname+' '+suggestion.firstname+' - '+suggestion.email,
      value: suggestion.id
    }];
    setSelectedCustomers([...selectedCustomers, {
      label: suggestion.lastname+' '+suggestion.firstname+' - '+suggestion.email,
      value: suggestion.id
    }])
    handle('customers', sc);
    setCustomerValue('');
  }

  // useEffect(() => {
  //   handle('customers', selectedCustomers);
  //   setCustomerValue('');
  // }, [selectedCustomers, handle])

  const handleChange = (v) => {
    setSelectedCustomers(v);
    handle('customers', v);
    setCustomerValue('');
  }

  return (
    <>
      <div className="mb-2">
        <Select
          isMulti
          onChange={(v) => handleChange(v === null ? [] : v)}
          defaultValue={null}
          value={selectedCustomers}
          options={selectedCustomers}
          placeholder="Choisir les clients..."
        />
      </div>
      <Autosuggest
        className="form-control"
        suggestions={suggestions.slice(0, 10)}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        highlightFirstSuggestion={true}
        multipleSele
        inputProps={{
         value: customerValue,
         placeholder: 'ou renseigner nom du client (à sélectionner dans la liste des suggestions)',
         onChange: (e, { newValue }) => setCustomerValue(newValue),
       }}

      />
    </>
  );
};

CustomerFilter.propTypes = {

};

export default CustomerFilter;
