import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "state/utils";

/* State shape
{
  data: [],
  isLoading: bool
}
*/
const establishmentData = {
  data: {},
  isLoading: false
}

const establishmentReducer = createReducer(establishmentData)({
  [actionTypes.GET_ESTABLISHMENT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_ESTABLISHMENT_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false
    };
  },
  [actionTypes.GET_ESTABLISHMENT_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  }
});

export default combineReducers({
  establishment: establishmentReducer
});
