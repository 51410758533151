import { createSelector } from "reselect";

const smsPageState = (state) => {
  return state.store.sav.sms;
};

const getSavPageSelector = createSelector(
  smsPageState,
  (data) => {
    let vehicles = [];
    let spots = [];

    if (data) {
      data.vehicles.forEach((vehicle) => {
        vehicles = [
          ...vehicles,
          { value: vehicle.id, label: vehicle.name },
        ];
      });

      data.spots.forEach((spot) => {
        spots = [
          ...spots,
          { value: spot.id, label: spot.number_spot },
        ];
      });
    }

    return {
      ...data,
      vehicles,
      spots
    };
  }
);

export { getSavPageSelector };
