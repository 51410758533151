import * as actionTypes from "./types";

/**
 * Recupère les données pour la page admin des clients suspects
 * @param  {int} establishmentId
 */
export const initPage = (establishmentId) => ({
  type: actionTypes.INIT_ADMIN_CUSTOMER_POOL_SUSPECT,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/suspectpoolcustomers.json`,
    method: "GET",
    params: {
      establishment: establishmentId,
    }
  }
});

/**
 * Lance l'action de relance du porte-monnaie numérique (anciennement nommé cagnotte)
 * @param  {int} customerId
 */
export const postSuspectPoolCustomerNotification = (customerId) => ({
  type: actionTypes.POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/suspectpoolcustomers/${customerId}/notifications.json`,
    method: "POST",
  },
  payload: {
    customerId
  }
});

/**
 * Bloque ou débloque le moyen de payement Ticket Restaurant
 * @param  {int} customerId
 */
export const updateSuspectPoolCustomerTrFreeze = (customerId) => ({
  type: actionTypes.UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/suspectpoolcustomers/${customerId}/trfreeze.json`,
    method: "PUT",
  },
  payload: {
    customerId
  }
});
  /**
   * Action pour le champ de recherche
   */
export const searchSuspectPoolCustomer = (lastname) => ({
  type: actionTypes.SEARCH_SUSPECT_POOL_CUSTOMER,
  payload: {
    lastname
  },
});
