import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { ticketRestaurantOperations } from "state/ducks/store/ticketRestaurant";

const Filters = (props) => {

  const { vehicles, spots } = props;

  const [vehicle, setVehicles] = useState(null);
  const [spot, setSpot] = useState(null);
  const [lastname, setLastname] = useState(null);

  const dispatch = useDispatch();

  return (
    <Container>
      <Filter>
        <Col xs={12} sm={4}>
          <Select
            onChange={(selectedOption) => {dispatch(ticketRestaurantOperations.searchAdminTicketRestaurant(selectedOption.value, spot, lastname));setVehicles(selectedOption.value)}}
            defaultValue={vehicle}
            options={vehicles}
            placeholder="Toutes les charrettes"
          />
        </Col>
        <Col xs={12} sm={4}>
          <Select
            onChange={(selectedOption) => { dispatch(ticketRestaurantOperations.searchAdminTicketRestaurant(vehicle, selectedOption.value, lastname));setSpot(selectedOption.value)}}
            defaultValue={spot}
            options={spots}
            placeholder="Points de dépot avec TR en cours"
          />
        </Col>
        <Col xs={12} sm={4}>
          <Form.Control
            type="text"
            defaultValue={lastname}
            onChange={(e) => {dispatch(ticketRestaurantOperations.searchAdminTicketRestaurant(vehicle, spot, e.target.value));setLastname(e.target.value)}}
            placeholder="Nom du client"
          />
        </Col>
      </Filter>
    </Container>
  );
};

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Container = styled.div`
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
`;

Filters.propTypes = {
  establishment: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Filters;
