export const INIT_ADMIN_TICKET_RESTAURANT = "store/ticketRestaurant/INIT_ADMIN_TICKET_RESTAURANT";
export const INIT_ADMIN_TICKET_RESTAURANT_COMPLETED = "store/ticketRestaurant/INIT_ADMIN_TICKET_RESTAURANT_COMPLETED";
export const INIT_ADMIN_TICKET_RESTAURANT_FAILED = "store/ticketRestaurant/INIT_ADMIN_TICKET_RESTAURANT_FAILED";

export const UPDATE_ADMIN_TICKET_RESTAURANT = "store/ticketRestaurant/UPDATE_ADMIN_TICKET_RESTAURANT";
export const UPDATE_ADMIN_TICKET_RESTAURANT_COMPLETED = "store/ticketRestaurant/UPDATE_ADMIN_TICKET_RESTAURANT_COMPLETED";
export const UPDATE_ADMIN_TICKET_RESTAURANT_FAILED = "store/ticketRestaurant/UPDATE_ADMIN_TICKET_RESTAURANT_FAILED";

export const POST_POOL_OPERATIONS = "store/ticketRestaurant/POST_POOL_OPERATIONS";
export const POST_POOL_OPERATIONS_COMPLETED = "store/ticketRestaurant/POST_POOL_OPERATIONS_COMPLETED";
export const POST_POOL_OPERATIONS_FAILED = "store/ticketRestaurant/POST_POOL_OPERATIONS_FAILED";

export const GET_CUSTOMER_POOL = "store/ticketRestaurant/GET_CUSTOMER_POOL";
export const GET_CUSTOMER_POOL_COMPLETED = "store/ticketRestaurant/GET_CUSTOMER_POOL_COMPLETED";
export const GET_CUSTOMER_POOL_FAILED = "store/ticketRestaurant/GET_CUSTOMER_POOL_FAILED";

export const UPDATE_SUSPECT_CUSTOMER = "store/ticketRestaurant/UPDATE_SUSPECT_CUSTOMER";
export const UPDATE_SUSPECT_CUSTOMER_COMPLETED = "store/ticketRestaurant/UPDATE_SUSPECT_CUSTOMER_COMPLETED";
export const UPDATE_SUSPECT_CUSTOMER_FAILED = "store/ticketRestaurant/UPDATE_SUSPECT_CUSTOMER_FAILED";

export const SEARCH_ADMIN_TICKET_RESTAURANT = "store/ticketRestaurant/SEARCH_ADMIN_TICKET_RESTAURANT";

export const GET_CUSTOMER_ADVANTAGE = "store/ticketRestaurant/GET_CUSTOMER_ADVANTAGE";
export const GET_CUSTOMER_ADVANTAGE_COMPLETED = "store/ticketRestaurant/GET_CUSTOMER_ADVANTAGE_COMPLETED";
export const GET_CUSTOMER_ADVANTAGE_FAILED = "store/ticketRestaurant/GET_CUSTOMER_ADVANTAGE_FAILED";
