import React from "react";
import { rootReducerOperations } from "state/ducks/rootReducer";
import { useDispatch } from "react-redux";

const Error = (props) => {
  const { error, errorInfo } = props;

  const dispatch = useDispatch();

  return (
    <div
      style={{
        padding: "1.0rem",
        backgroundColor: "#efefef",
        color: "#111",
        textAlign: "center",
      }}
    >
      <h1>Aie !</h1>
      <h2
        style={{
          display: "block",
          fontSize: "1.2rem",
        }}
      >
        Désolé, nous rencontrons un problème avec votre demande ...
      </h2>
      <br />
      <details style={{ whiteSpace: "pre-wrap" }}>
        {error && error.toString()}
        {errorInfo && errorInfo.componentStack}
      </details>
      <button
        style={{
          backgroundColor: "#429853",
          color: "white",
          border: "none",
          borderRadius: "0.2rem",
          fontSize: "1.2rem",
          padding: "0.5rem",
          marginTop: "1.0rem",
        }}
        onClick={() =>
          dispatch(
            rootReducerOperations.clearOnDeliverySpot(),
            window.location.reload()
          )
        }
      >
        Retour à l'accueil
      </button>
    </div>
  );
};

Error.propTypes = {};

Error.defaultProps = {};

export default Error;
