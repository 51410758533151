import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "../../../utils";

/* State shape
{
  isLoading: bool,
  orders: []
}
*/
const initialPage = {
  isLoading : true,
  data      : []
};
const orderReducer = createReducer(initialPage)({
  [actionTypes.ORDER_GET]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.ORDER_GET_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
  [actionTypes.ORDER_GET_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
});

export default combineReducers({
  order: orderReducer,
});
