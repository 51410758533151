import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const HelpDocumentation = (props) => {
  const { title, url } = props;

  return (
    <Container>
      <OverlayTrigger
        key={"doc-right"}
        placement={"right"}
        overlay={
          <Tooltip id={`tooltip-right`}>
            {title}
          </Tooltip>
        }
      >
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Action variant="secondary">
            <FontAwesomeIcon icon={faInfo} />
          </Action>
        </a>
      </OverlayTrigger>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 1.5rem;
  bottom: 1.5rem;
  transition: 300ms;
`;

const Action = styled(Button)`
  background-color: ${(props) => props.theme.colors.favorite};
  width: 3rem;
  height: 3rem;
  margin: 0 0.2rem;
  border: 2px solid ${(props) => props.theme.colors.favorite};
  &:hover {
    background-color: white;
    color: ${(props) => props.theme.colors.favorite};
    border: 2px solid ${(props) => props.theme.colors.favorite};
  }
`;

HelpDocumentation.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

HelpDocumentation.defaultProps = {
  title: "Voir la documentation",
  url: "https://moulindev.freshdesk.com/support/solutions/48000193880",
};

export default HelpDocumentation;
