import React from "react";
import { Text, View } from "@react-pdf/renderer";

export const DynamicModelPart = props => {

  const {
    styles,
    productKey,
    product,
    categoryPdfModel
  } = props

  return (
      <>
        {categoryPdfModel === 2 &&
          <View style={productKey%2 === 0 ? styles.noBorder : styles.noBorderAlternate} key={productKey}>
            <View style={styles.product}>
              <Text></Text>
            </View>
            {Object.keys(product.periods[0].days).map((day, key) => (
              <>
                <View style={styles.staticCell13}>
                  <Text style={styles.staticCellText}>PR:</Text>
                </View>
                <View style={styles.staticCell13}>
                  <Text style={styles.staticCellText}></Text>
                </View>
                <View style={styles.staticCell13}>
                  <Text style={styles.staticCellText}>+</Text>
                </View>
              </>
            ))}
          </View>
        }

        {categoryPdfModel === 3 &&
          <>
            <View style={productKey%2 === 0 ? styles.noBorder : styles.noBorderAlternate} key={productKey}>
              <View style={styles.product}>
                <Text></Text>
              </View>
              {Object.keys(product.periods[0].days).map((day, key) => (
                <>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}>Reste</Text>
                  </View>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}>midi</Text>
                  </View>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}></Text>
                  </View>
                </>
              ))}
            </View>

            <View style={productKey%2 === 0 ? styles.noBorder : styles.noBorderAlternate} key={productKey}>
              <View style={styles.product}>
                <Text></Text>
              </View>
              {Object.keys(product.periods[0].days).map((day, key) => (
                <>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}>Reste</Text>
                  </View>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}>soir</Text>
                  </View>
                  <View style={styles.staticCell13}>
                    <Text style={styles.staticCellText}></Text>
                  </View>
                </>
              ))}
            </View>
          </>
        }

        {categoryPdfModel === 2 &&
          <View style={productKey%2 === 0 ? styles.noBorder : styles.noBorderAlternate} key={productKey}>
            <View style={styles.product}>
              <Text></Text>
            </View>
            {Object.keys(product.periods[0].days).map((day, key) => (
              <>
                <View style={styles.staticCell23}>
                  <Text style={styles.staticCellText}>Reste</Text>
                </View>
                <View style={styles.staticCell13}>
                  <Text style={styles.staticCellText}>-</Text>
                </View>
              </>
            ))}
          </View>
        }
        <View style={productKey%2 === 0 ? styles.noBorder : styles.noBorderAlternate} key={productKey}>
          <View style={styles.product}>
            <Text></Text>
          </View>
          {Object.keys(product.periods[0].days).map((day, key) => (
            <>
              <View style={styles.staticCell23}>
                <Text style={styles.staticCellText}>à produire</Text>
              </View>
              <View style={styles.staticCell13}>
                <Text style={styles.staticCellText}>=</Text>
              </View>
            </>
          ))}
        </View>

      </>
  );
};
