import React from "react";
import styled from "@emotion/styled";
import nyanCat from "assets/images/nyan-cat.gif";
import { Button, Row, Col } from "react-bootstrap";
import { Link as LinkReact } from "react-router-dom";

const Page404 = (props) => {
  return (
    <Container noGutters>
      <Col xs={12}>
        <img
          src={nyanCat}
          className="img-fluid align-items-center"
          alt="Nyan Cat"
        />
        <Title>Oups, cette page n'existe pas</Title>
        <Link to={"/"}>Accueil</Link>
        <Btn onClick={() => props.history.goBack()}>Revenir en arrière</Btn>
      </Col>
    </Container>
  );
};

const Container = styled(Row)`
  min-height: 100vh;
  padding: 1rem;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.green};
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.size.m};
  position: relative;
  background-color: ${(props) => props.theme.colors.green};
`;

const Link = styled(LinkReact)`
  text-transform: uppercase;
  background-color: white;
  font-size: ${(props) => props.theme.fonts.size.xs};
  width: 100%;
  border: none;
  color: ${(props) => props.theme.colors.black};
  display: block;
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem;
  span {
    color: ${(props) => props.theme.colors.black};
  }
  :hover,
  :active,
  :focus {
    color: white;
    border-color: ${(props) => props.theme.colors.black} !important;
    background-color: ${(props) => props.theme.colors.black} !important;
  }
`;

const Btn = styled(Button)`
  text-transform: uppercase;
  background-color: white;
  font-size: ${(props) => props.theme.fonts.size.xs};
  width: 100%;
  border: none;
  color: ${(props) => props.theme.colors.black};
  display: block;
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem;
  span {
    color: ${(props) => props.theme.colors.black};
  }
  :hover,
  :active,
  :focus {
    color: white;
    border-color: ${(props) => props.theme.colors.black} !important;
    background-color: ${(props) => props.theme.colors.black} !important;
  }
`;

export default Page404;
