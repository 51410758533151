import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import Error from "views/universal/Errors/Api/Error";
import { sessionOperations } from "state/ducks/session";
import { operations as generalOperations } from "state/ducks/general";

const Layout = (props) => {
  const dispatch = useDispatch();

  const paramUrl = queryString.parse(props.location);
  const establishmentIdUrl = paramUrl.establishment;
  const token = paramUrl.token;

  useEffect(() => {

    dispatch(sessionOperations.getEstablishment(establishmentIdUrl, token));
    dispatch(generalOperations.getEstablishment(establishmentIdUrl));

  }, [establishmentIdUrl, token, dispatch]);

  return (
    <>
      {token ? (
        <div>
          <Error />
          {props.children}
        </div>
      ) : (
        <div className="text-center pt-4">
          <img
            src="https://lemoulin.fr/wp-content/uploads/2018/10/le-moulin-salades-produits-frais.png"
            alt="Logo Le Moulin"
          />
          <br />
          <br />
          Vous n'avez pas accès à cette page.
        </div>
      )}
    </>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
