import React from "react";
import styled from "@emotion/styled";
import Layout from "views/universal/Layout/Layout";
import CountUp from 'react-countup';
import { CSSTransition } from "react-transition-group";
import { Row, Col } from "react-bootstrap";
import Filters from "./Filters"
import { tipsSelector } from "state/ducks/store/tips";
import { useSelector } from "react-redux";
import Loader from "views/universal/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import HelpDocumentation from "views/universal/Layout/HelpDocumentation"

const Tips = (props) => {

  const totalTips = useSelector((state) => {
    return tipsSelector.getTotalTipsSelector(state);
  });

  return (
    <Layout location={props.location.search}>
      <Container>
        <Filters isLoading={totalTips.isLoading}/>
        {totalTips.data ?
          <>
            {totalTips.isLoading ?
              <Loader items={1} key={0} />
            :
              <CSSTransition
                appear={true}
                in={true}
                timeout={500}
                classNames="transition"
              >
                <Row>
                  <Col xs={12} sm={3}>
                    <Result>
                      <Title>Pourboire client(s)</Title>
                      <Amount><CountUp end={totalTips.totalTips / 100} duration={3}/> €</Amount>
                      <CountTips><CountUp end={totalTips.countTips} duration={3}/> pourboires</CountTips>
                    </Result>
                  </Col>
                </Row>
              </CSSTransition>
            }
          </>
          :
            <Help>
              <p>
                <FontAwesomeIcon icon={faHandPointUp} size="lg" />
                <br />
                Lancer une recherche ci-dessus pour voir le montant et le nombre de pourboire générés !
              </p>
            </Help>
        }
      </Container>
      <HelpDocumentation title="Documentation sur les pourboires" url="https://moulindev.freshdesk.com/support/solutions/articles/48001080197-syst%C3%A8me-de-pourboire"/>
    </Layout>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

const Result = styled.div`
  background: white;
  padding: 1rem;
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.green};
  border-radius: 4px;
  text-align: center;
`;

const Amount = styled.h3`
  font-size: ${(props) => props.theme.fonts.size.xl};
  color: ${(props) => props.theme.colors.green};
  margin-bottom: 0rem;
`;

const CountTips = styled.h4`
  font-style:italic;
  color: ${(props) => props.theme.colors.green};
  font-size: ${(props) => props.theme.fonts.size.s};
`;

const Title = styled.h2`
  text-align: center;
  margin: auto;
  display: inline-block;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.s};
  margin-bottom: 0.5rem;
  border: 2px solid ${(props) => props.theme.colors.black};;
`;

const Help = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

Tips.propTypes = {};

Tips.defaultProps = {};

export default Tips;
