import { createSelector } from "reselect";

const managePageState = (state) => state.ticketRestaurant.managePage;
const customerPoolState = (state) =>
  state.ticketRestaurant.managePage.customerPool;
const customerAdvantageState = (state) =>
  state.ticketRestaurant.managePage.customerAdvantage;

const getManagePageSelector = createSelector(
  managePageState,
  (managePageState) => {
    let vehicles = [];
    let spots = [];
    let ticketRestaurants = [];
    let customers = [];

    if (managePageState) {
      managePageState.vehicles.forEach((vehicle) => {
        vehicles = [...vehicles, { value: vehicle.id, label: vehicle.name }];
      });

      managePageState.spots.forEach((spot) => {
        spots = [...spots, { value: spot.id, label: spot.number_spot }];
      });

      customers = managePageState.customers;

      // Trie par client
      let trByCustomer = [];

      managePageState.ticketRestaurants.forEach((tr, key) => {
        // On check le nombre de TR ayant le meme customerId
        const jointsTr = managePageState.ticketRestaurants.filter(
          (element) => element.customerId === tr.customerId
        );

        // Si on a plus d'un TR pour un customer
        if (jointsTr.length > 1) {
          // On check si il existe deja un customer dans notre tableau
          let indexCustomer = trByCustomer.findIndex(
            (element) => element.customerId === tr.customerId
          );

          // Si il existe deja le customer
          if (indexCustomer !== -1) {
            // On recrée un objet en ajoutant le TR en plus des précedents pour le client
            const newListTr = {
              ...trByCustomer[indexCustomer],
              ticketRestaurants: [
                ...trByCustomer[indexCustomer].ticketRestaurants,
                tr,
              ],
            };

            //  On supprimer l'ancien et on ajoute le nouveau résultat
            trByCustomer.splice(indexCustomer, 1);
            trByCustomer = [...trByCustomer, newListTr];
          } else {
            //  On ajoute le client au tableau avec son premier TR
            let newListTr = {
              trSuspect: tr.trSuspect,
              charretteId: tr.charretteId,
              spotId: tr.spotId,
              lastname: tr.lastname,
              firstname: tr.firstname,
              phone: tr.phone,
              email: tr.email,
              customerId: tr.customerId,
              ticketRestaurants: [...ticketRestaurants, tr],
            };
            trByCustomer = [...trByCustomer, newListTr];
          }
        } else {
          //  On ajoute le client au tableau avec son premier TR
          let newListTr = {
            trSuspect: tr.trSuspect,
            charretteId: tr.charretteId,
            spotId: tr.spotId,
            lastname: tr.lastname,
            firstname: tr.firstname,
            phone: tr.phone,
            email: tr.email,
            customerId: tr.customerId,
            ticketRestaurants: [...ticketRestaurants, tr],
          };
          trByCustomer = [...trByCustomer, newListTr];
        }
      });

      ticketRestaurants = trByCustomer;
    }

    return {
      ...managePageState,
      vehicles,
      spots,
      customers,
      ticketRestaurants,
    };
  }
);

const getCustomerPoolSelector = createSelector(
  customerPoolState,
  (customerPoolState) => {
    let total = 0;

    if (customerPoolState) {
      customerPoolState.operations.forEach((operation) => {
        if (operation.type === "debit") {
          if (operation.amount) {
            total = total - operation.amount;
          } else {
          }
        } else if (operation.type === "credit") {
          if (operation.amount) {
            total = total + operation.amount;
          } else {
          }
        }
      });
    }
    let firstname,
      lastname = "";
    if (
      customerPoolState &&
      customerPoolState.customer &&
      customerPoolState.customer.firstname
    ) {
      firstname = customerPoolState.customer.firstname;
    }
    if (
      customerPoolState &&
      customerPoolState.customer &&
      customerPoolState.customer.lastname
    ) {
      lastname = customerPoolState.customer.lastname;
    }
    return {
      ...customerPoolState,
      total,
      firstname: firstname,
      lastname: lastname,
    };
  }
);

const getCustomerAdvantageSelector = createSelector(
  customerAdvantageState,
  (customerAdvantageState) => {
    let availableAdvantageAmount = 0;
    let firstname,
      lastname = "";
    let advantages = [];
    if (
      customerAdvantageState &&
      customerAdvantageState.advantages
    ) {
      advantages = customerAdvantageState.advantages;
    }
    if (
      customerAdvantageState &&
      customerAdvantageState.customer &&
      customerAdvantageState.customer.firstname
    ) {
      firstname = customerAdvantageState.customer.firstname;
    }
    if (
      customerAdvantageState &&
      customerAdvantageState.customer &&
      customerAdvantageState.customer.lastname
    ) {
      lastname = customerAdvantageState.customer.lastname;
    }
    if (
      customerAdvantageState &&
      customerAdvantageState.availableAdvantageAmount
    ) {
      availableAdvantageAmount = customerAdvantageState.availableAdvantageAmount;
    }
    return {
      advantages: advantages,
      availableAdvantageAmount: availableAdvantageAmount,
      firstname: firstname,
      lastname: lastname,
    };
  }
);

export {
  getManagePageSelector,
  getCustomerPoolSelector,
  getCustomerAdvantageSelector,
};
