import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Real from "./Real/Real";
import CompositionProductReal from "./CompositionProducts/Real/Real";
import { Tabs, Tab } from "react-bootstrap";
import Layout from "views/universal/Layout/Layout";
import { storeOperations, storeSelector } from "state/ducks/store";
import Forecast from "./Forecast/Forecast";
import Loader from "views/universal/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Stocks = (props) => {
  const dispatch = useDispatch();

  const establishment = useSelector(
    (state) => state.session.establishment,
    shallowEqual
  );

  const productCategories = useSelector((state) => {
    return storeSelector.getProductCategoriesSelector(state);
  });

  const activityTypes = useSelector(
    (state) => state.store.activityTypes,
    shallowEqual
  );

  const compositionProductCategories = useSelector((state) => {
    return storeSelector.getCompositionProductCategoriesSelector(state);
  });

  useEffect(() => {
    dispatch(storeOperations.getProductCategories());
    dispatch(storeOperations.getActivityTypes());
    dispatch(storeOperations.getCompositionProductCategories());
  }, [dispatch]);

  return (
    <Layout location={props.location.search}>
      <Container>
        <Import>
          <a href={`${process.env.REACT_APP_LEMOULIN_BO_URL}/e28XmZh3/store/deliveroo/`} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faDownload} size="xs" /> Donnée Deliveroo - </a>
          <a href={`${process.env.REACT_APP_LEMOULIN_BO_URL}/e28XmZh3/store/ikentoo/`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDownload} size="xs" /> Donnée Ikentoo</a>
        </Import>
        <Tabs defaultActiveKey="forecast" fill >
          <Tab eventKey="forecast" title="Prévisionnel produit">
            {productCategories.data && activityTypes.data ? (
              <Forecast
                establishment={establishment}
                productCategories={productCategories.data}
                activityTypes={activityTypes.data}
              />
            ) : (
              <Loader items={1} />
            )}
          </Tab>
          <Tab eventKey="tweakstock" title="Ajust Stock Produit">
            {productCategories.data ? (
              <>
                <Real
                  establishment={establishment}
                  productCategories={productCategories.data}
                />
              </>
            ) : (
              <Loader items={1} />
            )}
          </Tab>
          <Tab eventKey="tweakstockcomposition" title="Ajust Stock Ingredient">
            {compositionProductCategories.data ? (
              <>
                <CompositionProductReal
                  establishment={establishment}
                  compositionProductCategories={compositionProductCategories.data}
                />
              </>
            ) : (
              <Loader items={1} />
            )}
          </Tab>
          {/* <Tab eventKey="menu" title="A la carte disponible">
            {productCategories.data ? (
              <>
                <ALaCartes
                  establishment={establishment}
                  productCategories={productCategories.data}
                />
              </>
            ) : (
              <Loader items={1} />
            )}
          </Tab> */}
        </Tabs>
      </Container>

    </Layout>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

const Import = styled.div`
  text-align: right;
`;

Stocks.propTypes = {};

Stocks.defaultProps = {};

export default Stocks;
