import React from "react";
import styled from "@emotion/styled";
import "react-dates/lib/css/_datepicker.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = (props) => {

  const {
    title,
    description,
    children,
    vAlign,
    center,
    icon,
    colorIcon,
    backgroundColor
  } = props

  return (
    <Container center={center} className={vAlign && "vAlign"} backgroundColor={backgroundColor} >
      <div>
        {icon && <Icon colorIcon={colorIcon} ><FontAwesomeIcon icon={icon} /></Icon>}
        {title && <Title>{title}</Title>}
        {description && <Descr>{description}</Descr>}
        {children}
      </div>
    </Container>
  );

};

const Icon = styled.div`
    width: 54px;
    height: 54px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 50%!important;
    background-color: ${(props) =>
      props.colorIcon
        ? (props) => props.colorIcon
        : (props) => '#111'};
    color: white;
    text-align: center;
    margin: 0.5rem auto;
    svg{
      text-align: center;
      margin: auto;
    }
`;

const Title = styled.h4`
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
`;

const Descr = styled.p`
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;
`;

const Container = styled.div`
    box-shadow: 0 0.46875rem 2.1875rem rgb(0 0 0 / 3%), 0 0.9375rem 1.40625rem rgb(0 0 0 / 3%), 0 0.25rem 0.53125rem rgb(0 0 0 / 5%), 0 0.125rem 0.1875rem rgb(0 0 0 / 3%);
    border-width: 0;
    text-align: ${(props) =>
      props.center
        ? (props) => 'center'
        : (props) => 'left'};
    transition: all .2s;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${(props) =>
      props.backgroundColor
        ? (props) => props.backgroundColor
        : (props) => '#fff'};
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    display: flex;
    padding: 1rem;
    position: relative;
    height: 100%;
`;

Card.propTypes = {
  title: PropTypes.string,
  colorIcon: PropTypes.string,
  description: PropTypes.string,
  vAlign: PropTypes.bool,
  center: PropTypes.bool,
};

export default Card;
