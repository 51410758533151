import * as actionTypes from "./types";
import { createReducer } from "state/utils";

/* State shape
{
  data: [],
  isLoading: bool,
  customer:
}
*/
const initData = {
  isLoading: false,
  data: [],
  customer: {},
};

const customersReducer = createReducer(initData)({
  [actionTypes.INIT_ADMIN_STORE_CUSTOMERS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.INIT_ADMIN_STORE_CUSTOMERS_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result.customers,
      isLoading: false,
    };
  },

  [actionTypes.GET_STORE_CUSTOMER_INFO]: (state, action) => {
    const customer = action.payload.customer;
    return { 
      ...state,
      customer: {
        isLoading: false,
        ...customer,
      },
    };
  },

  [actionTypes.GET_STORE_CUSTOMER_ORDERS]: (state, action) => {
    const customer = action.payload.customer;
    
    return { ...state, customer: { ...customer, isLoading: false } };
  },

  [actionTypes.GET_STORE_CUSTOMER_ORDERS_COMPLETED]: (state, action) => {
    const customer = action.payload.customer;

    return {
      ...state,
      customer: {
        isLoading: false,
        ...customer,
        orders: action.payload.result.orders,
      },
    };
  },

  [actionTypes.UPDATE_STORE_CUSTOMER]: (state, action) => {
    return { ...state };
  },

  [actionTypes.UPDATE_STORE_CUSTOMER_COMPLETED]: (state, action) => {
    console.log(state)
    return {
      ...state,
      customer: { ...state.customer, ...action.payload.result },
    };
  },

  [actionTypes.POST_STORE_CUSTOMER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.POST_STORE_CUSTOMER_COMPLETED]: (state, action) => {
    let theNewCustomer = {
      code_id: action.payload.result.code_id,
      email: action.payload.result.email,
      firstname: action.payload.result.firstname,
      id: action.payload.result.id,
      last_order_amount_offer: action.payload.result.last_order_amount_offer,
      lastname: action.payload.result.lastname,
      phone: action.payload.result.phone,
      verified_account: action.payload.result.verified_account,
      vigilanceLevel: null,
    };
    let data = state.data;
    data.push(theNewCustomer);
    return {
      ...state,
      data: data,
      customer: { ...state.customer, ...action.payload.result },
      isLoading: false,
    };
  },
  [actionTypes.POST_STORE_CUSTOMER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
});

export default customersReducer;
