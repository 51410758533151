import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridToolbar, frFR } from '@material-ui/data-grid';
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Orders = (props) => {

  const {
    orders
  } = props

  /**
   * On retraite la réponse pour la rendre compatible au tableau
   */
  let responseFormat = [];
  if(orders.length > 0){
    responseFormat = orders.map((value, key) => {
      return {id: value.id, number_order: value.number_order, total_price_ttc: value.total_price_ttc, lastname: value.customer_info.lastname, firstname: value.customer_info.firstname, email: value.customer_info.email, phone: value.customer_info.phone, number_spot: value.delivery_info.number_spot}
    })
  }

  const theme = createMuiTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    frFR,
  );

  /**
   * On définit les colonnes que l'on veut mapper au data
   */
  const columns: GridColDef[] = [
    { field: 'lastname', headerName: 'Nom', flex: 1, renderCell: (params: GridCellParams) => (
      <Capitalize>
        {params.value}
      </Capitalize>
    )},
    { field: 'firstname', headerName: 'Prénom', flex: 1, renderCell: (params: GridCellParams) => (
      <Capitalize>
        {params.value}
      </Capitalize>
    )},
    { field: 'email', headerName: 'Email', flex: 1, renderCell: (params: GridCellParams) => (
      <div>
        {params.value}
      </div>
    )},
    { field: 'phone', flex: 1, headerName: 'Téléphone' },
    { field: 'number_order', flex: 1, headerName: 'N° de commande' },
    { field: 'number_spot', flex: 1, headerName: 'N° du point' },
    { field: 'total_price_ttc', flex: 1, headerName: 'Prix TTC', renderCell: (params: GridCellParams) => (
      <div>
        {params.value / 100} €
      </div>
    )},
  ];

  return (
    <div style={{ height: 500, width: '100%' }} className="mb-2">
      {responseFormat != null &&
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={responseFormat}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={20}
          />
        </ThemeProvider>
      }
    </div>
  )

}

const Capitalize = styled.div`
  text-transform: capitalize;
`;

Orders.propTypes = {
  orders: PropTypes.array
};

Orders.defaultValues = {
  orders: []
};

export default Orders;
