import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Filter from "./Filter";
import Customer from "./Customer";
import PopupCustomer from "./PopupCustomer";
import { customersOperations } from "state/ducks/store/customers";
import { notificationsOperations } from "state/ducks/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const Customers = (props) => {

  const { getCustomerPool, getCustomerAdvantage } =
    props;

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);

  // Permet de savoir si la popup customer sert pour l'ajout ou l'edit d'un client
  const [isForEditPopup, setIsForEditPopup] = useState(false);

  const establishment = useSelector(
    (state) => state.session.establishment,
    shallowEqual
  );

  const customers = useSelector((state) => state.store.customers, shallowEqual);

  useEffect(() => {
    if (establishment) {
      dispatch(customersOperations.initPage(establishment));
    }
  }, [dispatch, establishment]);

  const customerHandle = (mode, data) => {
    setIsLoadingPopup(true);
    if (mode === "edit") {
      dispatch(customersOperations.updateStoreCustomer(data)).then(
        (result) => {
          setIsLoadingPopup(false);
          setIsModalOpen(false);
          if (result?.data?.result) {
            dispatch(
              customersOperations.getStoreCustomerInfo(result.data.result)
            );
          }
        },
        (error) => {
          setIsLoadingPopup(false);
        }
      );
      return;
    } else if (mode === "add") {
      dispatch(customersOperations.addStoreCustomer(data)).then(
        (result) => {
          setIsLoadingPopup(false);
          setIsModalOpen(false);
          if (result?.data?.result) {
            dispatch(
              customersOperations.getStoreCustomerInfo(result.data.result)
            );
          }
        },
        (error) => {
          setIsLoadingPopup(false);
        }
      );
    }
  };

  const sendSMSHandle = async (customer) => {
    return dispatch(customersOperations.sendCodeBySMS(customer)).then(
      (result) => {
        if (result?.data?.result?.success) {
          return dispatch(
            notificationsOperations.getResponseSuccess("SMS envoyé")
          );
        }
        return dispatch(
          notificationsOperations.getResponseSuccess("Echec de l'envoi")
        );
      }
    );
  };

  return (

      <>
        <div className="text-right mt-3">
          <Button
            onClick={() => {
              setIsModalOpen(true);
              setIsForEditPopup(false);
            }}
            disabled={customers.isLoading}
          >
            Inscrire un client en boutique
          </Button>
        </div>
        <Filter
          isLoadingPage={customers.isLoading}
          customers={customers.data}
        />
        <Customer
          customer={customers.customer}
          establishment={establishment}
          openPopupCustomerHandle={() => {
            setIsModalOpen(true);
            setIsForEditPopup(true);
          }}
          sendSMSHandle={sendSMSHandle}
          getCustomerPool={getCustomerPool}
          getCustomerAdvantage={getCustomerAdvantage}
        />
        {!customers.customer.id && (
          <Explain>
            <p>
              <FontAwesomeIcon icon={faHandPointUp} size="lg" />
              <br />
              Lancer une recherche ci-dessus pour avoir les détails sur un
              client dont ses commandes.
            </p>
          </Explain>
        )}

        <PopupCustomer
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          onEscapeKeyDown={() => setIsModalOpen(false)}
          customerHandle={customerHandle}
          isLoading={isLoadingPopup}
          customer={customers.customer}
          isEdit={isForEditPopup}
          customers={customers.data}
        />
      </>
    
  );
};

const Explain = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

Customers.propTypes = {};

Customers.defaultProps = {};

export default Customers;
