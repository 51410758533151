import { combineReducers } from "redux";
import * as reducers from "./../../ducks";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === "rootReducer/CLEAR_ALL") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    // Exemple pour vider que garder certaines partie du store
    const { session } = state;
    state = { session };

    // Exemple on vide tout le store
    //state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
