import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const TypeFilter = (props) => {

  const {
    value,
    handleChange
  } = props

  return (
    <div className="mb-2">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="type"
          name="type"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        >
          <FormControlLabel
            value="order"
            control={<Radio color="primary" />}
            label="Par commande"
            labelPlacement="start"
          />
          <FormControlLabel
            value="customer"
            control={<Radio color="secondary" />}
            label="Par client"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </div>
  )

}

export default TypeFilter;
