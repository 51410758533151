import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Loader from "views/universal/Loader/Loader";
import moment from "moment";
import { savOperations } from "state/ducks/store/sav";
import { orderOperations } from "state/ducks/store/order";
import Steps from "./Steps";

const Sms = (props) => {

  const savPage = useSelector(
    (state) => state.store.sav.admin,
    shallowEqual
  );

  const [filterSelected, setFilterSelected] = useState({});
  const [response, setResponse]             = useState(false); // {'total', 'success', 'fails'}
  const [orders, setOrders]                 = useState([]);

  const dispatch = useDispatch();

  /**
   * Récupère les commandes
   * Retourn un tableau
   */
  const getOrders = async () => {
    let params = {
      deliveryType: 'spot',
      date     : moment().format("YYYY-MM-DD"),
      vehicles : filterSelected.vehicles && filterSelected.vehicles.map((value) => value.value),
      spots    : filterSelected.spots && filterSelected.spots.map((value) => value.value),
      groups: ['order_info', 'order_multiple']
    };

    return dispatch(orderOperations.getOrders(params))
    .then(
      (result) => {
        if (result?.data?.result?.orders) {
          setOrders(result.data.result.orders);
          return result.data.result.orders;
        }
        throw new Error('No data')
      },
      (error) => {
        throw new Error('No data')
      }
    )
    .finally(() => {
    })
    .catch((e) => {
      return [];
    });
  }

  /**
   * Envoie le sms
   */
  const sendSMS = async (cause, start, end) => {
    let orderIds = orders.map((order, key) => order.id);
    return dispatch(savOperations.sendSMS(orderIds, cause, start, end))
    .then(
      (result) => {
        if (result?.data?.result) {
          setResponse(result.data.result);
          return result.data.result;
        }
        throw new Error('No data')
      },
      (error) => {
        throw new Error('No data')
      }
    )
    .finally(() => {
    })
    .catch((e) => {
      return [];
    });
  }

  /**
   * Gère le changement des variables
   */
  const handleFiltersChange = (filters) => {
    setFilterSelected(filters);
  }

  return (
    <>
      {!savPage.isInit ? (
        <Loader items={2} />
      ) : (
        <>
          <Steps
            handleFiltersChange={handleFiltersChange}
            getOrders={getOrders}
            orders={orders}
            sendSMS={sendSMS}
            smsModel={savPage.smsModel}
            vehicles={savPage.vehicles}
            spots={savPage.spots}
            savIsLoading={savPage.isLoading}
            response={response}
            filterSelected={filterSelected}
          />
        </>
      )}
    </>
  );
};

Sms.propTypes = {};

Sms.defaultProps = {};

export default Sms;
