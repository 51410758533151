import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faSpinner,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { storeOperations } from "../../../../../../state/ducks/store";
import { useDispatch } from "react-redux";

const FormStock = (props) => {
  const { type, stock, date, productId, indexProduct, isAvailable } = props;

  const [stockValue, setStockValue] = useState(stock.tweakStock !== null ? stock.tweakStock : stock.stock);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setStockValue(stock.tweakStock !== null ? stock.tweakStock : stock.stock);
  }, [stock]);

  const submitNewStock = (e) => {
    e.preventDefault();
    if (stock.stockId && type && indexProduct) {
      setIsLoading(true);
      dispatch(
        storeOperations.updateRealProductStocks(
          stock.stockId,
          stockValue === "" ? -1 : stockValue,
          type,
          indexProduct
        )
      ).then(
        (result) => {
          setIsLoading(false);
          setIsSuccess(true);
          setTimeout(function () {
            setIsSuccess(false);
          }, 2000);
        },
        (error) => {
          setIsLoading(false);
          setIsError(true);
          setTimeout(function () {
            setIsError(false);
          }, 2000);
        }
      );
    }

    if (stock.stockId === null && type && date && productId && indexProduct) {
      setIsLoading(true);
      dispatch(
        storeOperations.postRealProductStocks(
          stockValue === "" ? -1 : stockValue,
          type,
          date,
          productId,
          indexProduct,
          stock.stock
        )
      ).then(
        (result) => {
          setIsLoading(false);
          setIsSuccess(true);
          setTimeout(function () {
            setIsSuccess(false);
          }, 2000);
        },
        (error) => {
          setIsLoading(false);
          setIsError(true);
          setTimeout(function () {
            setIsError(false);
          }, 2000);
        }
      );
    }
  };

  return (
    type !== 'ikentoo' &&
      <Form>
        <ListStock>
          <Type xs={3} sm={2} md={2} className="vAlign">
            <p>
              {type === "shop"
                ? "Boutique"
                : type === "spot"
                ? "Charrette"
                : type === "deliveroo"
                ? "Deliveroo"
                : type === "ikentoo"
                ? "Boutique direct"
                : type === "shop_v2"
                ? "Boutique"
                : type}
            </p>
          </Type>
          <Cell xs={3} sm={3} md={3} className="vAlign">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Stock</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={stockValue === -1 ? "" : stockValue}
                onChange={(e) => setStockValue(e.target.value)}
                placeholder="Illimité"
                disabled={!isAvailable}
              />
            </InputGroup>
          </Cell>
          <Cell xs={2} sm={2} md={2} className="vAlign">
            <p>
              <small>Commande</small>
              <br />
              {stock.order}
            </p>
          </Cell>
          <Cell xs={3} sm={3} md={3} className="vAlign">
            {stock.tweakStock !== null ?
                <Warning>
                  <small>Ajustement</small>
                  <br />
                  Oui (stock du prévisionnel non utilisé / {stock.stock === -1 ? "Illimité" : stock.stock})
                </Warning>
              :
                <p>
                  <small>Ajustement</small>
                  <br />
                  Non (stock du prévisionnel utilisé)
                </p>
            }
          </Cell>
          <Save xs={12} sm={2} md={2} type={isError ? 'error' : isSuccess ? 'success' : null}>
            <button
              disabled={
                !moment(date).isSameOrAfter(moment().format("YYYY-MM-DD"))
              }
              onClick={(e) => submitNewStock(e)}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : isSuccess ? (
                <FontAwesomeIcon icon={faCheck} size="lg" />
              ) : isError ? (
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faSave} size="lg" />
              )}
            </button>
          </Save>
        </ListStock>
      </Form>
  );
};

const ListStock = styled(Row)`
  border-bottom: #dedede 1px solid;
`;

const Cell = styled(Col)`
  border-left: #dedede 1px solid;
  padding: 0.5rem;
  p {
    small {
      color: ${(props) => props.theme.colors.green};
    }
    font-size: ${(props) => props.theme.fonts.size.xs};
    margin-bottom: 0px;
  }
`;

const Type = styled(Col)`
  border-left: #dedede 1px solid;
  padding: 0rem;
  p {
    margin-bottom: 0rem;
    text-align: center;
    word-break: break-all;
  }
`;

const Warning = styled.p`
  color: ${(props) => props.theme.colors.red};
`;

const Save = styled(Col)`
  padding: 0rem;
  button {
    transition: 300ms;
    width: 100%;
    background-color: ${(props) => props.type === 'error' ? props.theme.colors.red : props.type === 'success' ? props.theme.colors.darkgreen : props.theme.colors.green};
    color: white;
    border: none;
    height: 100%;
    min-height: 3rem;
    &:hover {
      opacity: 0.7;
    }
    :disabled,
    button[disabled] {
      opacity: 0.5;
    }
  }
`;

FormStock.propTypes = {
  type: PropTypes.string,
  stock: PropTypes.object,
  date: PropTypes.string,
  productId: PropTypes.number,
  indexProduct: PropTypes.string,
};

export default FormStock;
