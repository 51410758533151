import React, { useState } from "react";
import Filters from "./Filters";
import { Row, Col } from "react-bootstrap";
import { Stepper, Step, StepLabel, StepContent, Paper, CircularProgress, Button } from '@material-ui/core';
import Orders from "./Orders"
import Phone from "./Phone"
import PropTypes from "prop-types";
import ResponseCard from "../Common/ResponseCard";
import swal from 'sweetalert';

const Steps = (props) => {

  const { savIsLoading, handleFiltersChange, getOrders, orders, sendSMS, smsModel, vehicles, spots, response, filterSelected } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading]   = useState(false);

  /**
   * Permet de définir les labels des Steppers
   */
  const getSteps = () => {
    return ['Filtrer les commandes du jour', 'Vérifier la liste des commandes', 'Le SMS'];
  }

  /**
   * Permet de définir les composants utilisés pour telle Step
   */
  const getStepContent = (step) => {
    let comp;
    switch (step) {
      case 0:
        comp = <Filters
          handleChange={handleFiltersChange}
          vehicles={vehicles}
          spots={spots}
        />;
        break;
      case 1:
        comp = <Orders orders={orders} />
        break;
      case 2:
        const sendSMSAndNextStep = async (cause, start, end) => {
          let resp = await sendSMS(cause, start, end);
          if (resp) {
            handleNext();
          }
        }
        comp = <Phone
        sendSMS={sendSMSAndNextStep}
        smsModel={smsModel}
        isLoading={savIsLoading}
        />;
        break;
      default:
        comp = 'Etape inconnu';
        break;
    }
    return comp;
  }

  /**
   * Permet de définir quelle fonction est utilisée lors du passage à l'étape suivante
   */
  const getStepFunction = async (step) => {
    switch (step) {
      case 0:
        if (!filterSelected.spots || filterSelected.spots.length !== 1) {
          swal({
            title: "Pas si vite !",
            text: "Merci de renseigner uniquement un point",
            icon: "warning",
            buttons: {
              ok: "Ok",
            },
          })
          return false;
        }
        setIsLoading(true);
        await getOrders();
        setIsLoading(false);
        handleNext();
        break;
      case 1:
        handleNext();
        break;
      default:
        break;
    }
  }

  const isNextDisable = (step) => {
    let disable = false;
    switch (step) {
      case 1:
        if (orders.length <= 0) {
          disable = true;
        }
        break;
      default:
        break;
    }
    return disable;
  }

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div className="mt-3">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <Row>
                  <Col xs={12}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Retour
                    </Button>
                    {activeStep < (steps.length - 1) &&
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isNextDisable(index) || isLoading}
                        onClick={(e) => getStepFunction(index)}
                      >
                        {isLoading
                          ? <CircularProgress color="white" size={18} />
                          : 'Suivant'
                        }
                      </Button>
                     }
                  </Col>
                </Row>
              </StepContent>
            </Step>
          ))}
          {activeStep === steps.length && (
            <Paper square elevation={0}>
              <Row className="mt-3">
                <Col xs={4}>
                  <div className="mt-3">
                    <Button onClick={handleReset} variant="outlined" color="primary">
                      Faire un autre envoi ?
                    </Button>
                  </div>
                </Col>
              </Row>
            </Paper>
          )}
          <ResponseCard title={"Résultats de la dernière requête"} success={response?.success} fails={response?.fails}/>
        </Stepper>
      </div>
    </>
  );

};

Steps.propTypes = {
  handleFiltersChange: PropTypes.func,
  getOrders: PropTypes.func,
  orders: PropTypes.array,
  sendSMS: PropTypes.func,
  smsModel: PropTypes.string,
  vehicles: PropTypes.array,
  spots: PropTypes.array,
  filterSelected: PropTypes.object // les filtres {spots:[], vehicles:[]}
};

Steps.defaultProps = {
  smsModel: '',
  vehicles: [],
  spots: []
};

export default Steps;
