import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import Card from "views/universal/Components/Card";
import {
  faMapMarker
} from "@fortawesome/free-solid-svg-icons"

const Filters = (props) => {

  const { handleChange, vehicles, spots } = props;

  // Les options des filtres select
  const options = {
    'vehicles': vehicles.map(v => {
      return {'label': v.name, 'value': v.id}
    }),
    'spots': spots.map(v => {
      return {'label': v.number_spot, 'value': v.id}
    })
  };

  // Les valeurs renseignées des filtres
  const [selectedOptions, setSelectedOptions] = useState({
    'vehicles'     : null,
    'spots'        : null
  });

  // const filterVehicles = () => {
  //   return options.vehicles.filter((v) => {
  //     let vehicleData = vehicles.find(s => v.value === s.id); // Les données du store du vehicle séléctionné

  //     if (!vehicleData) {
  //       return false;
  //     }

  //     return true;
  //   });
  // }

  const filterSpots = () => {
     return options.spots.filter((v) => {

      let selectedVehicles = selectedOptions.vehicles;
      let spotData         = spots.find(s => v.value === s.id); // Les données du store du spot séléctionné

      // Pas de charrette
      if (!selectedVehicles) {
        return true;
      }
      if (!spotData) {
        return false;
      }

      // Charrettes séléctionnées
      if (selectedVehicles) {
        let selectedVehicleIds = selectedVehicles.map((sv) => {
          return sv.value;
        })

        if (!selectedVehicleIds.includes(spotData.vehicle.id)) {
          return false;
        };
      }

      return true;
    });
  }

  const handle = (attr, selectedValue) => {
    if (attr === 'vehicles') {
      setSelectedOptions({
        ...selectedOptions,
        [attr]:selectedValue,
        spots:null
      })
    }
    else {
      setSelectedOptions({
        ...selectedOptions,
        [attr]:selectedValue
      })
    }
  }

  // Gère la maj des options choisies
  useEffect(() => {
    handleChange(selectedOptions);
  }, [selectedOptions, handleChange]);

  return (
      <Filter>
        { /* <Col xs={12} sm={6}>
          <Card title="Recherche par charrette" description="" icon={faBicycle} colorIcon={'#abd156'}>
            <Select
              isMulti
              onChange={(v) => handle('vehicles', v)}
              value={selectedOptions.vehicles}
              options={filterVehicles()}
              placeholder="Toutes les charrettes"
            />
          </Card>
        </Col> */}
        <Col xs={12} sm={6}>
          <Card title="Recherche par point" description="" icon={faMapMarker} colorIcon={'#abd156'}>
            <Select
              isMulti
              isOptionDisabled={() => selectedOptions.spots && selectedOptions.spots.length >= 1}
              onChange={(v) => handle('spots', v)}
              value={selectedOptions.spots}
              options={filterSpots()}
              placeholder="Tous les points"
            />
          </Card>
        </Col>
      </Filter>
  );
};

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

Filters.propTypes = {
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  spots: PropTypes.array,
  vehicles: PropTypes.array,
};

export default Filters;
