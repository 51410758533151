import axios from "axios";
import oauth from "axios-oauth-client";
import { fetch } from "../utils";
import { notificationsOperations } from "state/ducks/notifications";
import { store } from "state/store";

const baseUrl = process.env.REACT_APP_LEMOULIN_API_URL;
const apiKey = process.env.REACT_APP_LEMOULIN_API_KEY;
const clientId = process.env.REACT_APP_LEMOULIN_API_CLIENT_ID;
const clientSecret = process.env.REACT_APP_LEMOULIN_API_CLIENT_SECRET;

const apiService = () => next => action => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }

  // Juste pour l'authentification
  if (action.meta.auth) {
    const url = `${process.env.REACT_APP_LEMOULIN_URL}/oauth/v2/token?apiKey=${apiKey}`;
    const body = action.meta.body;
    const getOwnerCredentials = oauth.client(axios.create(), {
      url: url,
      grant_type: "password",
      client_id: clientId,
      client_secret: clientSecret,
      username: body.username,
      password: body.password
    });
    const credentials = getOwnerCredentials();
    credentials.then(
      res => {
        window.localStorage.setItem("token", res.access_token);
        handleResponse(
          {
            data: {
              result: res.access_token
            }
          },
          action,
          next
        );
      },
      err => {
        handleErrors(err, action, next);
      }
    );
  } else {
    let { path, method, body, params } = action.meta;

    if (!path) {
      throw new Error(`'path' not specified for async action ${action.type}`);
    }

    let url = `${baseUrl}${path}?apiKey=${apiKey}`;

    if (method === "GET") {
      body = params;
    }

    let tokenAuth = store.getState().session.token;

    return fetch(url, method, body, tokenAuth).then(
      res => handleResponse(res, action, next),
      err => handleErrors(err, action, next)
    );
  }
};

export default apiService;

function handleErrors(err, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.error = err;

  next({
    type: `${action.type}_FAILED`,
    payload: payload,
    meta: action.meta
  });

  store.dispatch(notificationsOperations.getResponseError(err));
  return Promise.reject(err);
}

function handleResponse(res, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.result = res.data.result;

  if (res.data.total) {
    payload.total = res.data.total;
  }

  next({
    type: `${action.type}_COMPLETED`,
    payload: payload,
    meta: action.meta
  });
  return res;
}
