import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "../../../utils";

/* State shape
{
  spots: [],
  vehicles: [],
  establishments: [],
  products: [],
  customers: [],
  model: string
  isLoading: bool
}
*/
const initialPage = {
  isInit         : false,
  isLoading      : true,
  spots          : [],
  vehicles       : [],
  establishments : [],
  products       : [],
  customers      : [],
  smsModel       : ''
};
const savReducer = createReducer(initialPage)({

  [actionTypes.INIT_ADMIN_SAV]: (state, action) => {
    return initialPage;
  },

  [actionTypes.INIT_ADMIN_SAV_COMPLETED]: (state, action) => {
    return { ...state,
      spots          : action.payload.result.spots,
      vehicles       : action.payload.result.vehicles,
      establishments : action.payload.result.establishments,
      products       : action.payload.result.products,
      customers      : action.payload.result.customers,
      smsModel       : action.payload.result.model,
      isLoading      : false,
      isInit         : true
    };
  },

  [actionTypes.SAV_SEND_SMS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.SAV_SEND_SMS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
  [actionTypes.SAV_SEND_SMS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [actionTypes.SAV_CREDIT_POOLS]: (state, action) => {
    return { ...state };
  },
  [actionTypes.SAV_CREDIT_POOLS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
  [actionTypes.SAV_CREDIT_POOLS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

});

export default combineReducers({
  admin: savReducer,
});
