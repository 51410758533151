import { createSelector } from "reselect";
import moment from "moment";

/**
 * Réorganise les données stocks de deux façons différentes en fonction du reorderType
 * Fonction utile dans les deux selectors: getForecastStocksSelector, getForecastStocksPrintSelector
 * @param  {array} stocks
 * @param  {string} reorderType null ou print
 * @return {array}             [description]
 */
const forecastStocksReorder = (stocks, reorderType) => {
  let formattedStocks = [];

  // Stock la date du lundi de chaque semaine demandée
  let weekDates = [];

  stocks.forEach((stock, key) => {
    let category,
      categoryPdfModel,
      productId,
      productName,
      stockData,
      day,
      start,
      end,
      startFormatted,
      endFormatted,
      weekNumber,
      isAvailable;

    // Infos sur les produits et cat
    category = stock["category"];
    categoryPdfModel = stock["categoryPdfModel"];
    productId = stock["productId"];
    productName = stock["name"];
    isAvailable = stock["isAvailable"];

    // Infos sur les dates
    day = moment(stock["date"])
      .locale("en")
      .format("dddd")
      .toLowerCase();

    weekNumber = moment(stock["date"]).format("W");

    start = moment(stock["date"])
      .day(1)
      .week(weekNumber);

    end = moment(stock["date"])
      .day(7)
      .week(weekNumber);
    startFormatted = moment(start).format("YYYY-MM-DD");
    endFormatted = moment(end).format("YYYY-MM-DD");

    // Ajoute les périodes de dates
    let weekIndex = weekDates.findIndex((d) => {
      return (d && d.start !== undefined && d.start === startFormatted)
    })
    if (weekIndex < 0) {
      weekDates.push({start: startFormatted, end: endFormatted});
    }

    /**
     * Infos sur les stocks
     * Si c'est pour du print on modifie pour cumuler les types shop + ikentoo + deliveroo
     */
    let dayStocks = {};
    if (reorderType === "print") {
      dayStocks = {
        types: {
          shop_v2: { qty: 0, active: true },
          deliveroo: { qty: 0, active: true },
          spot: { qty: null, active: true },
          total: { qty: 0 }
        },
        isAvailable: isAvailable
      };
      Object.keys(stock["types"]).forEach(type => {
        let total = dayStocks.types.total.qty;
        let active = stock["types"][type].active;
        // Si le stock ajusté n'est pas null, on prend ce dernier
        let qty = 0;
        qty = (stock["types"][type].tweakStock !== null) ? stock["types"][type].tweakStock : stock["types"][type].stock;

        // Si le total est déjà à -1, un des types est illimité, on laisse comme ça
        if (total !== -1) {
          if (active) {
            total = (qty !== -1) ? total + qty : -1;
          }
        }

        if (type === "shop" || type === "deliveroo" || type === "ikentoo") {
          // 25/06/2020 - pour le moment, le pdf ne retient que shop car ils n'utilisent pas ikentoo & deliveroo
          if (type === "shop") {
            let shop_v2 = dayStocks.types.shop_v2.qty;
            // Si le total du sjop cumulative est déjà à -1, un des types est illimité, on laisse comme ça
            if (shop_v2 !== -1) {
              if (active) {
                shop_v2 = (qty !== -1) ? dayStocks.types.shop_v2.qty + qty : -1;
              }

              dayStocks.types = {
                ...dayStocks.types,
                shop_v2: { qty: shop_v2, active: active },
                total: { qty: total }
              };
            }
          }
          else if (type === "deliveroo") {
            dayStocks.types = {
              ...dayStocks.types,
              [type]: { qty: qty, active: active },
              total: { qty: total }
            };
          }

        } else {
          dayStocks.types = {
            ...dayStocks.types,
            [type]: { qty: qty, active: active },
            total: { qty: total }
          };
        }
      });
    } else {
      stockData = stock["types"][Object.keys(stock["types"])[0]];
      dayStocks = {
        stockId: stockData["stockId"],
        stock: stockData["stock"],
        tweakStock: stockData["tweakStock"],
        order: stockData["order"],
        update: stockData["update"],
        percent: stockData["activityPercentage"],
        lastManuallyUpdated: stockData["lastManuallyUpdated"],
        isAvailable: isAvailable,
        orderByType: stockData["orderByType"] ? stockData["orderByType"] : null
      };
    }

    // Forme le premier niveau, la catégorie de produit
    let index = formattedStocks.findIndex(
      s => s.name !== undefined && s.name === category
    );
    if (index < 0) {
      formattedStocks = [...formattedStocks, { name: category, categoryPdfModel: categoryPdfModel, products: [] }];
      index = formattedStocks.length - 1;
    }

    // Forme le second niveau, le listing des produits
    let productIndex = formattedStocks[index].products.findIndex(
      s => s.id !== undefined && s.id === productId
    );
    if (productIndex < 0) {
      formattedStocks[index].products = [
        ...formattedStocks[index].products,
        { id: productId, active: stock["types"][Object.keys(stock["types"])[0]].active, name: productName, periods: [] }
      ];
      productIndex = formattedStocks[index].products.length - 1;
    }

    // Forme le troisième niveau, les stocks par périodes et jours
    let periodIndex = formattedStocks[index].products[
      productIndex
    ].periods.findIndex(
      s =>
        s.dates !== undefined &&
        s.dates.start === startFormatted &&
        s.dates.end === endFormatted
    );
    if (periodIndex < 0) {
      formattedStocks[index].products[productIndex].periods = [
        ...formattedStocks[index].products[productIndex].periods,
        {
          dates: { start: startFormatted, end: endFormatted },
          days: {}
        }
      ];
      periodIndex =
        formattedStocks[index].products[productIndex].periods.length - 1;
    }
    formattedStocks[index].products[productIndex].periods[periodIndex].days[
      day
    ] = dayStocks;
  });

  // Enferme les index du tableau des périodes dans un tableau pour ensuite gérer la suppresion des périodes et des produits
  // Met à null le total si produit générique indispo pour la version print
  formattedStocks.forEach((category, categoryKey) => {
    category.products.forEach((product, productKey) => {
      let periodIndex = [];
      product.periods.forEach((period, periodKey) => {
        let unset = true;
        Object.keys(period.days).forEach(dayName => {
          if (period.days[dayName].isAvailable === true) {
            unset = false;
          }
          if (reorderType === "print") {
            if (period.days[dayName].isAvailable === false) {
              period.days[dayName] = {
                types: {
                  shop_v2   : { qty: null, active: true },
                  deliveroo : { qty: null, active: true },
                  spot      : { qty: null, active: true },
                  total     : { qty: "indispo" }
                },
                isAvailable: period.days[dayName].isAvailable
              };
            }
          }
        });
        if (unset !== false) {
          periodIndex.push(periodKey);
        }
      });

      // Supprime les périodes dont les indexes sont dans la variable periodIndex
      periodIndex.forEach(periodKey => {
        delete formattedStocks[categoryKey].products[productKey].periods[
          periodKey
        ];
      });
      // Après la suppression, si les périodes sont vides ou que la première valeur est undefined (empty slot), supprime le produit
      if (reorderType === "print" && formattedStocks[categoryKey].products[productKey].periods[0] === undefined) {
        delete formattedStocks[categoryKey].products[productKey];
      } else if (formattedStocks[categoryKey].products[productKey].periods.length < 1) {
        delete formattedStocks[categoryKey].products[productKey];
      }

      // Unset le produit hors print s'il est inactif, dans le print on cumule plusieurs types donc ça ne fonctionne pas
      if(reorderType !== "print" && formattedStocks[categoryKey].products[productKey] && !formattedStocks[categoryKey].products[productKey].active){
        delete formattedStocks[categoryKey].products[productKey];
      }

    });
  });

  /**
   * Ajoute les périodes vides aux produits et reclasse les périodes par date
   */
  formattedStocks.forEach((category, categoryKey) => {
    category.products.forEach((product, productKey) => {

      // Vérifie avant si le produit a au moins une période avec des jours de dispo
      let emptyPeriods = true;
      formattedStocks[categoryKey].products[productKey].periods.forEach((period, periodKey) => {
        if (period) {
          emptyPeriods = false;
        }
      });
      if (emptyPeriods) {
        return;
      }

      // Pour chaque période de dates, ajoute la période au produit si non présent
      weekDates.forEach((dates, weekStartDateKey) => {
        let periodIndex = formattedStocks[categoryKey].products[productKey].periods.findIndex(
          s =>
            s && s.dates !== undefined &&
            s.dates.start === dates.start &&
            s.dates.end === dates.end
        );
        if (periodIndex < 0) {
          formattedStocks[categoryKey].products[productKey].periods.push({
            dates: { start: dates.start, end: dates.end },
            days: {}
          });
        }
      });

      // reclasse les périodes
      formattedStocks[categoryKey].products[productKey].periods.sort(function(a, b) {
        if (a && a.dates !== undefined && moment(a.dates.start).isAfter(b.dates.start)) {
          return 1
        }
        return -1;
      });
    });

  });

  return formattedStocks;
};

/**
 * Formate les stocks prévisonnel avec les périodes
 */
const forecastStocksState = state => state.store.forecastStocks;
const getForecastStocksSelector = createSelector(
  forecastStocksState,
  stocks => {
    // Vérifie s'il y a des données
    if (!stocks.data) {
      return stocks;
    }
    return {
      ...stocks,
      data: forecastStocksReorder(stocks.data)
    };
  }
);

/**
 * Formate les stocks prévisonnel avec les périodes pour le print (pdf)
 */
const getForecastStocksPrintSelector = createSelector(
  forecastStocksState,
  stocks => {
    // Vérifie s'il y a des données
    if (!stocks.printData) {
      return stocks;
    }
    return {
      ...stocks,
      printData: forecastStocksReorder(stocks.printData, "print")
    };
  }
);

/**
 * Formate les catégories de produit
 */
const productCategoriesState = state => state.store.productCategories;
const getProductCategoriesSelector = createSelector(
  productCategoriesState,
  productCategoriesState => {
    let categories = [];

    if (
      productCategoriesState &&
      productCategoriesState.data &&
      productCategoriesState.data.length > 0
    ) {
      productCategoriesState.data.forEach(category => {
        categories = [
          ...categories,
          { value: category.slug, label: category.name }
        ];
      });
    }

    return {
      ...productCategoriesState,
      data: categories
    };
  }
);

const compositionProductCategoriesState = state => state.store.compositionProductCategories;
const getCompositionProductCategoriesSelector = createSelector(
  compositionProductCategoriesState,
  compositionProductCategoriesState => {
    let categories = [];

    if (
      compositionProductCategoriesState &&
      compositionProductCategoriesState.data &&
      compositionProductCategoriesState.data.length > 0
    ) {
      compositionProductCategoriesState.data.forEach(category => {
        categories = [
          ...categories,
          { value: category.slug, label: category.name }
        ];
      });
    }

    return {
      ...compositionProductCategoriesState,
      data: categories
    };
  }
);

export {
  getForecastStocksSelector,
  getForecastStocksPrintSelector,
  getProductCategoriesSelector,
  getCompositionProductCategoriesSelector
};
