import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import swal from 'sweetalert';
import { operations } from "state/ducks/store/customer";
import { notificationsOperations } from "state/ducks/notifications";
import styled from "@emotion/styled";

const CustomerNotificationButton = (props) => {
  const { customerId } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const postSuspectPoolCustomerNotification = (customerId, e) => {
    e.preventDefault();
    swal({
      title: "Mail de demande de régularisation",
      text: "Etes-vous sûr de vouloir envoyer un mail de demande de régularisation à ce client ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        setIsLoading(true);
        dispatch(operations.postSuspectPoolCustomerNotification(customerId)).then(
          (result) => {
            setIsLoading(false);
            dispatch(notificationsOperations.getResponseSuccess('Un mail de demande de régularisation a bien été envoyé'));
          },
          (error) => {
            setIsLoading(false);
          }
        )
      }
    });
  };

  return (
    <CustomButton
      disabled={isLoading}
      onClick={(e) => postSuspectPoolCustomerNotification(customerId, e)}
    >
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} size="lg" spin />
      )
      : (
        <><FontAwesomeIcon icon={faEnvelope} /> Régularisation  </>
      )}
    </CustomButton>
  );
};

const CustomButton = styled.button`
  background-color: ${(props) => props.theme.colors.black };
  transition: 300ms;
  width: auto;
  margin-left: 0.5rem;
  padding: 0.1rem 0.5rem;
  color: white;
  border: none;
  border-radius: 3px;
  min-height: 2rem;
  font-size: ${(props) => props.theme.fonts.size.xs};
  &:hover {
    opacity: 0.7;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`

CustomerNotificationButton.propTypes = {
  customerId: PropTypes.number,
};

export default CustomerNotificationButton;
