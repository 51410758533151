import React from "react";
import styled from "@emotion/styled";
import { Modal, Col, Row } from "react-bootstrap";
import moment from "moment";
import Loader from "views/universal/Loader/Loader";

const PopupOrders = (props) => {

  const {
    show,
    onHide,
    onEscapeKeyDown,
    customer,
    isLoading
  } = props;

  require("moment/locale/fr.js");

  return (
      <Popup
        size="lg"
        show={show}
        onHide={onHide}
        onEscapeKeyDown={onEscapeKeyDown}
      >
        {isLoading
          ?
            <Loader items={2} />
          :
            <>
            {customer.orders ?
              <>
                <Row>
                  <Col>
                    <Title className="text-center">
                      <small>
                        {customer.lastname + " " + customer.firstname}
                      </small>
                      <br/><span>{customer.orders && customer.orders.length > 0 ? `${customer.orders && customer.orders.length} commande(s)` : "Aucune commande"} </span>
                    </Title>
                  </Col>
                </Row>
                <Header noGutters>
                  <Col sm={2}>
                    <p><b>Numéro de commande</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Numéro du spot</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Commande pour le</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Prix TTC</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Fiche prod</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Facture</b></p>
                  </Col>
                </Header>

                {customer.orders &&
                  customer.orders.map((order, key) => (
                  <Order key={order.id} noGutters>
                    <Col sm={2}><p><b>{order.numberOrder ? order.numberOrder : "Inconnu"}</b></p></Col>
                    <Col sm={2}><p>{order.numberSpot ? order.numberSpot : "En boutique"}</p></Col>
                    <Col sm={2}><p>{moment(order.date).format("DD-MM-YYYY")}</p></Col>
                    <Col sm={2}><p>{order.price / 100} €</p></Col>
                    <Col sm={2}>
                      <p><Link href={order.link} target="_blank">
                        Fiche prod
                      </Link></p>
                    </Col>
                    <Col sm={2}>
                      <p><Link href={order.invoicePublicLink} target="_blank">
                        Facture
                      </Link></p>
                    </Col>
                  </Order>
                  ))}

              </>
            : "Pas de commande pour ce client."}
            </>
        }
      </Popup>
  );
};


const Popup = styled(Modal)`
  .modal-content{
    background-color: #ecf0f5 !important;
  }
  p {
    margin-bottom: 0rem;
  }
`;

const Order = styled(Row)`
  padding: 0.6rem;
  background-color: white;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h3`
  span{
    font-weight: bold;
    color: ${(props) => props.theme.colors.green};
  }
`;

const Header = styled(Row)`
  color: grey;
  padding: 0.6rem;
  font-size: 0.8rem;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.green};
  text-decoration: underline;
  text-align: right;
`;


PopupOrders.propTypes = {

};

export default PopupOrders;
