import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Form } from "react-bootstrap";
import Card from "views/universal/Components/Card";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from 'react-currency-input-field';

const Variables = (props) => {
  const { typeFilter, freeCreditAmount, note, handleChange, addCreditOrderAmount, addSupercookieCode } = props;

  return (
    <Row className="mb-2">
      <Col xs={12} sm={6} md={6}>
        <Card title="Créditer la cagnotte de ses clients de" description="" icon={faTicketAlt} colorIcon={'#abd156'}>
          { typeFilter === 'order' && <Form.Check
              type="checkbox"
              label="Montant de la commande à créditer sur la cagnotte"
              checked={addCreditOrderAmount}
              onChange={(e) => handleChange('addCreditOrderAmount', e.target.checked)}
              className="mt-2"
            />
          }
            <hr/>
            <p>Montant libre à créditer</p>
            <CurrencyInput
              placeholder="Montant libre à créditer"
              className="form-control"
              defaultValue={Number.isNaN(freeCreditAmount) ? 0 : freeCreditAmount}
              decimalSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              allowNegativeValue={false}
              intlConfig={{"locale":"fr-FR","currency":"EUR"}}
              onValueChange={(value, name) => handleChange('freeCreditAmount', parseFloat(value))}
            />
            <hr/>
          <Form.Check
            type="checkbox"
            label="Offrir un code supercookie"
            checked={addSupercookieCode}
            onChange={(e) => handleChange('addSupercookieCode', e.target.checked)}
            className="mt-2"
          />
          <br/>
          <Form.Control
            type="text"
            defaultValue={note}
            onChange={(e) => handleChange('note', e.target.value)}
            placeholder="Motif de crédit (visible dans le mail client)"
          />
        </Card>
      </Col>
    </Row>
  );
};

Variables.propTypes = {
  amount: PropTypes.number,
  note: PropTypes.string,
  handleChange: PropTypes.func,
  addCreditOrderAmount: PropTypes.bool,
  addSupercookieCode: PropTypes.bool,
};

export default Variables;
