import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import styled from "@emotion/styled";
import Item from "./Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import "react-dates/lib/css/_datepicker.css";
import Filters from "./Filters";
import { CSSTransition } from "react-transition-group";
import HelpDocumentation from "views/universal/Layout/HelpDocumentation"

const Real = (props) => {
  require("moment/locale/fr.js");

  const { establishment, productCategories } = props;

  const realProductStocks = useSelector(
    (state) => state.store.realProductStocks,
    shallowEqual
  );

  return (
    <>
      <Filters
        isLoading={realProductStocks.isLoading}
        establishment={establishment}
        productCategories={productCategories}
      />

      {realProductStocks.data && realProductStocks.data.length > 0 ? (
        <CSSTransition
          appear={true}
          in={true}
          timeout={1000}
          classNames="transition"
        >
          <div>
            {Object.keys(realProductStocks.data).map((e) => (
              realProductStocks.data[e]?.isAvailable && <Item
                key={'real-'+realProductStocks.data[e].productId+'-'+realProductStocks.data[e].date}
                stock={realProductStocks.data[e]}
                indexProduct={e}
              />
            ))}
          </div>
        </CSSTransition>
      ) : (
        <Explain>
          <p>
            <FontAwesomeIcon icon={faHandPointUp} size="lg" />
            <br />
            Lancer une recherche ci-dessus pour voir l'état d'un stock à un
            moment T et modifier un stock exceptionnellement pour un jour !
          </p>
        </Explain>
      )}
      <HelpDocumentation title="Voir la documentation sur les stocks temps réel" url="https://moulindev.freshdesk.com/support/solutions/articles/48001080200-gestion-des-stocks"/>
    </>
  );
};

const Explain = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

Real.propTypes = {
  establishment: PropTypes.string,
  activityTypes: PropTypes.array,
};

export default Real;
