export const INIT_ADMIN_SAV = "store/sav/INIT_ADMIN_SAV";
export const INIT_ADMIN_SAV_COMPLETED = "store/sav/INIT_ADMIN_SAV_COMPLETED";
export const INIT_ADMIN_SAV_FAILED = "store/sav/INIT_ADMIN_SAV_FAILED";

export const SAV_SEND_SMS = "store/sav/SAV_SEND_SMS";
export const SAV_SEND_SMS_COMPLETED = "store/sav/SAV_SEND_SMS_COMPLETED";
export const SAV_SEND_SMS_FAILED = "store/sav/SAV_SEND_SMS_FAILED";

export const SAV_CREDIT_POOLS = "store/sav/SAV_CREDIT_POOLS";
export const SAV_CREDIT_POOLS_COMPLETED = "store/sav/SAV_CREDIT_POOLS_COMPLETED";
export const SAV_CREDIT_POOLS_FAILED = "store/sav/SAV_CREDIT_POOLS_FAILED";
