import { combineReducers } from "redux";
import moment from "moment";
import * as actionTypes from "./types";
import { createReducer } from "../../utils";

import historicReducer from 'state/ducks/store/tips/reducers'
import customersReducer from 'state/ducks/store/customers/reducers'
import savReducer from 'state/ducks/store/sav/reducers'

/* State shape
{
  forecastStocks: [ object ]
  realProductStocks: [ object ]
  realCompositionProductStocks: [ object ]
  productALaCartes: [ object ]
  productCategories: [ object ]
  compositionProductCategories: [ object ]
  activityTypes: [ object ]
}
*/

const initialRealProductStock = { isLoading: false, isReset: true };
const initialRealCompositionProductStock = { isLoading: false, isReset: true };
const initialProductCategories = { isLoading: false };
const initialCompositionProductCategories = { isLoading: false };
const initialProductALaCartes = {
  isLoading: false,
  offset: 0,
  limit: 10,
  isReset: true,
  search: {
    establishmentId: null,
    categories: [],
    name: null,
    startDate: null,
    endDate: null,
    offset: 0,
    limit: 15,
    isReset: true,
  },
};
const initialForecastStock = {
  isLoading: false,
  search: {
    establishmentId: null,
    types: null,
    categories: [],
    weeks: [],
    days: [],
    offset: 0,
    limit: 15,
    isReset: true,
  },
  offset: 0,
  limit: 10,
  isReset: true,
  isPrint: false,
};

const realProductStocksReducer = createReducer(initialRealProductStock)({

  [actionTypes.CLEAR_REAL_PRODUCT_STOCKS]: (state, action) => {
    return { initialRealProductStock };
  },
  [actionTypes.GET_REAL_PRODUCT_STOCKS]: (state, action) => {
    const { date, category, name, isReset } = action.payload;
    return {
      ...state,
      search: [date, category, name],
      isLoading: true,
      isReset: isReset,
    };
  },
  [actionTypes.GET_REAL_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result.data };
  },
  [actionTypes.UPDATE_REAL_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    const { type, index } = action.payload;

    let indexInt = parseInt(index);

    const updateItem = {
      ...state.data[indexInt],
      types: {
        ...state.data[indexInt].types,
        [type]: action.payload.result.types[type],
      },
    };

    return {
      ...state,
      data: [
        ...state.data.slice(0, indexInt),
        updateItem,
        ...state.data.slice(indexInt + 1),
      ],
    };
  },
  [actionTypes.POST_REAL_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    const { type, index } = action.payload;

    let indexInt = parseInt(index);

    const updateItem = {
      ...state.data[indexInt],
      types: {
        ...state.data[indexInt].types,
        [type]: action.payload.result.types[type],
      },
    };

    return {
      ...state,
      data: [
        ...state.data.slice(0, indexInt),
        updateItem,
        ...state.data.slice(indexInt + 1),
      ],
    };
  },
});

const realCompositionProductStocksReducer = createReducer(initialRealCompositionProductStock)({

  [actionTypes.CLEAR_REAL_COMPOSITION_PRODUCT_STOCKS]: (state, action) => {
    return { initialRealCompositionProductStock };
  },
  [actionTypes.GET_REAL_COMPOSITION_PRODUCT_STOCKS]: (state, action) => {
    const { date, category, name, isReset } = action.payload;
    return {
      ...state,
      search: [date, category, name],
      isLoading: true,
      isReset: isReset,
    };
  },
  [actionTypes.GET_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result.data };
  },
  [actionTypes.UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    const { type, index } = action.payload;

    let indexInt = parseInt(index);

    const updateItem = {
      ...state.data[indexInt],
      types: {
        ...state.data[indexInt].types,
        [type]: action.payload.result.types[type],
      },
    };

    return {
      ...state,
      data: [
        ...state.data.slice(0, indexInt),
        updateItem,
        ...state.data.slice(indexInt + 1),
      ],
    };
  },
  [actionTypes.POST_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED]: (state, action) => {
    const { type, index } = action.payload;

    let indexInt = parseInt(index);

    const updateItem = {
      ...state.data[indexInt],
      types: {
        ...state.data[indexInt].types,
        [type]: action.payload.result.types[type],
      },
    };

    return {
      ...state,
      data: [
        ...state.data.slice(0, indexInt),
        updateItem,
        ...state.data.slice(indexInt + 1),
      ],
    };
  },
});

const forecastStocksReducer = createReducer(initialForecastStock)({
  // Met à jour les filtres seulement si c'est pour une recherche hors print
  [actionTypes.GET_FORECAST_STOCKS]: (state, action) => {
    const {
      establishmentId,
      types,
      categories,
      weeks,
      days,
      offset,
      limit,
      isReset,
      isPrint,
    } = action.payload;
    if (!isPrint) {
      return {
        ...state,
        search: {
          establishmentId: establishmentId,
          types: types,
          categories: categories,
          weeks: weeks,
          days: days,
          offset: offset,
          limit: limit,
          isReset: isReset,
        },
        isLoading: true,
        isReset: isReset,
        isPrint: isPrint,
      };
    } else {
      return { ...state, isLoading: true, isReset:isReset, isPrint:isPrint };
    }
  },
  [actionTypes.GET_FORECAST_STOCKS_COMPLETED]: (state, action) => {
    const { isReset, isPrint } = action.payload;

    if (!isPrint) {
      return {
        ...state,
        isLoading: false,
        data:
          state.data && !isReset
            ? [...state.data, ...action.payload.result.data]
            : action.payload.result.data,
        total: action.payload.result.total,
      };
    } else {
      return { ...state, isLoading: false, printData:action.payload.result.data };
    }
  },
  [actionTypes.UPDATE_FORECAST_STOCKS]: (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [actionTypes.UPDATE_FORECAST_STOCKS_FAILED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [actionTypes.UPDATE_FORECAST_STOCKS_COMPLETED]: (state, action) => {
    // Body stocks contient les données envoyées pour l'update
    if (action.meta && action.meta.body && action.meta.body.stocks) {

      action.meta.body.stocks.forEach((stockSent) => {
        stockSent.stocks.forEach((ss) => {

          // Temporaire / On enleve une heure pour être raccord avec les modifications de date faites en amont (23h00). meta.body décallait la date d'origine d'une heure et passait donc au jours suivant. Probleme plus profond, à corriger plus tard
          let ssDate = moment(ss.date).subtract(1, 'hours');
          let date = moment(ssDate).format("YYYY-MM-DD");

          let percent = 100;
          let lastManuallyUpdated = null;
          let stockQty = null;

          // Met a jour les stock et la date de maj ainsi que pour les dates suivantes
          state.data.forEach((stock, index) => {
            if (
              stockSent.productId === stock.productId &&
              moment(date).isoWeekday() === moment(stock.date).isoWeekday() &&
              moment(stock.date).isSameOrAfter(date)
            ) {

              // Si c'est le stock mis à jour, on met à jour son lastManuallyUpdated et on garde son % pour le calcul des quantité des jours n+
              if (moment(stock.date).isSame(date) === true) {
                percent             = stock.types[ss.type].activityPercentage;
                lastManuallyUpdated = moment().format();
                stockQty            = ss.quantity;
              }

              // Pour les semaines suivantes, recalcule le stock avec le % sauf si le stock a ete mis à jour manuellement
              if (moment(stock.date).isAfter(date) === true) {
                // Si stock manuel, on passe au suivant car pas de maj
                if (stock.types[ss.type].lastManuallyUpdated !== null) {
                  return;
                }
                // On cherche le stock de la semaine passée s'il existe pour prendre ces infos plutot que le stock qui est réellement mis à jour pour etre plus precis (le +2 s'appuie sur le +1 au lieu du n)
                let previousWeekDayIndex = state.data.findIndex(s => {
                  let previousWeekDay = moment(stock.date).subtract(7, 'days');
                  return (s.types[ss.type] !== undefined && moment(previousWeekDay).format("YYYY-MM-DD") === moment(s.date).format("YYYY-MM-DD") && s.productId === stock.productId );
                });

                if (previousWeekDayIndex !== undefined) {
                  stockQty = Math.floor(
                    Math.ceil(
                      (state.data[previousWeekDayIndex].types[ss.type].stock / (
                        state.data[previousWeekDayIndex].types[ss.type].activityPercentage / 100
                      ))
                    )
                    *
                    ((stock.types[ss.type].activityPercentage) / 100));
                }
                else {
                  stockQty = Math.floor(ss.quantity / ((percent) / 100));
                }
                lastManuallyUpdated = null;
              }

              let updateItem = {
                ...state.data[index],
                types: {
                  [ss.type]: {
                    ...state.data[index].types[ss.type],
                    stock: parseInt(stockQty),
                    update: moment().format(),
                    lastManuallyUpdated: lastManuallyUpdated
                  },
                },
              };

              state = {
                ...state,
                data: [
                  ...state.data.slice(0, index),
                  updateItem,
                  ...state.data.slice(index + 1),
                ],
              };
            }
          });
        });
      });

      return {
        ...state,
        isLoading: false,
      };
    }
  },
});

const productALaCartesReducer = createReducer(initialProductALaCartes)({
  [actionTypes.GET_PRODUCT_A_LA_CARTES]: (state, action) => {
    const {
      startDate,
      endDate,
      establishment,
      name,
      category,
      offset,
      limit,
      isReset,
    } = action.payload;

    return {
      ...state,
      search: {
        startDate: startDate,
        endDate: endDate,
        establishment: establishment,
        name: name,
        category: category,
        offset: offset,
        limit: limit,
        isReset: isReset,
      },
      isLoading: true,
      isReset: isReset,
    };

  },
  [actionTypes.GET_PRODUCT_A_LA_CARTES_COMPLETED]: (state, action) => {
    const { isReset } = action.payload;

    return {
      ...state,
      isLoading: false,
      data:
        state.data && !isReset
          ? [...state.data, ...action.payload.result.data]
          : action.payload.result.data,
      total: action.payload.result.total,
    };

  },

});

const productCategoriesReducer = createReducer(initialProductCategories)({
  [actionTypes.GET_PRODUCT_CATEGORIES]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_PRODUCT_CATEGORIES_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
});

const compositionProductCategoriesReducer = createReducer(initialCompositionProductCategories)({
  [actionTypes.GET_COMPOSITION_PRODUCT_CATEGORIES]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_COMPOSITION_PRODUCT_CATEGORIES_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
});

const activityTypesReducer = createReducer(initialProductCategories)({
  [actionTypes.GET_ACTIVITY_TYPES]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_ACTIVITY_TYPES_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },
});

export default combineReducers({
  realProductStocks: realProductStocksReducer,
  realCompositionProductStocks: realCompositionProductStocksReducer,
  forecastStocks: forecastStocksReducer,
  productALaCartes: productALaCartesReducer,
  productCategories: productCategoriesReducer,
  compositionProductCategories: compositionProductCategoriesReducer,
  activityTypes: activityTypesReducer,
  tips: historicReducer,
  customers: customersReducer,
  sav: savReducer
});
