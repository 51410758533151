import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import 'moment/locale/fr';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customersOperations } from "state/ducks/store/customers";
import { notificationsOperations } from "state/ducks/notifications";

const Vigilance = (props) => {

  const dispatch = useDispatch();

  const {
    customer
  } = props

  const [isLoading, setIsLoading] = useState(false);

  const options = [
    {value: 0, label: "Aucun degré de vigilance"},
    {value: 1, label: "Niveau 1 (vert)"},
    {value: 2, label: "Niveau 2 (orange)"},
    {value: 3, label: "Niveau 3 (rouge)"}
  ];

  const updateStoreCustomer = (optionSelected) => {
    setIsLoading(true);
    let currentDate = new Date();
    dispatch(customersOperations.updateStoreCustomer({
      id: customer.id,
      vigilanceLevel: optionSelected.value,
      vigilanceLevelDate: moment(currentDate).format('YYYY-MM-DD HH:mm:ss')
    })).then(
      (result) => {
        setIsLoading(false);
        dispatch(notificationsOperations.getResponseSuccess('Mise à jour effectuée'));
      },
      (error) => {
        setIsLoading(false);
        dispatch(notificationsOperations.getResponseError('Erreur, le statut n\'a pas pu être mis à jour'));
      }
    )
  };

  return (
    isLoading ? (
      <><FontAwesomeIcon icon={faSpinner} size="lg" spin /> &nbsp;&nbsp;</>
    )
    : (
      <Select
        onChange={(selectedOption) => updateStoreCustomer(selectedOption)}
        value={customer.vigilance_level && customer.vigilance_level > 0 ? options[customer.vigilance_level] : 0}
        options={options}
        placeholder="Indice de vigilance"
        disabled={isLoading}
      />
    )
  );
};


Vigilance.propTypes = {};

Vigilance.defaultProps = {};

export default Vigilance;
