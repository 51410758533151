import React, { useState } from "react";
import styled from "@emotion/styled";
import TicketRestaurant from "./TicketRestaurant";
import { Col, Row } from "react-bootstrap";
import {
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
  faMeh
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from '@tippyjs/react';
import { ticketRestaurantOperations } from "state/ducks/store/ticketRestaurant";
import { notificationsOperations } from "state/ducks/notifications";
import { useDispatch } from "react-redux";

const Customer = (props) => {
  const {
    customer,
    getCustomerPool,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSuspect, setIsSuspect] = useState(customer.trSuspect)

  const dispatch = useDispatch();

  const updateSuspectCustomer = (customerId, e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(ticketRestaurantOperations.updateSuspectCustomer(customerId)).then(
      (result) => {
        setIsLoading(false);
        setIsSuspect(!isSuspect);
        dispatch(notificationsOperations.getResponseSuccess('Statut du client suspect bien mis à jour'));
      },
      (error) => {
        setIsLoading(false);
        dispatch(notificationsOperations.getResponseError('Erreur, le statut n\'a pas pu être mis à jour'));
      }
    )
  };

  return (
    <>
    <Container noGutters>
      <Col xs={12} sm={8}>
        <Title>
        {isSuspect &&
          <FontAwesomeIcon icon={faMeh} color="#ff9900"/>
        } <span>{customer.lastname} {customer.firstname}</span> - {customer.phone} - {customer.email}</Title>
      </Col>
      <Col xs={12} sm={4} align='right'>

          <Tippy content={
            isSuspect ?
                <span>Cliquez pour ne plus mettre ce client en suspect</span>
              : <span>Cliquez pour mettre ce client en suspect</span>
            }
            >
            <SuspectButton
              isSuspect={isSuspect}
              disabled={isLoading}
              onClick={(e) => updateSuspectCustomer(customer.customerId, e)}
            >

              {isLoading ? (
                <><FontAwesomeIcon icon={faSpinner} size="lg" spin /> &nbsp;&nbsp; Suspect</>
              )
              : (
                <>
                  {isSuspect
                    ?
                      <><FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp; Suspect</>
                    :
                      <><FontAwesomeIcon icon={faCircle} /> &nbsp;&nbsp; Suspect</>
                  }

                </>
              )}
            </SuspectButton>
          </Tippy>

      </Col>
    </Container>
    {
      customer.ticketRestaurants.map((tr) =>
        <TicketRestaurant
          ticketRestaurant={tr}
          key={tr.ticket.id}
          getCustomerPool={getCustomerPool}
        />
      )

    }
    </>
  );
}


const Title = styled.p`
  margin-bottom: 0rem;
  font-size: 1.0rem;
  span{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Container = styled(Row)`
  background-color: #eee;
  margin-top: 1.0rem;
  padding: 1.0rem;
  color: black;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const SuspectButton = styled.button`
  background-color: ${(props) => props.isSuspect ? props.theme.colors.orange : '#ccc' };
  transition: 300ms;
  width: auto;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 4px;
  color: white;
  &:hover {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.orange};
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

Customer.propTypes = {};

Customer.defaultProps = {};

export default Customer;
