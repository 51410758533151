import React from "react";
import styled from "@emotion/styled";
import Loader from "views/universal/Loader/Loader";
import Filters from "./Filters";
import Customer from "./Customer";

const DepositValidationPage = (props) => {
  const {
    establishment,
    vehicles,
    spots,
    trByCustomer,
    search,
    isLoadingPage,
    getCustomerPool,
    getCustomerAdvantage
  } = props;

  return (
    <>
      {isLoadingPage ? (
        <Loader items={2} />
      ) : (
        <>
          <Filters
            establishment={establishment}
            vehicles={vehicles}
            spots={spots}
          />
          {trByCustomer.length > 0 ? (
            <>
              {trByCustomer
                .filter(
                  (customer) =>
                    (search.vehicle === null ||
                      customer.charretteId === search.vehicle) &&
                    (search.spot === null || customer.spotId === search.spot) &&
                    (search.lastname === null ||
                      customer.lastname
                        .toLowerCase()
                        .includes(search.lastname.toLowerCase()))
                )
                .map((customer) => (
                  <Customer key={customer.customerId} customer={customer} getCustomerPool={getCustomerPool} getCustomerAdvantage={getCustomerAdvantage} />
                ))}
            </>
          ) : (
            <Help>
              <p>Il n'y a pas de Ticket Restaurant à traiter pour le moment.</p>
            </Help>
          )}
        </>
      )}
    </>
  );
};

const Help = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

DepositValidationPage.propTypes = {};

DepositValidationPage.defaultProps = {};

export default DepositValidationPage;
