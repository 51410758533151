import * as actionTypes from "./types";

export const initPage = () => ({
  type: actionTypes.INIT_ADMIN_SAV,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/sav.json`,
    method: "GET"
  },
  payload: {
  },
});

export const sendSMS = (orderIds, cause, start, end) => ({
  type: actionTypes.SAV_SEND_SMS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/sav/sendsms.json`,
    method: "POST",
    body: {
      orderIds: orderIds,
      cause   : cause,
      start   : start,
      end     : end
    },
  },
});

export const creditPools = (type, params) => {

  let path = `/v2/admin/store/sav/credit.json`;
  if (type === 'customer') {
    path = `/v2/admin/store/sav/credit/bycustomer.json`;
  }
  return {
    type: actionTypes.SAV_CREDIT_POOLS,
    meta: {
      async: true,
      blocking: true,
      path: path,
      method: "POST",
      body: params,
    },
  }
}
