import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// Gestion des styles
import "assets/fonts/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "emotion-theming";
import { theme } from "styles/Theme.js";
// eslint-disable-next-line
import Global from "styles/Global";
import 'tippy.js/dist/tippy.css'; // optional

// Listing des routes
import routes from "routes";

//import de Provider qui va permettre de fournir le store aux composants React
import { Provider } from "react-redux";
import { store, persistor } from "state/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from 'redux-persist';

// Gestion error
import ErrorBoundary from "utils/ErrorBoundary";

// Reinitialisation du store au déploiement
const deployVer = process.env.REACT_APP_VERSION;
const curVersion = localStorage.getItem('version');
if(curVersion === 'undefined' || curVersion === null || curVersion !== deployVer){
  localStorage.setItem('version', deployVer);
  persistStore(store).purge();
  document.location.reload();
}

const App = (props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorBoundary>
              <Switch>
                {routes.map((route, key) => (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={key}
                  />
                ))}
              </Switch>
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
