/**
 * RECUPERE LES CLIENTS SUSPECTS
 */
export const INIT_ADMIN_CUSTOMER_POOL_SUSPECT = "store/customer/INIT_ADMIN_CUSTOMER_POOL_SUSPECT";
export const INIT_ADMIN_CUSTOMER_POOL_SUSPECT_COMPLETED = "store/customer/INIT_ADMIN_CUSTOMER_POOL_SUSPECT_COMPLETED";
export const INIT_ADMIN_CUSTOMER_POOL_SUSPECT_FAILED = "store/customer/INIT_ADMIN_CUSTOMER_POOL_SUSPECT_FAILED";

/**
 * ENVOI DE NOTIFICATION AU CLIENT
 */
export const POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION = "store/customer/POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_POOL";
export const POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_COMPLETED = "store/customer/POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_COMPLETED";
export const POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_FAILED = "store/customer/POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_FAILED";

/**
 * BLOQUE DEBLOQUE PAIEMENT TR
 */
export const UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE = "store/customer/UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE";
export const UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE_COMPLETED = "store/customer/UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE_COMPLETED";
export const UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE_FAILED = "store/customer/UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE_FAILED";

/**
 * Recherche un client
 */
export const SEARCH_SUSPECT_POOL_CUSTOMER = "store/customer/SEARCH_SUSPECT_POOL_CUSTOMER";
