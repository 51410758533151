import * as actionTypes from "./types";

/**
 * Recupère un établissement
 * @param  {int} establishmentId
 */
export const getEstablishment = (establishmentId) => ({
  type: actionTypes.GET_ESTABLISHMENT,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/establishments/${establishmentId}.json`,
    method: "GET"
  }
});
