import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Variables from "./Variables";
import swal from 'sweetalert';
import PropTypes from "prop-types";

const Phone = (props) => {

  const { sendSMS, smsModel, isLoading } = props;

  const [cause, setCause]     = useState('');
  const [start, setStart]     = useState('');
  const [end, setEnd]         = useState('');
  const [content, setContent] = useState(null);
  const [cost, setCost]       = useState(0);

  const sprintf = require('sprintf-js').sprintf;

  useEffect(() => {
    var c = sprintf(smsModel, cause, start, end, '0*********');
    setContent(c);
    // Cout du sms
    // @source https://dev.mailjet.com/sms/guides/encoding/
    // Je divise par 140 pour prévoir les accents qui comptent pour plus de caractères
    var lengthContent = c.length;
    setCost(4 * Math.ceil(lengthContent / 140));
  }, [cause, start, end, smsModel, sprintf]);


  /**
   * Gère le changement des variables pour le sms
   */
  const handleVariablesChange = (variableName, value) => {
    if (variableName === 'cause') {
      setCause(value);
    }
    if (variableName === 'start') {
      setStart(value);
    }
    if (variableName === 'end') {
      setEnd(value);
    }
  }

  const areVariablesOk = () => {
    if (cause === '' || start === '' || end === '') {
      return false;
    }
    return true;
  }

  /**
   * Envoie le sms
   */
  const send = () => {

    if (!areVariablesOk()) {
      swal({
        title: "Pas si vite !",
        text: "Merci de renseigner une cause et un retard estimé avant d'envoyer le SMS",
        icon: "warning",
        buttons: {
          ok: "Ok",
        },
      })
      return;
    }
    swal({
      title: "Envoyer un sms ?",
      text: "Etes-vous sûr de vouloir envoyer un sms à tous les clients ayant commandé aujourd'hui sur ces points/charrettes ?",
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Valider",
      },
    })
    .then((confirm) => {
      if (confirm === true) {
        sendSMS(cause, start, end);
      }
    });
  }

  return (
    <Iphone>
      <i>Speaker</i>
      <b>Camera</b>
      <Variables
        handleChange={handleVariablesChange}
        cause={cause}
        start={start}
        end={end}
      />
      <Badge><div>Estimation du coût du sms: {cost / 100} €</div></Badge>
      <p>{content}</p>
      <Save>
        {isLoading
          ?
            <button
              disabled="true"
            >
              En cours...
            </button>
          :
            <button
              onClick={e => send()}
            >
              Envoyer le sms
            </button>
        }
      </Save>
    </Iphone>
  );
};

const Badge = styled.div`
  padding: 0rem 1.0rem 0.5rem 1rem;
  div{
    padding: 0.2rem;
    border-radius: 50px;
    text-align: center;
    background-color: #111;
    color: white;
  }
`;

const Iphone = styled.div`
    position: relative;
    margin: 40px auto;
    width: 320px;
    background-color: rgb(66,152,83);
    background-image: linear-gradient(60deg, rgb(66,152,83) 1%,rgb(144,163,69) 100%);
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 10px #111;
    &:before,
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      bottom: 7px;
      width: 140px;
      height: 4px;
      background-color: #f2f2f2;
      border-radius: 10px;
    }

    i,b,s,span {
      position: absolute;
      display: block;
      color: transparent;
    }
    i {
      top: 5px;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 15%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
    }
    b {
      left: 10%;
      top: 5px;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      &:after {
        content: '';
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
      }
    }
    p {
      color: #fff;
      text-align: left;
      text-decoration: none;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 100;
      padding: 0.5rem 1.0rem 8.0rem 1.0rem;
    }

`;

const Save = styled.div`
  text-align: center;
  button {
    transition: 300ms;
    width: auto;
    border-radius: 50px;
    margin-bottom: 2.0rem;
    background-color: white;
    color: #111;
    border: none;
    padding: 0.5rem 2.5rem;
    &:hover {
      opacity: 0.7;
    }
    :disabled,
    button[disabled] {
      opacity: 0.5;
    }
  }
`;

Phone.propTypes = {
  sendSMS: PropTypes.func,
  smsModel: PropTypes.string,
  isLoading: PropTypes.bool
};

Phone.defaultProps = {
  smsModel: '',
  isLoading: false
};

export default Phone;
