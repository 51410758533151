import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * La gestion du stock "vide" est un peu galère d'ou les nombreuses verifs du type undefined, null ou "" pour supprimer le message d'erreur dans la console
 */
const StockQuantityInput = (props) => {
  const { quantity, onChange, disabled, isAvailable } = props;

  const [stockQty, setStockQty] = useState(quantity);

  const onChangeFunc = (e) => {
    setStockQty(e.target.value !== "" ? e.target.value : -1);
    onChange(e.target.value !== "" ? e.target.value : -1);
  };

  useEffect(() => {
    setStockQty(quantity !== -1 ? quantity : -1);
  }, [quantity]);

  return (
    <CustomInput
      type="number"
      value={(isNaN(stockQty) || stockQty === -1) ? "" : stockQty}
      name="stockQty"
      placeholder={isAvailable ? 'Illimité' : 'Désactivé'}
      onChange={(e) => onChangeFunc(e)}
      disabled={disabled || !isAvailable ? true : false}
    />
  );
};

const CustomInput = styled(Form.Control)`
  height: auto !important;
  display: inline-block;
  padding: 0rem 0.5rem !important;
  width: 90%;
`;

StockQuantityInput.propTypes = {
  quantity   : PropTypes.number,
  onChange   : PropTypes.func,
  disabled   : PropTypes.bool,
  isAvailable: PropTypes.bool,
};

export default StockQuantityInput;
