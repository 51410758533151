import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Form, Col, Row } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { customersOperations } from "state/ducks/store/customers";
import Autosuggest from "react-autosuggest";
import Loader from "views/universal/Loader/Loader";

const Filter = (props) => {
  require("moment/locale/fr.js");

  const { customers, isLoadingPage } = props;

  const dispatch = useDispatch();

  const [customerValue, setCustomerValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Fonction qui permet de rechercher les détails d'un client
  const searchCustomer = (customer, e) => {
    e.preventDefault();
    if (customer) {
      dispatch(customersOperations.getStoreCustomerInfo(customer));
      setCustomerValue("");
    }
  };

  /**
   * Fonction qui recherche parmis la liste des clients
   */
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let returnCustomers = [];

    if (inputLength !== 0) {
      returnCustomers = customers.filter((customer) => {
        // Isole chaque string apres un espace
        let values = inputValue.split(" ");
        let customerOk = true;
        if (values.length > 0) {
          values.forEach((v) => {
            if (
              !customer.lastname.toLowerCase().includes(v.toLowerCase()) &&
              !customer.email.toLowerCase().includes(v.toLowerCase()) &&
              !customer.firstname.toLowerCase().includes(v.toLowerCase()) &&
              !customer.id.toString().toLowerCase().includes(v.toLowerCase()) &&
              (customer.phone === null ||
                !customer.phone
                  .toString()
                  .toLowerCase()
                  .replace(/\s/g, "")
                  .includes(v.toLowerCase().replace(/\s/g, ""))) &&
              (customer.code_id === null ||
                !customer.code_id
                  .toString()
                  .toLowerCase()
                  .includes(v.toLowerCase()))
            ) {
              customerOk = false;
            }
          });
        }
        return customerOk;
      });
    }

    return returnCustomers;
  };

  const getSuggestionValue = (suggestion) =>
    suggestion.lastname + " " + suggestion.firstname;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.lastname +
        " " +
        suggestion.firstname +
        " - " +
        suggestion.email +
        " - " +
        suggestion.phone +
        " - " +
        suggestion.code_id +
        " - " +
        suggestion.id}
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <>
      {isLoadingPage ? (
        <Loader items={1} />
      ) : (
        <Form>
          <Container>
            <Col xs={12} sm={12}>
              <div>
                <h4>Recherche de client</h4>
                <p>
                  Par : nom, prénom, email, téléphone, identifiant borne
                  boutique ou id du client
                </p>
              </div>
              <Autosuggest
                className="form-control"
                suggestions={suggestions.slice(0, 10)}
                onSuggestionSelected={(event, { suggestion }) => {
                  searchCustomer({ ...suggestion }, event);
                }}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                highlightFirstSuggestion={true}
                inputProps={{
                  value: customerValue,
                  placeholder: "Je recherche le client ...",
                  onChange: (e, { newValue }) => setCustomerValue(newValue),
                }}
              />
            </Col>
          </Container>
        </Form>
      )}
    </>
  );
};

const Container = styled(Row)`
  p {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  h4 {
    color: ${(props) => props.theme.colors.green};
  }
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
  color: #444;
  line-height: 20px;
  background: #fff;
  border-radius: 4px;
  padding: 1rem 0rem;
  margin-bottom: 1rem;
`;


Filter.propTypes = {};

export default Filter;
