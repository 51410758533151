import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import Card from "views/universal/Components/Card";
import {
  faCalendarAlt,
  faStore,
  faCarrot,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from "moment";
import CustomerFilter from "./CustomerFilter";

const Filters = (props) => {

  const { typeFilter, handleChange, vehicles, spots, establishments, products, customers, handleDateChange, rangeDates } = props;

  // Les valeurs renseignées des filtres
  const [selectedOptions, setSelectedOptions] = useState({
    'vehicles'     : null,
    'spots'        : null,
    'establishment': null,
    'deliveryType' : {'label': 'Boutique', 'value': 'shop'},
    'products'     : null,
    'customers'    : null
  });

  // Pour DateRangePicker
  const [focusedInput, setFocusedInput] = useState(null);
  let maximumNights = 30;
  let maximumEndDate = moment().add(maximumNights, 'days');
  let condition;

  if(rangeDates.start){
      maximumEndDate = rangeDates.start.clone().add(maximumNights, 'days')
  }

  if(focusedInput === END_DATE) {
      condition = (day) => isInclusivelyAfterDay(day, maximumEndDate);
  }

  if(focusedInput === START_DATE) {
      condition = (day) => false;
  }

  const onDatesChange = ({ startDate, endDate }) => {
      handleDateChange('rangeDates', {start: startDate, end: endDate})
  }

  const onFocusChange = (focusedInput) => {
      setFocusedInput(focusedInput)
      if(focusedInput === START_DATE) {
          let ranges = rangeDates;
          ranges = {...ranges, end:null}
          handleDateChange('rangeDates', ranges)
      }
  }

  // Les options des filtres select
  const options = {
    'vehicles': vehicles.map(v => {
      return {'label': v.name, 'value': v.id}
    }),
    'spots': spots.map(v => {
      return {'label': v.number_spot, 'value': v.id}
    }),
    'establishments': establishments.map(v => {
      return {'label': v.name, 'value': v.id}
    }),
    'deliveryTypes': [
      {'label': 'Boutique', 'value': 'shop'},
      {'label': 'Charrette', 'value': 'spot'}
    ],
    'products': products.map(v => {
      return {'label': v.name, 'value': v.id + '#' + v.type}
    }),
    'customers': customers.map(v => {
      return {'label': `${v.lastname} ${v.firstname} (${v.email}, ${v.phone})`, 'value': v.id}
    })
  };

  const filterVehicles = () => {
      return options.vehicles.filter((v) => {
      // Les valeurs des vehicles dépendent de l'établissement
      let selectedEstablishmentId = selectedOptions.establishment && selectedOptions.establishment.value; // obj
      let vehicleData             = vehicles.find(s => v.value === s.id); // Les données du store du vehicle séléctionné

      // Pas d'etablissement selectionné
      if (!selectedEstablishmentId) {
        return true;
      }
      if (!vehicleData) {
        return false;
      }

      // Etablissement séléctionné
      if (selectedEstablishmentId) {
        if (vehicleData && vehicleData.establishment !== undefined && vehicleData.establishment.id !== undefined) {
          return vehicleData.establishment.id === selectedEstablishmentId
        }
      }

      return true;
    });
  }

  const filterSpots = () => {
     return options.spots.filter((v) => {

      // Les valeurs des spots dépendent de l'établissement et des charrettes
      let selectedEstablishmentId = selectedOptions.establishment && selectedOptions.establishment.value; // obj
      let selectedVehicles        = selectedOptions.vehicles; // tableau
      let spotData                = spots.find(s => v.value === s.id); // Les données du store du spot séléctionné

      // Pas de charrette // etablissement selectionné
      if (!selectedVehicles && !selectedEstablishmentId) {
        return true;
      }
      if (!spotData) {
        return false;
      }

      // Charrettes séléctionnées
      if (selectedVehicles) {
        let selectedVehicleIds = selectedVehicles.map((sv) => {
          return sv.value;
        })
        if (!selectedVehicleIds.includes(spotData.vehicle.id)) {
          return false;
        };
      }

      // Etablissement séléctionné
      if (selectedEstablishmentId) {
        let vehicleData = spotData.vehicle;
        if (vehicleData && vehicleData.establishment !== undefined && vehicleData.establishment.id !== undefined) {
          return vehicleData.establishment.id === selectedEstablishmentId
        }
      }

      return true;
    });
  }

  const handle = (attr, selectedValue) => {
    if (attr === 'establishment') {
      setSelectedOptions({
        ...selectedOptions,
        [attr]:selectedValue,
        spots:null,
        vehicles:null
      })
    }
    else if (attr === 'vehicles') {
      setSelectedOptions({
        ...selectedOptions,
        [attr]:selectedValue,
        spots:null
      })
    }
    else {
      setSelectedOptions({
        ...selectedOptions,
        [attr]:selectedValue
      })
    }
  }

  // Gère la maj des options choisies
  useEffect(() => {
    handleChange(selectedOptions);
  }, [selectedOptions, handleChange]);

  return (
    <Row className="mb-3">
      {typeFilter === 'order' &&
        <Col xs={12} sm={6} md={6} className={"mt-3"}>
          <Card title="Commandes en date du" description="" icon={faCalendarAlt} colorIcon={'#abd156'}>
            <div>
              <DateRangePicker
                minimumNights={0}
                startDate={rangeDates.start}
                startDateId="start_date"
                endDate={rangeDates.end}
                endDateId="end_date"
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                displayFormat={"DD MM YYYY"}
                startDatePlaceholderText="Date début"
                endDatePlaceholderText="Date fin"
                isOutsideRange={condition}
              />
            </div>
          </Card>
        </Col>
      }
      <Col xs={12} sm={6} md={6} className={"mt-3"}>
        <Card title="Client" description="" icon={faUser} colorIcon={'#abd156'}>
          <CustomerFilter
            customers={customers}
            handle={handle}
          />
        </Card>
      </Col>
      {typeFilter === 'order' &&
        <>
          <Col xs={12} sm={6} md={6} className={"mt-3"}>
            <Card title="Commandes passées à" description="" icon={faStore} colorIcon={'#abd156'}>
              <div className="mb-2">
                <Select
                  onChange={(v) => handle('deliveryType', v)}
                  value={selectedOptions.deliveryType}
                  options={options.deliveryTypes}
                />
              </div>
              <div className="mb-2">
                <Select
                  isClearable
                  onChange={(v) => handle('establishment', v)}
                  value={selectedOptions.establishment}
                  options={options.establishments}
                  placeholder="Tous les établissements"
                />
              </div>
              { selectedOptions.deliveryType.value === "spot" &&
                <>
                  <div className="mb-2">
                    <Select
                      isMulti
                      onChange={(v) => handle('vehicles', v)}
                      value={selectedOptions.vehicles}
                      options={filterVehicles()}
                      placeholder="Toutes les charrettes"
                    />
                  </div>
                  <Select
                    isMulti
                    onChange={(v) => handle('spots', v)}
                    value={selectedOptions.spots}
                    options={filterSpots()}
                    placeholder="Tous les points"
                  />
                </>
              }
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} className={"mt-3"}>
            <Card title="Commande contenant" description="" icon={faCarrot} colorIcon={'#abd156'}>
              <Select
                isMulti
                onChange={(v) => handle('products', v)}
                value={selectedOptions.products}
                options={options.products}
                placeholder="N'importe quel produit"
              />
            </Card>
          </Col>
        </>
      }
    </Row>
  );
};

Filters.propTypes = {
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  vehicles: PropTypes.array,
  spots: PropTypes.array,
  products: PropTypes.array,
  customers: PropTypes.array,
  typeFilter: PropTypes.string,
  rangeDates: PropTypes.object
};

export default Filters;
