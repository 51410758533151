import React from "react";
import { Text, View } from "@react-pdf/renderer";

export const ActivityTypeColumns = props => {
  const { categoryName, days, styles } = props;

  return (
    <View style={styles.borderType}>
      <View style={styles.product}>
        <Text style={styles.text}>{categoryName}</Text>
      </View>
      {days.map((day, key) => (
        <>
          <View style={styles.type}>
            <Text style={styles.textType}>Btq</Text>
          </View>
          <View style={styles.type}>
            <Text style={styles.textType}>Dlvro</Text>
          </View>
          <View style={styles.type}>
            <Text style={styles.textType}>Char</Text>
          </View>
          <View style={styles.type}>
            <Text style={styles.textType}>Tot</Text>
          </View>
        </>
      ))}
    </View>
  );
};
