import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./ducks/rootReducer";
import { apiService, createLogger } from "./middlewares";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["session", "store", "modals", "notifications", "ticketRestaurant"],
  stateReconciler: autoMergeLevel2,
  timeout: null,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  storeEnhancers(
    applyMiddleware(thunkMiddleware, apiService, createLogger(true))
  )
);

export const persistor = persistStore(store);
