import { injectGlobal } from "emotion";

const Global = (props) => {};

injectGlobal`
  body{
    background-color: #ecf0f5 !important;
  }
  a{
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #429853;
    :hover{
      text-decoration: none;
      color: #111;
    }
  }
  .vAlign{
    display: flex;
    webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
  }
  // NOTE: the order of these styles DO matter

  // Will edit everything selected including everything between a range of dates
  .CalendarDay__selected_span {
    background: #82e0aa; //background
    color: white; //text
    border: 1px solid $light-red; //default styles include a border
  }

  // Will edit selected date or the endpoints of a range of dates
  .CalendarDay__selected {
    background: $dark-red;
    color: white;
  }

  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    background: orange;
    color: white;
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: brown;
  }
  .DateInput{
    width: 45% !important;
  }
  .DateInput_input{
    font-size:1.0rem !important;
    height: 37px;
    border-bottom: none !important;
  }
  .DateRangePickerInput__withBorder{
    width: 100%;
    border-color: hsl(0,0%,80%) !important;
  }
  .DateRangePicker{
    width: 100%;
  }
  .DateRangePickerInput_arrow{
    width: 10% !important;
  }
  .nav-tabs {
    border-bottom: none;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  }
  .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-link {
    display: block;
    padding: 0.8rem 1rem;
    border-right: solid 1px #ddd !important;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: #429853;
    border-color: #dee2e6 #dee2e6 #fff;
    border: none;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.1) inset;
  }
  .nav-tabs .nav-link:hover {
    border: none;
    background-color: #429853;
    color: white;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .transition-appear {
    opacity: 0;
  }
  .transition-appear-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .transition-enter {
    opacity: 0;
  }
  .transition-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .transition-exit {
    opacity: 1;
  }
  .transition-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  .popover-header{
    background-color: #e6005c;
    color: white;
  }
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input::-ms-clear {
    display: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .modal-content {
    padding: 1.0rem;
  }
  .dropdown-toggle{
    z-index: 0;
  }
  .dropdown-menu{
    z-index: 9999;
  }
`;

export default Global;
