import React, { useState } from "react";
import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faChevronRight,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { operations } from "state/ducks/store/customer";
import { useDispatch } from "react-redux";
import { notificationsOperations } from "state/ducks/notifications";
import swal from 'sweetalert';
import Tippy from '@tippyjs/react';
import CustomerNotificationButton from "../Elements/CustomerNotificationButton";

const SuspectPoolCustomer = (props) => {

  const {
    pool,
    getCustomerPool
  } = props

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const today = moment();

  const updateSuspectPoolCustomerTrFreeze = (customerId, e) => {
    e.preventDefault();
    swal({
      title: "Bloquer un porte-monnaie",
      text: "Etes-vous sûr de vouloir bloquer le porte-monnaie de ce client ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        setIsLoading(true);
        dispatch(operations.updateSuspectPoolCustomerTrFreeze(customerId)).then(
          (result) => {
            setIsLoading(false);
            dispatch(notificationsOperations.getResponseSuccess('Le paiement TR pour ce client a bien été mis à jour'));
          },
          (error) => {
            setIsLoading(false);
          }
        )
      }
    });
  };

  return (
    <>
      {pool.customer &&
        <ListStock>
          <Date xs={12} sm={3} md={2} className="vAlign">
            {pool.metaData && pool.metaData.firstDelayTrDate &&
              <p>Retard de:<b> {today.diff(pool.metaData.firstDelayTrDate, 'days')} jours</b></p>
            }
            <CustomerNotificationButton customerId={pool.customer.id}/>
          </Date>
          <Pool xs={12} sm={3} md={2} className="vAlign">
            <p>Porte-monnaie:<b> {pool.amount / 100} €</b></p>
            <button
              disabled={isLoading}
              onClick={(e) => getCustomerPool(pool.customer.id, e)}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              )
              : (
                <>Voir l'historique  &nbsp;&nbsp;<FontAwesomeIcon icon={faChevronRight} /></>
              )}
            </button>
          </Pool>
          <Type xs={12} sm={4} md={4} className="vAlign">
            <p>
              <b><span>{pool.customer.lastname}</span> {pool.customer.firstname}</b><br/>
              <small><i>Tel: {pool.customer.phone} / Email : {pool.customer.email}</i></small><br/>
              <small><i>Derniere commande sur : {pool.metaData && pool.metaData.lastOrderAddress}</i></small>
            </p>
          </Type>
          <Cell xs={12} sm={4} md={2} className="vAlign">
            <p>
              <small>Client depuis : </small>{today.diff(pool.customer.created_at, 'days')} jour(s)<br/>
              <small>Commandes passées : </small>{pool.metaData && pool.metaData.totalOrders && pool.metaData.totalOrders}<br/>
              <small>Relancé le : </small>{pool.metaData && pool.metaData.notifiedAt ? moment(pool.metaData.notifiedAt).format("DD-MM-Y") : "Jamais"}<br/>
            </p>
          </Cell>
          <Save xs={12} sm={3} md={2} type={null} className="vAlign">

              <Tippy content={<span>Vous pouvez bloquer ou débloquer le paiement ensuite si besoin</span>}>
                <FreezeButton
                  isFreeze={pool.customer.tr_freeze}
                  disabled={isLoading}
                  onClick={(e) => updateSuspectPoolCustomerTrFreeze(pool.customer.id, e)}
                >
                  {isLoading ? (
                    <><FontAwesomeIcon icon={faSpinner} size="lg" spin /> &nbsp;&nbsp; Paiement TR bloqué</>
                  )
                  : (
                    <>
                      {pool.customer.tr_freeze
                        ?
                          <><FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp; Paiement TR bloqué (on)</>
                        :
                          <><FontAwesomeIcon icon={faCircle} /> &nbsp;&nbsp; Paiement TR bloqué (off)</>
                      }

                    </>
                  )}
                </FreezeButton>
              </Tippy>

          </Save>
        </ListStock>
      }
    </>
  );
};



const FreezeButton = styled.button`
  background-color: ${(props) => props.isFreeze ? props.theme.colors.red : '#aaa' };
`;

const ListStock = styled(Row)`
  margin: 1rem 0;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const Date = styled(Col)`
  border-left: #dedede 1px solid;
  padding: 0.5rem 0.8rem;
  text-align:center;
  align-items: center;
  p {
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.fonts.size.s};
    margin-bottom: 0.5rem;
  }
  button {
    background-color: ${(props) => props.theme.colors.black };
    transition: 300ms;
    width: auto;
    padding: 0.1rem 1rem;
    color: white;
    border: none;
    border-radius: 3px;
    min-height: 2rem;
    &:hover {
      opacity: 0.7;
    }
    :disabled,
    button[disabled] {
      opacity: 0.5;
    }
  }
`;

const Cell = styled(Col)`
  border-left: #dedede 1px solid;
  padding: 0.5rem 0.8rem;
  p {
    small {
      color: ${(props) => props.theme.colors.green};
      font-size: ${(props) => props.theme.fonts.size.s};
    }
    font-size: ${(props) => props.theme.fonts.size.s};
    margin-bottom: 0px;
  }

`;

const Type = styled(Col)`
  border-left: #dedede 1px solid;
  padding: 0.5rem 0.8rem;
  p {
    span{
      text-transform: uppercase;
    }
    margin-bottom: 0rem;
    text-align: left;
    word-break: break-all;
  }
`;

const Save = styled(Col)`
  padding: 0rem;
  align-items: center;
  border-left: #dedede 1px solid;
  button {
    transition: 300ms;
    width: auto;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    color: white;
    min-height: 3rem;
    &:hover {
      opacity: 0.7;
    }
    :disabled,
    button[disabled] {
      opacity: 0.5;
    }
  }
`;

const Pool = styled(Col)`
  padding: 0rem;
  text-align: center;
  color: ${(props) => props.theme.colors.red };
  align-items: center;
  border-left: #dedede 1px solid;
  p{
    margin-bottom: 0.5rem;
  }
  button {
    background-color: ${(props) => props.theme.colors.black };
    transition: 300ms;
    width: auto;
    padding: 0.1rem 1rem;
    color: white;
    border: none;
    border-radius: 3px;
    min-height: 2rem;
    &:hover {
      opacity: 0.7;
    }
    :disabled,
    button[disabled] {
      opacity: 0.5;
    }
  }
`;

SuspectPoolCustomer.propTypes = {

};

export default SuspectPoolCustomer;
