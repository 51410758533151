import * as actionTypes from "./types";

export const initPage = (establishmentId) => ({
  type: actionTypes.INIT_ADMIN_TICKET_RESTAURANT,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/ticketrestaurants.json`,
    method: "GET",
    params: {
      establishment: establishmentId,
    }
  },
  payload: {
  },
});

export const updateAdminTicketRestaurant = (id, data) => {
  let d = {};
  if (data.modifiedAmount) {
    d.modifiedAmount = data.modifiedAmount;
  }
  if (data.suspect) {
    d.suspect = data.suspect;
  }
  if (data.reason) {
    d.reason = data.reason;
  }
  if (data.note) {
    d.note = data.note;
  }
  return {
    type: actionTypes.UPDATE_ADMIN_TICKET_RESTAURANT,
    meta: {
      async: true,
      blocking: true,
      path: `/v2/store/order/ticketrestaurants/${id}.json`,
      method: "PUT",
      body: {
        ticketrestaurant: d,
      }
    },
    payload: {
    },
  }
}


export const postPoolOperations = (id, amount, note, suspect) => ({
  type: actionTypes.POST_POOL_OPERATIONS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/customers/${id}/pooloperations.json`,
    method: "POST",
    body: {
      operation: {amount: amount, note: note, suspect: suspect},
    }
  },
  payload: {
  },
});

export const searchAdminTicketRestaurant = (vehicle, spot, lastname) => ({
  type: actionTypes.SEARCH_ADMIN_TICKET_RESTAURANT,
  payload: {
    vehicle, spot, lastname
  },
});

export const getCustomerPool = (id) => ({
  type: actionTypes.GET_CUSTOMER_POOL,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/customers/${id}/pool.json`,
    method: "GET",
  },
  payload: {
    id
  },
});

/**
 * Bloque ou débloque le moyen de payement Ticket Restaurant
 * @param  {int} customerId
 */
export const updateSuspectCustomer = (customerId) => ({
  type: actionTypes.UPDATE_SUSPECT_CUSTOMER,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/suspectpoolcustomers/${customerId}/trsuspect.json`,
    method: "PUT",
  },
  payload: {
    customerId
  }
});


/**
 * Retourne les avantages du client
 */
export const getCustomerAdvantage = (id) => ({
  type: actionTypes.GET_CUSTOMER_ADVANTAGE,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/customers/${id}/advantage.json`,
    method: "GET",
  },
  payload: {
    id
  },
});
