import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import FiltersSuspect from "./Filters"
import { operations } from "state/ducks/store/customer";
import SuspectPoolCustomer from "./SuspectPoolCustomer"
import { ticketRestaurantSelector } from "state/ducks/store/ticketRestaurant";
import Loader from "views/universal/Loader/Loader";

const SuspectPoolCustomerPage = (props) => {

  const {
    getCustomerPool
  } = props
  const establishment = useSelector(
    (state) => state.session.establishment,
    shallowEqual
  );

  const pageData = useSelector(
    (state) => state.customer.pool,
    shallowEqual
  );

  const customerPool = useSelector((state) => {
    return ticketRestaurantSelector.getCustomerPoolSelector(state);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if(establishment){
      dispatch(operations.initPage(establishment));
    }
  }, [dispatch, establishment]);

  return (
    <div>
      <FiltersSuspect />
      {pageData.isLoading
        ?
          <Loader items={2} />
        :
          <>
            {
              pageData.customerPoolSuspects.length > 0
                ?
                  <>
                    {pageData.customerPoolSuspects.filter(
                      (pool) => {
                        if (pageData.search && pageData.search.lastname) {
                          if (pool.customer) {
                          return pool.customer.lastname
                              .toLowerCase()
                              .includes(pageData.search.lastname.toLowerCase())
                          }
                          return false;
                        }
                        return pool
                      }

                    ).map( pool =>
                      <SuspectPoolCustomer pool={pool} key={pool.id} customerPool={customerPool} getCustomerPool={getCustomerPool}/>
                    )}
                  </>
                :
                <Help>
                  <p>
                    Il n'y a pas de client suspect.
                  </p>
                </Help>
            }
          </>
      }
    </div>
  );

}

const Help = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.l};
  }
`;

SuspectPoolCustomerPage.propTypes = {};

SuspectPoolCustomerPage.defaultProps = {};

export default SuspectPoolCustomerPage;
