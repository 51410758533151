import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import FormStock from "./FormStock";

const Item = (props) => {
  const { stock, indexProduct } = props;

  return (
    <>
      <Container>
        <Title xs={12} sm={2} className="vAlign">
          <p>{stock.name}</p>
        </Title>
        <Col>
          <ListDate>
            <Date className="vAlign" xs={12} sm={1}>
              {moment(stock.date).format("DD/MM/YYYY")}
            </Date>
            <Col xs={12} sm={11}>
              {stock.types &&
                Object.keys(stock.types).map((type, key) => (
                  ['spot', 'shop'].includes(type) && <FormStock
                    isAvailable={stock.isAvailable}
                    stock={stock.types[type]}
                    date={stock.date}
                    type={type}
                    productId={stock.productId}
                    key={key}
                    indexProduct={indexProduct}
                  />
                ))}
            </Col>
          </ListDate>
        </Col>
      </Container>
    </>
  );
};

const Container = styled(Row)`
  margin: 1rem 0;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const Title = styled(Col)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.green};
  border-right: #dedede 1px solid;
  p {
    margin-bottom: 0rem;
  }
  @media (max-width: 575px) {
    text-align: center;
  }
`;

const ListDate = styled(Row)`
  border-bottom: #dedede 1px solid;
`;

const Date = styled(Col)`
  font-weight: bold;
  font-size: ${(props) => props.theme.fonts.size.xs};
`;

Item.propTypes = {
  stock: PropTypes.object,
  indexProduct: PropTypes.string,
};

export default Item;
