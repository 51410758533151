import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Loader from "views/universal/Loader/Loader";
import PropTypes from "prop-types";
import { Modal, Button, Form, Container } from "react-bootstrap";
import { checkEmail, checkMobile } from "utils/utils";
import swal from 'sweetalert';

const PopupCustomer = (props) => {
  const {
    show,
    onHide,
    onEscapeKeyDown,
    isLoading,
    customerHandle,
    customer,
    isEdit,
    customers,
  } = props;

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [newsletter, setNewsletter] = useState(true);
  const [isForKiosk, setIsForKiosk] = useState(!isEdit);
  const [noPhone, setNoPhone] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setFirstname(customer.firstname);
      setLastname(customer.lastname);
      setEmail(customer.email);
      setPhone(customer.phone);
      setNewsletter(customer.newsletter);
      setNoPhone(false);
      setIsForKiosk(false);
    } else {
      setFirstname("");
      setLastname("");
      setEmail("");
      setPhone("");
      setNewsletter(true);
      setIsForKiosk(true);
    }
  }, [isEdit, customer]);

  const getCustomersWithThisPhone = (thePhone) => {
    return customers.filter((customer) => {
      return (
        customer.phone &&
        customer.phone
          .toString()
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(thePhone.toLowerCase().replace(/\s/g, ""))
      );
    });
  };

  const getCustomersWithThisEmail = (theEmail) => {
    return customers.filter((customer) => {
      return customer.email
        .toString()
        .toLowerCase()
        .replace(/\s/g, "")
        .includes(theEmail.toLowerCase().replace(/\s/g, ""));
    });
  };

  const alertIfPhoneAlreadyUsed = (thePhone) => {
    if (checkMobile(thePhone)) {
      if (getCustomersWithThisPhone(thePhone).length > 0) {
        swal({
          title: "Pas si vite !",
          text: "Ce numéro de téléphone est déjà utilisé par un autre client.",
          icon: "warning",
          buttons: {
            ok: "Ok",
          },
        });
        return;
      }
      return;
    } else {
      swal({
        title: "Pas si vite !",
        text: "Le téléphone doit commencer par 06 ou 07.",
        icon: "warning",
        buttons: {
          ok: "Ok",
        },
      });
      return;
    }
  };
  const alertIfEmailAlreadyUsed = (theEmail) => {

    if (checkEmail(theEmail)) {
      if (getCustomersWithThisEmail(theEmail).length > 0) {
        swal({
          title: "Pas si vite !",
          text: "Cet email est déjà utilisé par un autre client.",
          icon: "warning",
          buttons: {
            ok: "Ok",
          },
        });
        return;
      }
      return;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let hasEmailUpdate = false;
    let hasPhoneUpdate = false;

    let data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      newsletter: newsletter,
    };
    if (isEdit) {
      data = { ...customer };
      data.firstname = firstname;
      data.lastname = lastname;
      data.email = email;
      data.newsletter = newsletter;

      if (customer.email !== email) {
        hasEmailUpdate = true;
      }
      if (customer.phone !== phone) {
        hasPhoneUpdate = true;
      }
    }

    if (email !== "" && !checkEmail(email)) {
      swal({
        title: "Pas si vite !",
        text: "Cet email n'est pas valide, vous n'avez pas fait de faute de frappe ?",
        icon: "warning",
        buttons: {
          ok: "Ok",
        },
      });
      return;
    }
    if (
      (!isEdit && getCustomersWithThisEmail(email).length > 0) ||
      (isEdit && hasEmailUpdate && getCustomersWithThisEmail(email).length > 0)
    ) {
      swal({
        title: "Pas si vite !",
        text: "Cet email est déjà utilisé par un autre client. Vous ne pouvez pas créer un noueau client avec cet email.",
        icon: "warning",
        buttons: {
          ok: "Ok",
        },
      });
      return;
    }
    if (noPhone === false) {
      if (phone !== "" && !checkMobile(phone)) {
        swal({
          title: "Pas si vite !",
          text: "Le téléphone doit commencer par 06 ou 07",
          icon: "warning",
          buttons: {
            ok: "Ok",
          },
        });
        return;
      }

      if (
        (!isEdit && getCustomersWithThisPhone(phone).length > 0) ||
        (isEdit &&
          hasPhoneUpdate &&
          getCustomersWithThisPhone(phone).length > 0)
      ) {
        if (
          !window.confirm(
            "Ce téléphone est déjà utilisé par un autre client. Voulez-vous forcer la création/mise à jour de l'utilisateur ?"
          )
        ) {
          return;
        }
      }
      data.phone = phone;
    }

    customerHandle(isEdit ? "edit" : "add", data);
  };

  return (
    <Popup
      size="lg"
      show={show}
      onHide={onHide}
      onEscapeKeyDown={onEscapeKeyDown}
    >
      {isLoading ? (
        <Loader items={2} />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstname">
              <Form.Group controlId="formEmail">
                <Form.Label>Email :</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(event) =>
                    setEmail(event.target.value.replace(/\s/g, ""))
                  }
                  onBlur={(event) =>
                    alertIfEmailAlreadyUsed(event.target.value)
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Téléphone portable :</Form.Label>
                <Form.Control
                  type="tel"
                  value={phone}
                  onChange={(event) =>
                    setPhone(event.target.value.replace(/\s/g, ""))
                  }
                  onBlur={(event) => {
                    if (event.target.value !== "") {
                      alertIfPhoneAlreadyUsed(event.target.value);
                    }
                  }}
                  required={!noPhone}
                />
              </Form.Group>
              <Form.Label>Prénom :</Form.Label>
              <Form.Control
                type="text"
                value={firstname}
                onChange={(event) => setFirstname(event.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLastname">
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                type="text"
                value={lastname}
                onChange={(event) => setLastname(event.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formNewsletter">
              <Form.Check
                type="checkbox"
                label="J'accepte de recevoir les communications marketing du Moulin"
                checked={newsletter}
                onChange={(event) => setNewsletter(event.target.checked)}
              />
            </Form.Group>

            {!isEdit && (
              <>
                <hr />
                <Form.Group controlId="formKiosk">
                  <Form.Check
                    type="checkbox"
                    label="Création pour une commande kiosque/boutique (Envoie de son identifiant boutique par SMS)"
                    checked={isForKiosk}
                    onChange={(event) => setIsForKiosk(event.target.checked)}
                  />
                </Form.Group>
                <Form.Group controlId="formNoPhone">
                  <Form.Check
                    type="checkbox"
                    label="Le client n'a pas de téléphone (permet au client de quand même commander au borne en lui donnant son code oralement)"
                    checked={noPhone}
                    onChange={(event) => setNoPhone(event.target.checked)}
                  />
                </Form.Group>
                <hr />
              </>
            )}
            <Button variant="primary" type="submit">
              {isEdit ? "Modifier ce client" : "Créer ce client"}
            </Button>
          </Form>
        </Container>
      )}
    </Popup>
  );
};

const Popup = styled(Modal)`
  .modal-content {
    background-color: #ecf0f5 !important;
  }
  p {
    margin-bottom: 0rem;
  }
`;

PopupCustomer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onEscapeKeyDown: PropTypes.func,
  isLoading: PropTypes.bool,
  addCustomerHandle: PropTypes.func,
  customer: PropTypes.object,
  isEdit: PropTypes.bool,
  customers: PropTypes.array,
};

export default PopupCustomer;
