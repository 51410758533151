/**
 * Transforme les paramètres d'url en object
 * @source https://www.sitepoint.com/get-url-parameters-with-javascript/
 * @param  {string} url
 */
export const getAllUrlParams = (url) => {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

/**
 * Retire un paramètre d'une url
 * @source https://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript/1634841#1634841
 * @param  string url       [description]
 * @param  string parameter [description]
 * @return string           [description]
 */
export const removeURLParameter = (url, parameter) => {
    //prefer to use l.search if you have a location/link object
    var urlparts= url.split('?');
    if (urlparts.length>=2) {

        var prefix= encodeURIComponent(parameter)+'=';
        var pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url= urlparts[0]+'?'+pars.join('&');
        return url;
    } else {
        return url;
    }
}

/**
 * 03/23 - Vérifie si l'email est valide
 * @param {*} email 
 * @returns 
 */
export const checkEmail = (email) => {
  // eslint-disable-next-line
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var extension = email.split('.').pop();
  var badExtensions = ['coma', 'cim', 'col', 'rf', 'fe', 'ff', 'ey', 'ft'];
  if (badExtensions.indexOf(extension) > -1) {
    return false;
  }
  
  return re.test(email);
}

/**
 * 03/23 - Vérifie si le télépohne portable (06 ou 07) est valide
 * @param {*} mobile 
 * @returns 
 */
export const checkMobile = (mobile) => {
  // var re = /^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/;
  var re = /^(0)[67](\s?\d{2}){4}$/;
  return re.test(mobile);
}

/**
 * Trasnforme un montant en centimes en euros
 * @param {montant en centimes} amount 
 * @returns string
 */
export function centimesToCurrency(amount) {
  return amount / 100 + ' €';
}
