import React from "react";
import styled from "@emotion/styled";
import { Modal, Col, Row, Badge } from "react-bootstrap";
import moment from "moment";
import Loader from "views/universal/Loader/Loader";
import {
  faTimes,
  faCheckCircle,
  faClock,
  faMeh,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from '@tippyjs/react';

const PopupPool = (props) => {

  const {
    show,
    onHide,
    onEscapeKeyDown,
    customerPool,
    isLoading
  } = props;

  require("moment/locale/fr.js");

  return (
      <Popup
        size="lg"
        show={show}
        onHide={onHide}
        onEscapeKeyDown={onEscapeKeyDown}
      >
        {isLoading
          ?
            <Loader items={2} />
          :
            <>
            {customerPool ?
              <>
                <Row>
                  <Col>
                    <Title className="text-center">
                      <small>
                      {customerPool.customer && customerPool.customer.tr_suspect &&
                        <Tippy content={<span>Client signalé comme suspect</span>}>
                          <Waiting>
                            <FontAwesomeIcon icon={faMeh} size="lg"/>
                          </Waiting>
                        </Tippy>
                      }Porte-monnaie de {customerPool.firstname && customerPool.firstname} {customerPool && customerPool.lastname && customerPool.lastname}
                      </small>
                      <br/><span>{customerPool.total / 100} €</span>
                    </Title>
                  </Col>
                </Row>
                <Header noGutters>
                  <Col sm={2}>
                    <p><b>Type</b></p>
                  </Col>
                  <Col sm={2}>
                    <Tippy placement="left" content={<span>Action possible:<br/> TR = Dépot de TR / Manuel / Régularisation / Approvisionnement / Commande </span>}><p><b>Action</b> <FontAwesomeIcon icon={faInfoCircle} size="lg"/></p></Tippy>
                  </Col>
                  <Col sm={2}>
                    <Tippy placement="left" content={<span>Montant déclaré par le client</span>}><p><b>Déclaré</b> <FontAwesomeIcon icon={faInfoCircle} size="lg"/></p></Tippy>
                  </Col>
                  <Col sm={1}>
                    <Tippy placement="left" content={<span>Montant que le Moulin a controlé pour validation</span>}><p><b>Edité</b> <FontAwesomeIcon icon={faInfoCircle} size="lg"/></p></Tippy>
                  </Col>
                  <Col sm={2}>
                    <p><b>Déclaré le</b></p>
                  </Col>
                  <Col sm={2}>
                    <p><b>Mis à jour</b></p>
                  </Col>
                  <Col sm={1}>
                    <Tippy placement="left" content={<span>Statut possible : <br/> Validé/En attente/Annulé</span>}><p><b>Statut <FontAwesomeIcon icon={faInfoCircle} size="lg"/></b></p></Tippy>
                  </Col>
                </Header>

                {customerPool.operations && customerPool.operations.sort((a, b) => moment(b.updated_at).format('YYYYMMDD') - moment(a.updated_at).format('YYYYMMDD')).map( operation =>

                      <Operation key={operation.id} type={operation.type} noGutters>
                        <Col sm={2}><p><b>{operation.type && operation.type}</b></p></Col>
                        <Col sm={2}>
                          <p>
                          {operation.action === 'ticketRestaurant' ? 'TR' : operation.action === 'manual' ? 'Manuelle' : operation.action === 'order' ? 'Commande' : operation.action === 'creditCard' ? 'Par carte' : operation.action}
                          </p>
                        </Col>
                        <Col sm={2}><p>{operation.init_amount && (operation.init_amount/100)+'€'}</p></Col>
                        <Col sm={1}><p>{operation.amount && (operation.amount/100)+'€'}</p></Col>
                        <Col sm={2}><p><i><small>{operation.created_at && moment(operation.created_at).format("DD-MM-YY H:mm")}</small></i></p></Col>
                        <Col sm={2}><p><i><small>{operation.updated_at && moment(operation.updated_at).format("DD-MM-YY H:mm")}</small></i></p></Col>
                        <Col sm={1}>
                          <p>
                            {
                              operation.status === 'validated' ?
                                <Tippy content={<span>Validé</span>}>
                                  <Validate>
                                    <FontAwesomeIcon icon={faCheckCircle} size="lg"/>
                                  </Validate>
                                </Tippy>
                              : operation.status === 'inprogress' ?
                                <Tippy content={<span>En attente du client</span>}>
                                  <Waiting>
                                    <FontAwesomeIcon icon={faClock} size="lg"/>
                                  </Waiting>
                                </Tippy>
                              : operation.status === 'cancelled' ?
                                <Tippy content={<span>Annulé</span>}>
                                  <Error>
                                    <FontAwesomeIcon icon={faTimes} size="lg"/>
                                  </Error>
                                </Tippy>
                              :
                                null
                            }

                            {operation.suspect &&
                              <Tippy content={<span>Opération signalé comme suspecte</span>}>
                                <Waiting>
                                  <FontAwesomeIcon icon={faMeh} size="lg"/>
                                </Waiting>
                              </Tippy>
                            }
                          </p>
                        </Col>
                        {(operation.note || operation.tr_note) &&
                            <Note sm={12}>
                              <p>
                                Note : {operation.note} {operation.tr_note}
                              </p>
                            </Note>
                        }

                        {operation.reason !== null &&
                          <Note sm={12}>
                            <p>
                              {operation.reason === "noNews" ?
                                <Badge variant="secondary">Annulation silence Radio</Badge>
                              : operation.reason === "customerCall" ?
                                <Badge variant="secondary">Annulation suite appel</Badge>
                              : operation.reason === "regularisation" ?
                                <Badge variant="secondary">Régularisation</Badge>
                              :
                                null
                              }
                            </p>
                          </Note>
                        }

                      </Operation>
                )}
              </>
            : "Pas de porte-monnaie pour ce client."}
            </>
        }
      </Popup>
  );
};


const Popup = styled(Modal)`
  .modal-content{
    background-color: #ecf0f5 !important;
  }
  p {
    margin-bottom: 0rem;
  }
`;

const Operation = styled(Row)`
  padding: 0.6rem;
  background-color: white;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  p{
    color: ${(props) => props.type === "credit" ? props.theme.colors.green : props.type === "debit" ? props.theme.colors.grey : props.theme.colors.black};
  }
`;

const Title = styled.h3`
  span{
    font-weight: bold;
    color: ${(props) => props.theme.colors.green};
  }
`;

const Header = styled(Row)`
  color: grey;
  padding: 0.6rem;
  font-size: 0.8rem;
`;

const Note = styled(Col)`
  border-top: solid 1px #ddd;
  padding-top: 0.2rem !important;
  margin-top: 0.5rem;
  p{
    color: grey;
  }
`;

const Validate = styled.span`
  svg{
    color: ${(props) => props.theme.colors.green};
    margin-right: 0.5rem;
  }
`;
const Waiting = styled.span`
  svg{
    color: ${(props) => props.theme.colors.orange};
    margin-right: 0.5rem;
  }
`;
const Error = styled.span`
  svg{
    color: ${(props) => props.theme.colors.red};
    margin-right: 0.5rem;
  }
`;

PopupPool.propTypes = {

};

export default PopupPool;
