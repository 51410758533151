import { createSelector } from "reselect";

const historicTipsState = (state) => state.store.tips.historic;

const getTotalTipsSelector = createSelector(
  historicTipsState,
  (historicTipsState) => {

    let totalTips = 0;
    let countTips = 0;

    if(historicTipsState.data){
      historicTipsState.data.forEach((tip) => {
        totalTips = totalTips + tip.amount;
      });
      countTips = historicTipsState.data.length;
    }

    return {
      ...historicTipsState,
      totalTips: totalTips,
      countTips: countTips
    };
  }
);

export { getTotalTipsSelector };
