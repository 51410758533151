import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Form } from "react-bootstrap";

const Variables = (props) => {

  const { cause, start, end, handleChange } = props;

  return (
    <Filter>
        <b>A renseigner avant envoi</b>
        <Form.Control
          type="text"
          defaultValue={cause}
          onChange={(e) => handleChange('cause', e.target.value)}
          placeholder="Cause du retard"
        />
        <Form.Control
          type="start"
          defaultValue={start}
          onChange={(e) => handleChange('start', e.target.value)}
          placeholder="Heure de début de la livraison"
        />
        <Form.Control
          type="end"
          defaultValue={end}
          onChange={(e) => handleChange('end', e.target.value)}
          placeholder="Heure de fin de la livraison"
        />
    </Filter>
  );
};

const Filter = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 2.0rem 1.0rem 0rem 1.0rem;
  input{
    margin: 0.5rem 0;
    background-color: rgba(255,255,255,0.7);
  }
`;

Variables.propTypes = {
  cause: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  handleChange: PropTypes.func
};

export default Variables;
