import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Form, Col, Row } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { tipsOperations } from "state/ducks/store/tips";

const Filters = (props) => {
  require("moment/locale/fr.js");

  const { isLoading } = props;

  const [establishment, setEstablishment] = useState({ value: "all", label: "Tous les établissements" });

  const establishmentChoices = [
    { value: "all", label: "Tous les établissements"},
    { value: 1, label: "Lyon 8 - Lumière" },
    { value: 2, label: "Lyon 9 - Valmy" }
  ]

  const [date, setDate] = useState({ startDate: moment(), endDate: moment() });
  const [focusedInput, setFocusedInput] = useState(null);

  const dispatch = useDispatch();

  return (
    <Container>
      <Form>
        <Filter>
          <Col xs={12} sm={4}>
            <Select
              onChange={(selectedOption) => setEstablishment(selectedOption)}
              value={establishment}
              options={establishmentChoices}
              placeholder="Tous les établissements"
            />
          </Col>
          <Col xs={12} sm={6}>
            <DateRangePicker
              minimumNights={0}
              startDate={date.startDate} // momentPropTypes.momentObj or null,
              startDateId="start_date" // PropTypes.string.isRequired,
              endDate={date.endDate} // momentPropTypes.momentObj or null,
              endDateId="end_date" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                setDate({ startDate: startDate, endDate: endDate })
              } // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              displayFormat={() => moment.localeData().longDateFormat("L")}
              startDatePlaceholderText="Date début"
              endDatePlaceholderText="Date fin"
              isOutsideRange={() => false}
            />
          </Col>
          <Col xs={12} sm={2}>
            <Search
              onClick={(event) =>
                {
                  event.preventDefault();
                  let establishmentTransform = null;
                  if (establishment) {
                    establishmentTransform = establishment.value;
                  }
                  dispatch(
                    tipsOperations.getTips(
                      moment(date.startDate).format("YYYY-MM-DD"),
                      moment(date.endDate).format("YYYY-MM-DD"),
                      establishmentTransform,
                    )
                  )
                }
              }
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : (
                "Lancer une recherche"
              )}
            </Search>
          </Col>
        </Filter>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
`;

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Search = styled.button`
  border: solid 1px ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.black};
  padding: 0.5rem;
  color: white;
  display: inline-block;
  transition: 300ms;
  width: 100%;
  &:hover {
    border: solid 1px ${(props) => props.theme.colors.green};
    background-color: ${(props) => props.theme.colors.green};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

Filters.propTypes = {
  establishment: PropTypes.string,
  productCategories: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Filters;
