import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Layout from "views/universal/Layout/Layout";
import CountUp from 'react-countup';
import { CSSTransition } from "react-transition-group";
import { Row, Col } from "react-bootstrap";
import { tipsSelector, tipsOperations } from "state/ducks/store/tips";
import moment from "moment";
import Loader from "views/universal/Loader/Loader";

const Tips = (props) => {

  const totalTips = useSelector((state) => {
    return tipsSelector.getTotalTipsSelector(state);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      tipsOperations.getTips(
        moment().startOf('quarter').format('YYYY-MM-DD'),
        moment().endOf('quarter').format('YYYY-MM-DD'),
        'all',
      )
    )
  }, [dispatch]);

  return (
    <Layout location={props.location.search}>
      {totalTips.isLoading ?
        <Loader items={1} key={0} />
      :
        <Container>
          <CSSTransition
            appear={true}
            in={true}
            timeout={500}
            classNames="transition"
          >
            <Row>
              <Col cs={6} className="vAlign">
                <div className="text-center">
                  <Title>Pourboire ce trimestre</Title>
                </div>
              </Col>
              <Col cs={6} className="vAlign">
                <div className="text-center">
                <Amount><CountUp end={totalTips.totalTips / 100} duration={3}/> €</Amount>
                <CountTips><CountUp end={totalTips.countTips} duration={3}/> pourboires</CountTips>
                </div>
              </Col>

            </Row>
          </CSSTransition>
        </Container>
      }
    </Layout>
  );
};

const Container = styled.div`
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
  padding: 1rem;
  color: white;
  border-radius: 4px;
`;

const Amount = styled.h3`
  font-size: ${(props) => props.theme.fonts.size.xl};
  margin-bottom: 0rem;
`;

const CountTips = styled.h4`
  font-style:italic;
  color: rgba(255, 255, 255, 0.7);
  font-size: ${(props) => props.theme.fonts.size.s};
`;

const Title = styled.h2`
  text-align: center;
  margin: auto;
  display: inline-block;
  padding: 0.5rem;
  color: white;
  font-size: ${(props) => props.theme.fonts.size.s};
  margin-bottom: 0rem;
  border: 2px solid  white;
`;

Tips.propTypes = {};

Tips.defaultProps = {};

export default Tips;
