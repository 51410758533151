import React from "react";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { DayColumns } from "./DayColumns";
import { ActivityTypeColumns } from "./ActivityTypeColumns";
import { ProductRows } from "./ProductRows";

const PdfStock = props => {
  const { stocksProduct, start, end } = props;
  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];
  return (
    <Document>
      {stocksProduct.printData.map((category, catKey) => (
        <Page
          wrap
          size="A4"
          style={styles.page}
          orientation="landscape"
          key={catKey}
        >
          <DayColumns
            start={start}
            end={end}
            days={days}
            styles={styles}
            key={catKey + 10000}
          />
          <ActivityTypeColumns
            categoryName={category.name}
            days={days}
            styles={styles}
            key={catKey + 20000}
          />
          <ProductRows
            category={category}
            styles={styles}
            key={catKey + 30000}
          />
        </Page>
      ))}
    </Document>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {

  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  noBorder: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 0",
    width: "100%",
  },
  noBorderAlternate: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 0",
    width: "100%",
    backgroundColor: "#ccc"
  },
  border: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1 solid black",
    padding: "0 0",
    width: "100%",
  },
  borderAlternate: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1 solid black",
    padding: "0 0",
    width: "100%",
    backgroundColor: "#ccc"
  },
  borderType: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 0",
    backgroundColor: "black",
    color: "white",
    width: "100%"
  },
  title: {
    width: "100%"
  },
  days: {
    width: "16%",
    textAlign: "center",
    borderRight: "1 solid black",
    borderLeft: "1 solid black"
  },
  type: {
    width: "4%",
    textAlign: "center",
    borderRight: "1 solid white"
  },
  product: {
    width: "20%",
    borderRight: "1 solid black",
  },
  stock: {
    width: "4%",
    borderRight: "1 solid black",
    textAlign: "center",
    paddingBottom: 3,
    paddingTop: 3,
    paddingRight: 0,
    paddingLeft: 0,
  },
  titleText: {
    fontSize: 12,
    textAlign: "center",
    paddingBottom: 5,
    fontStyle: "italic"
  },
  text: {
    fontSize: 10
  },
  textTotal: {
    fontSize: 10,
    color: "green",
    textDecoration: "underline"
  },
  textType: {
    fontSize: 8
  },
  staticCell13: {
    width: "5%",
    borderRight: "1 solid black",
    borderTop: "1 solid black",
    textAlign: "left",
    paddingBottom: 5,
    paddingTop: 5,
    paddingRight: 0,
    paddingLeft: 0,
  },
  staticCell23: {
    width: "10%",
    borderRight: "1 solid black",
    borderTop: "1 solid black",
    textAlign: "right",
    paddingBottom: 5,
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 5,
  },
  staticCellText: {
    fontSize: 6
  },
});

PdfStock.propTypes = {
  stocksProduct: PropTypes.object, // Les données du store
  start: PropTypes.string, // date de début formatté
  end: PropTypes.string // date de fin formatté
};

export default PdfStock;
