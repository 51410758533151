import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Loader from "views/universal/Loader/Loader";
import Period from "./Period";
import PeriodBeforeAfter from "./PeriodBeforeAfter";
import Filters from "./Filters";
import InfiniteScroll from "react-infinite-scroller";
import {
  storeOperations,
  storeSelector
} from "../../../../../../state/ducks/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faHandPointUp,
  faCheck,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import HelpAndPrint from "./HelpAndPrint";

const Forecast = props => {
  const { establishment, productCategories, activityTypes } = props;

  const [forecastStocksTempo, setForecastStocksTempo] = useState([]);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);
  // Utile pour les catégories du pdf
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  // Recupère les stocks avec le selector
  const forecastStocks = useSelector(state => {
    return storeSelector.getForecastStocksSelector(state);
  });

  const dispatch = useDispatch();

  const calculItemsLoaded = () => {
    let nbItemsLoaded = 0;
    forecastStocks.data &&
      forecastStocks.data.forEach(category => {
        nbItemsLoaded = nbItemsLoaded + category.products.length;
      });
    return nbItemsLoaded;
  };

  /**
   * Met à jour les stocks globalement
   */
  const saveForecastStocks = () => {
    let stockFinal = [];
    forecastStocksTempo.forEach((stockData, key) => {
      let index = stockFinal.findIndex(stockF => {
        return stockF.productId === stockData.productId;
      });

      let stockProduct = {
        quantity: stockData.stockQty,
        date: stockData.date,
        type: forecastStocks.search.types[0]
      };

      if (index > -1) {
        stockFinal[index].stocks = [...stockFinal[index].stocks, stockProduct];
      } else {
        let stock = {
          productId: stockData.productId,
          stocks: [stockProduct]
        };
        stockFinal = [...stockFinal, stock];
      }
    });

    // Vide les stocks tempo si ok
    dispatch(storeOperations.updateForecastStocks(stockFinal)).then(
      result => {
        setForecastStocksTempo([]);
        setIsUpdateSuccess(true);
        setTimeout(function() {
          setIsUpdateSuccess(false);
        }, 2000);
      },
      error => {
        setIsUpdateError(true);
        setTimeout(function() {
          setIsUpdateError(false);
        }, 2000);
      }
    );
  };

  /**
   * Pour les props des enfants, met à jour les stocks tempo
   */
  const updateForecastStocksTempo = stock => {
    let newForecastStocksTempo = forecastStocksTempo;

    const index = newForecastStocksTempo.findIndex(stockData => {
      return (
        stockData.productId === stock.productId &&
        stock.date.isSame(stockData.date, "day")
      );
    });

    if (index > -1) {
      newForecastStocksTempo[index].stockQty = stock.stockQty;
    } else {
      newForecastStocksTempo = [...newForecastStocksTempo, stock];
    }
    setForecastStocksTempo(newForecastStocksTempo);
  };

  const loadItems = (
    establishmentId,
    types,
    categories,
    weeks,
    days,
    offset,
    limit,
    isReset
  ) => {
    dispatch(
      storeOperations.getForecastStocks(
        establishmentId,
        types,
        categories,
        weeks,
        days,
        offset,
        limit,
        isReset
      )
    );
  };

  let hasMore = true;

  if (
    (forecastStocks.data && calculItemsLoaded() === forecastStocks.total) ||
    forecastStocks.isLoading
  ) {
    hasMore = false;
  }

  const resetStockTempo = () => {
    setForecastStocksTempo([]);
  };

  return (
    <>
      <Filters
        isLoading={forecastStocks.isLoading}
        establishment={establishment}
        productCategories={productCategories}
        activityTypes={activityTypes}
        resetStockTempo={resetStockTempo}
        setCategoriesFilter={setCategoriesFilter}
      />
      {forecastStocks.data && forecastStocks.data.length > 0 ? (
        <>
          <CSSTransition
            appear={true}
            in={true}
            timeout={1000}
            classNames="transition"
          >
            <Scrollmenu>
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={() =>
                  loadItems(
                    forecastStocks.search.establishmentId,
                    forecastStocks.search.types,
                    forecastStocks.search.categories,
                    forecastStocks.search.weeks,
                    forecastStocks.search.days,
                    calculItemsLoaded(),
                    forecastStocks.search.limit,
                    false
                  )
                }
                hasMore={hasMore}
                loader={<Loader items={2} key={0} />}
              >
                {forecastStocks.data &&
                  forecastStocks.data.map((category, categoryKey) => (
                    <Category key={"type-"+forecastStocks.search.types+"-category-" + categoryKey}>
                      <h1>{category.name}</h1>
                      {category.products.map((product, productKey) => (
                        <div
                          key={
                            "type-"+forecastStocks.search.types+"-category-" + categoryKey + "-product-" + product.id
                          }
                        >
                          {product.periods.map((period, weekKey) => (
                            <>
                              {forecastStocks.search.types.includes("shop_v2") ?
                                <PeriodBeforeAfter
                                  key={
                                    "type-"+
                                    forecastStocks.search.types+
                                    "-category-" +
                                    categoryKey +
                                    "-product-" +
                                    product.id +
                                    "-week-" +
                                    period.dates.start
                                  }
                                  keyPeriod={"type-"+forecastStocks.search.types+"-category-"+categoryKey +
                                    "-product-"+product.id+"-week-"+period.dates.start
                                  }
                                  type={forecastStocks.search.types}
                                  period={period}
                                  productName={product.name}
                                  productId={product.id}
                                  line={productKey % 2 !== 0 ? "impair" : "pair"}
                                  updateForecastStocksTempo={
                                    updateForecastStocksTempo
                                  }
                                />
                              :
                                <Period
                                  key={
                                    "type-"+
                                    forecastStocks.search.types+
                                    "-category-" +
                                    categoryKey +
                                    "-product-" +
                                    product.id +
                                    "-week-" +
                                    period.dates.start
                                  }
                                  keyPeriod={"type-"+forecastStocks.search.types+"-category-"+categoryKey +
                                    "-product-"+product.id+"-week-"+period.dates.start
                                  }
                                  period={period}
                                  productName={product.name}
                                  productId={product.id}
                                  line={productKey % 2 !== 0 ? "impair" : "pair"}
                                  updateForecastStocksTempo={
                                    updateForecastStocksTempo
                                  }
                                />
                              }
                            </>
                          ))}
                          <Clear />
                        </div>
                      ))}
                    </Category>
                  ))}
              </InfiniteScroll>
            </Scrollmenu>
          </CSSTransition>
          <CSSTransition
            appear={true}
            in={true}
            timeout={1000}
            classNames="transition"
          >
            <Save
              onClick={() => saveForecastStocks()}
              disabled={forecastStocks.isLoading}
              type={
                isUpdateError ? "error" : isUpdateSuccess ? "success" : null
              }
            >
              {forecastStocks.isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : isUpdateSuccess ? (
                <FontAwesomeIcon icon={faCheck} size="lg" />
              ) : isUpdateError ? (
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
              ) : (
                "Sauvegarder"
              )}
            </Save>
          </CSSTransition>
        </>
      ) : (
        <Help>
          <p>
            <FontAwesomeIcon icon={faHandPointUp} size="lg" />
            <br />
            Lancer une recherche ci-dessus pour voir la prévision de vos stocks
            ou modifier directement votre taux d'activité !
          </p>
        </Help>
      )}
      {activityTypes && (
        <HelpAndPrint
          establishment={establishment}
          activityTypes={activityTypes}
          resetStockTempo={resetStockTempo}
          categoriesFilter={categoriesFilter}
        />
      )}
    </>
  );
};

const Clear = styled.div`
  both: clear;
`;

const Help = styled.div`
  margin-top: 1.5rem;
  p {
    color: #bbb;
    text-align: center;
    font-size: ${props => props.theme.fonts.size.l};
  }
`;

const Category = styled.div`
  h1 {
    text-align: left;
    padding: 0rem 0.5rem;
    margin-bottom: 0rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: grey;
  }
  margin: 0 auto;
  position: relative;
  color: #444;
  line-height: 20px;
  background: #fff;
  background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#d9eaf3),
      color-stop(4%, #fff)
    )
    0 4px;
  background: -webkit-linear-gradient(top, #d9eaf3 0%, #fff 6%) 0 4px;
  background: -moz-linear-gradient(top, #d9eaf3 0%, #fff 6%) 0 4px;
  background: -ms-linear-gradient(top, #d9eaf3 0%, #fff 6%) 0 4px;
  background: -o-linear-gradient(top, #d9eaf3 0%, #fff 6%) 0 4px;
  background: linear-gradient(top, #d9eaf3 0%, #fff 6%) 0 4px;
  background-size: 100% 20px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
`;

const Scrollmenu = styled.div`
  scroll-direction: horizontal;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const Save = styled.button`
  border: solid 1px
    ${props =>
      props.type === "error"
        ? props.theme.colors.red
        : props.type === "success"
        ? props.theme.colors.darkgreen
        : props.theme.colors.green};
  background-color: ${props =>
    props.type === "error"
      ? props.theme.colors.red
      : props.type === "success"
      ? props.theme.colors.darkgreen
      : props.theme.colors.green};
  padding: 1rem;
  border-radius: 4px;
  text-transform: uppercase;
  color: white;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.18);
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: inline-block;
  transition: 300ms;
  min-width: 152px;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  &:hover {
    border: solid 1px ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.black};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

Forecast.propTypes = {
  establishment: PropTypes.string,
  activityTypes: PropTypes.array,
  productCategories: PropTypes.array
};

export default Forecast;
