import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "state/utils";

/* State shape
{
  customerPoolSuspects: [],
  isLoading: bool
}
*/
const initData = {
  customerPoolSuspects: [],
  isLoading: false,
  search: {
    firstname: null
  }
}

const customerReducer = createReducer(initData)({
  [actionTypes.INIT_ADMIN_CUSTOMER_POOL_SUSPECT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.INIT_ADMIN_CUSTOMER_POOL_SUSPECT_COMPLETED]: (state, action) => {
    return { ...state,
      customerPoolSuspects: action.payload.result.pools,
      isLoading: false
    };
  },

  [actionTypes.POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION]: (state, action) => {
    return { ...state };
  },

  [actionTypes.POST_SUSPECT_POOL_CUSTOMER_NOTIFICATION_COMPLETED]: (state, action) => {
    return { ...state };
  },

  [actionTypes.UPDATE_SUSPECT_POOL_CUSTOMER_TR_FREEZE_COMPLETED]: (state, action) => {
    const {customerId} = action.payload;
    const index = state.customerPoolSuspects.findIndex(element => element.customer.id === customerId);
    let newCustomerPool = state.customerPoolSuspects[index];
    newCustomerPool = {
      ...newCustomerPool,
      customer: {
        ...newCustomerPool.customer,
        tr_freeze: !newCustomerPool.customer.tr_freeze
      }
    }

    return {
      ...state,
      customerPoolSuspects: [
        ...state.customerPoolSuspects.slice(0, index),
        newCustomerPool,
        ...state.customerPoolSuspects.slice(index + 1),
      ]
    };
  },

  [actionTypes.SEARCH_SUSPECT_POOL_CUSTOMER]: (state, action) => {
    const {lastname} = action.payload;
    return {
      ...state,
      search: {
        lastname: lastname
      }
    };
  },

});


export default combineReducers({
  pool: customerReducer,
});
