import * as types from "./types";
import { createReducer } from "../../utils";
import { combineReducers } from "redux";

/* State shape
{
    error: [ object ]
    success: [ text ]
}
*/

const errorReducer = createReducer(null)({
  [types.GET_RESPONSE_ERROR]: (state, action) => {
    const { response } = action.payload.error;
    return { ...state, response };
  },
  [types.CLEAR_ERROR]: (state, action) => {
    return null;
  },
});

const successReducer = createReducer(null)({
  [types.GET_RESPONSE_SUCCESS]: (state, action) => {
    const { message } = action.payload;
    return { ...state, message: message };
  },
  [types.CLEAR_SUCCESS]: (state, action) => {
    return null;
  },
});

export default combineReducers({
  error: errorReducer,
  success: successReducer,
});
