import * as actionTypes from "./types";

/**
 * Recupère les données pour la page admin des clients
 * @param  {int} establishmentId
 */
export const initPage = (establishmentId) => ({
  type: actionTypes.INIT_ADMIN_STORE_CUSTOMERS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/customers.json`,
    method: "GET",
    params: {
      establishment: establishmentId,
    }
  }
});

/**
 * Recupère les infos d'un client store sans les commandes
 */
export const getStoreCustomerInfo = (customer) => ({
  type: actionTypes.GET_STORE_CUSTOMER_INFO,
  payload: {
    customer: customer,
  }
});

/**
 * Recupère les données de commande d'un client store
 */
export const getStoreCustomerOrders = (customer) => ({
  type: actionTypes.GET_STORE_CUSTOMER_ORDERS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/customers/${customer.id}/orders.json`,
    method: "GET",
  },
  payload: {
    customer: customer,
  }
});

/**
 * Permet de mettre à jour les données d'un client
 */
export const updateStoreCustomer = (customer) => ({
  type: actionTypes.UPDATE_STORE_CUSTOMER,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/customers/${customer.id}.json`,
    method: "PUT",
    body: {
      ...customer
    }
  },
});

/**
 * Permet d'ajouter un client
 * Par défaut, on ajoute le créé par l'admin et pour le mode kiosque car l'action existe justement pour le mode kiosque
 */
export const addStoreCustomer = (data) => ({
  type: actionTypes.POST_STORE_CUSTOMER,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/customers/customers.json`,
    method: "POST",
    body: {...data, createdByAdmin: true, createdForKiosk: true}
  },
});

export const sendCodeBySMS = (customer) => ({
  type: actionTypes.STORE_CUSTOMER_SEND_CODE_BY_SMS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/customers/sendcodebysms.json`,
    method: "POST",
    body: {
      customerId: customer.id
    },
  },
});