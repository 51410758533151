import React, { useState } from "react";
import styled from "@emotion/styled";
import { Row, Col, Form, InputGroup, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMeh,
  faSmileBeam
} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { ticketRestaurantOperations } from "state/ducks/store/ticketRestaurant";
import { notificationsOperations } from "state/ducks/notifications";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import Tippy from '@tippyjs/react';
import CustomerNotificationButton from "../Elements/CustomerNotificationButton";

const TicketRestaurant = (props) => {

  const {
    ticketRestaurant,
    getCustomerPool
  } = props

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError]     = useState(false);

  const [trNote]                 = useState(ticketRestaurant.ticket.note);
  const [modifiedAmount, setModifiedAmount] = useState(ticketRestaurant.ticket.amount/100);

  /**
   * Met à jour la note du TR
   */
  const submitNote = (id, note) => {
    if(note === ''){
      note = null;
    }
    if (trNote !== note ) {
      dispatch(ticketRestaurantOperations.updateAdminTicketRestaurant(id, {note: note !== null ? note : "-" })).then(
        (result) => {
          dispatch(notificationsOperations.getResponseSuccess('La prise de note pour ce client a bien été enregistré'));
        }
      )
    }
  }

  const submitTR = (id, modifiedAmount, suspect, reason) => {
      setIsLoading(true);
      dispatch(ticketRestaurantOperations.updateAdminTicketRestaurant(id, {modifiedAmount:JSON.stringify(modifiedAmount), suspect: suspect, reason: reason})).then(
        (result) => {
          setIsLoading(false);
          setIsSuccess(true);
        },
        (error) => {
          setIsLoading(false);
          setIsError(true);
          setTimeout(function () {
            setIsError(false);
          }, 2000);
        }
      )
  }

  return (
    <>
      <ListStock>
        <Cell xs={6} sm={4} md={2} className="vAlign">
          <p>
            <small>TR déclaré</small>
            <br/><b>{ticketRestaurant.ticket.amount / 100} €</b>
            <Historic
              disabled={isLoading}
              onClick={(e) => getCustomerPool(ticketRestaurant.customerId, e)}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              )
              : (
                <>Historique  &nbsp;&nbsp;<FontAwesomeIcon icon={faChevronRight} /></>
              )}
            </Historic>
          </p>
        </Cell>

        <Cell xs={6} sm={4} md={2} className="vAlign">
          <p>
            <small>Rectif</small>
          </p>
          {isSuccess ?
            <div>{modifiedAmount} €</div>
            :
            <InputGroup>
              <Form.Control
                type="text"
                defaultValue={ticketRestaurant.ticket.amount / 100}
                onChange={(e) => setModifiedAmount(e.target.value)}
                placeholder="Montant"
                onBlur={() => swal ( "Ne pas oublier" ,  "Avant de valider une rectification, merci de contacter le client pour clarifier la situation." ,  "info" )}
              />
              <InputGroup.Prepend>
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          }
        </Cell>
        <Cell xs={6} sm={4} md={2} className="vAlign">
          <p>
            <small>A commandé le</small>
            <br/>{moment(ticketRestaurant.ordered_at).format("DD/MM/YYYY")} <CustomerNotificationButton customerId={ticketRestaurant.customerId}/>
          </p>
        </Cell>
        <Cell xs={12} sm={4} md={4} className="vAlign">
          <Textarea
            type="textarea"
            defaultValue={trNote}
            onBlur={(e) => submitNote(ticketRestaurant.ticket.id, e.target.value)}
            placeholder="Noter une information de suivi ..."
          />
        </Cell>
        <Save xs={12} sm={4} md={2} className="vAlign">
          {isSuccess ?
            <Response> <FontAwesomeIcon icon={faCheck} size="lg" color="#429853" /> Traitée</Response>
            :
            <>
              <Tippy content={<span>Annuler ce TR</span>}>
                <ActionList drop="left">
                  <Dropdown.Toggle
                    variant="danger"
                    id="dropdown-basic"
                    status={isError ? 'error' : isSuccess ? 'success' : null}
                    type="cancel"
                    disabled={isLoading}
                    >
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                    )
                    : (
                      <><FontAwesomeIcon icon={faTimes} /></>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item  onClick={() => submitTR(ticketRestaurant.ticket.id, 0, false, "customerCall")}>Suite appel client</Dropdown.Item>
                    <Dropdown.Item  onClick={() => submitTR(ticketRestaurant.ticket.id, 0, false, "noNews")}>Silence radio</Dropdown.Item>
                    <Dropdown.Item  onClick={() => submitTR(ticketRestaurant.ticket.id, 0, false, "regularisation")}>Le client a régularisé</Dropdown.Item>
                  </Dropdown.Menu>

                </ActionList>
              </Tippy>

              <Tippy content={<span>Valider ce TR comme suspect</span>}>
                <Action
                  status={isError ? 'error' : isSuccess ? 'success' : null}
                  type="suspicious"
                  disabled={isLoading}
                  onClick={() => submitTR(ticketRestaurant.ticket.id, Math.round(modifiedAmount * 100), true, null)}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                  )
                  : (
                    <><FontAwesomeIcon icon={faMeh} /></>
                  )}
                </Action>
              </Tippy>
              <Tippy content={<span>Valider ce TR</span>}>
                <Action
                  status={isError ? 'error' : isSuccess ? 'success' : null}
                  type="validate"
                  disabled={isLoading}
                  onClick={() => submitTR(ticketRestaurant.ticket.id, Math.round(modifiedAmount * 100), false, null)}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                  )
                  : (
                    <><FontAwesomeIcon icon={faSmileBeam} /></>
                  )}
                </Action>
              </Tippy>
            </>
          }
        </Save>
       </ListStock>
    </>
  );
};

const Textarea = styled(Form.Control)`
  border: none;
`;

const Response = styled.p`
  margin-top: 1.5rem;
`;

const ListStock = styled(Row)`
  margin: 0.3rem 0 0.3rem 1.0rem;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;

const Cell = styled(Col)`
  border-left: #dedede 1px solid;
  border-bottom: #dedede 1px solid;
  padding: 0.5rem 0.8rem;
  p {
    small {
      color: ${(props) => props.theme.colors.green};
      font-size: ${(props) => props.theme.fonts.size.s};
    }
    font-size: ${(props) => props.theme.fonts.size.s};
    margin-bottom: 0px;
  }
`;

const Historic = styled.button`
  background-color: ${(props) => props.theme.colors.black };
  transition: 300ms;
  width: auto;
  margin-left: 0.5rem;
  padding: 0.1rem 0.5rem;
  color: white;
  border: none;
  border-radius: 3px;
  min-height: 2rem;
  font-size: ${(props) => props.theme.fonts.size.xs};
  &:hover {
    opacity: 0.7;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`

const Action = styled.button`
  transition: 300ms;
  width: auto;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border: none;
  font-size: 1.6rem;
  width: 33%;
  z-index: 0;
  background-color:
    ${(props) => props.status === 'error'
      ? props.theme.colors.red
      : props.status === 'success'
      ? props.theme.colors.darkgreen
      : props.type === "cancel"
      ? props.theme.colors.red
      : props.type === "suspicious"
      ? props.theme.colors.orange
      : props.type === "validate"
      ? props.theme.colors.green
      : props.theme.colors.green
    };
  color: white;
  min-height: 3rem;
  &:hover {
    opacity: 0.7;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`

const ActionList = styled(Dropdown)`
  transition: 300ms;
  width: auto;
  display: inline-block;
  padding: 0;
  border: none;
  width: 33%;
  background-color:
    ${(props) => props.status === 'error'
      ? props.theme.colors.red
      : props.status === 'success'
      ? props.theme.colors.darkgreen
      : props.type === "cancel"
      ? props.theme.colors.red
      : props.type === "suspicious"
      ? props.theme.colors.orange
      : props.type === "validate"
      ? props.theme.colors.green
      : props.theme.colors.green
    };
  color: white;
  min-height: 3rem;
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
  button{
    min-height: 100%;
    width: 100%;
    border-radius: 0px;
    font-size: 1.6rem;
  }
`

const Save = styled(Col)`
  align-items: stretch;
  padding: 0rem;
  border-bottom: #dedede 1px solid;
  flex-direction: row;
`;


TicketRestaurant.propTypes = {

};

export default TicketRestaurant;
