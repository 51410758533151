import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { storeOperations } from "state/ducks/store";
import styled from "@emotion/styled";
import { Form, Col, Row } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "react-dates/lib/css/_datepicker.css";

const Filters = (props) => {
  require("moment/locale/fr.js");

  const { establishment, compositionProductCategories, isLoading } = props;

  const [searchValue, setSearchValue] = useState("");
  const [categories, setCategories] = useState({
    value: "all",
    label: "Toutes les catégories",
  });

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const dispatch = useDispatch();

  return (
    <Container>
      <Form>
        <Filter>
          <Col xs={12} sm={2}>
            <Form.Control
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Trier par nom d'ingrédient ..."
            />
          </Col>
          <Col xs={12} sm={2}>
            <Select
              onChange={(selectedOption) => setCategories(selectedOption)}
              value={categories}
              options={compositionProductCategories}
              placeholder="Categorie"
            />
          </Col>
          <Col xs={12} sm={6}>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              placeholder="Trier par nom de produit ..."
            />
          </Col>
          <Col xs={12} sm={2}>
            <Search
              onClick={(event) =>
                {
                  event.preventDefault();
                  dispatch(
                    storeOperations.getRealCompositionProductStocks(
                      moment(date).format("YYYY-MM-DD"),
                      establishment,
                      categories.value,
                      searchValue,
                      true
                    )
                  )
                }
              }
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : (
                "Lancer une recherche"
              )}
            </Search>
          </Col>
        </Filter>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
`;

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Search = styled.button`
  border: solid 1px ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.black};
  padding: 0.5rem;
  color: white;
  display: inline-block;
  transition: 300ms;
  width: 100%;
  &:hover {
    border: solid 1px ${(props) => props.theme.colors.green};
    background-color: ${(props) => props.theme.colors.green};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

Filters.propTypes = {
  establishment: PropTypes.string,
  compositionProductCategories: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Filters;
