import React from "react";
import { Row, Col } from "react-bootstrap";
import { css } from "emotion";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { notificationsOperations } from "state/ducks/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Error = (props) => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const { message } = props;

  if (notifications.error || notifications.success) {
    setTimeout(function () {
      dispatch(notificationsOperations.clearError());
      dispatch(notificationsOperations.clearSuccess());
    }, 5000);
  }

  return (
    <div>
      {notifications.error && notifications.error.response && (
        <Container id="error" name={"error"} type={"error"}>
          <Col xs={10} className={vAlign}>
            {notifications.error.response.data && notifications.error.response.data.error &&
            notifications.error.response.data.error.message
              ? notifications.error.response.data.error.message
              : notifications.error.response.data.result &&
                notifications.error.response.data.result.message
              ? notifications.error.response.data.result.message
              : notifications.error.response.data &&
                notifications.error.response.data.error_description
              ? notifications.error.response.data.error_description
              : message}
          </Col>
          <Remove
            xs={2}
            className={vAlign}
            onClick={() => dispatch(notificationsOperations.clearError())}
          >
            <div className={right}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </Remove>
        </Container>
      )}

      {notifications.success && notifications.success.message && (
        <Container id="error" name={"success"} type={"success"}>
          <Col xs={10} className={vAlign}>
            {notifications.success.message
              ? notifications.success.message
              : message}
          </Col>
          <Remove
            xs={2}
            className={vAlign}
            onClick={() => dispatch(notificationsOperations.clearSuccess())}
          >
            <div className={right}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </Remove>
        </Container>
      )}
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: "Nous rencontrons un problème, merci de recharger la page.",
};

const Remove = styled(Col)`
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  cursor: pointer;
`;

const Container = styled(Row)`
  box-shadow: 0px 4px 15px 5px rgba(0,0,0,0.5);
  padding: 1rem;
  position: fixed;
  z-index: 9999;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  color: white;
  text-align: center;
  margin: auto;
  border: solid 1px
    ${(props) =>
      props.type === "error"
        ? (props) => props.theme.colors.red
        : (props) => props.theme.colors.darkgreen};
  background-color: ${(props) =>
    props.type === "error"
      ? (props) => props.theme.colors.red
      : (props) => props.theme.colors.green};
  animation: fadein 0.2s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const vAlign = css`
  display: flex;
  webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
`;

const right = css`
  text-align: right;
`;

export default Error;
