import { Stocks, TicketRestaurant, Page404, Tips, StoreCustomers, Sav } from "../views/pages/";
import { Tips as WidgetTips } from "../views/widgets/";

const routes = [
  {
    path: "/",
    component: Stocks,
    exact: true,
  },
  {
    path: "/gestion-tr",
    component:TicketRestaurant,
    exact: true,
  },
  {
    path: "/tips",
    component: Tips,
    exact: true,
  },
  {
    path: "/widget/tips",
    component: WidgetTips,
    exact: true,
  },
  {
    path: "/store-customers",
    component: StoreCustomers,
    exact: true,
  },
  {
    path: "/sav",
    component: Sav,
    exact: true,
  },
  {
    path: "*",
    component: Page404,
  },
];

export default routes;
