import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "../../../utils";

/* State shape
{
  historic: [],
}
*/

const historicReducer = createReducer([])({

  [actionTypes.GET_TIPS]: (state, action) => {
    return { ...state, isLoading: true };
  },

  [actionTypes.GET_TIPS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, data: action.payload.result };
  },

});

export default combineReducers({
  historic: historicReducer,
});
