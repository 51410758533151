/**
 * RECUPERE LES CLIENTS STORE
 */
export const INIT_ADMIN_STORE_CUSTOMERS = "store/customers/INIT_ADMIN_STORE_CUSTOMERS";
export const INIT_ADMIN_STORE_CUSTOMERS_COMPLETED = "store/customers/INIT_ADMIN_STORE_CUSTOMERS_COMPLETED";
export const INIT_ADMIN_STORE_CUSTOMERS_FAILED = "store/customers/INIT_ADMIN_STORE_CUSTOMERS_FAILED";

/**
 * RECUPERE LES INFOS HORS COMMANDE DU CLIENT
 */
export const GET_STORE_CUSTOMER_INFO = "store/customers/GET_STORE_CUSTOMER_INFO";

/**
 * RECUPERE LES COMMANDES D'UN CLIENT STORE
 */
export const GET_STORE_CUSTOMER_ORDERS = "store/customers/GET_STORE_CUSTOMER_ORDERS";
export const GET_STORE_CUSTOMER_ORDERS_COMPLETED = "store/customers/GET_STORE_CUSTOMER_ORDERS_COMPLETED";
export const GET_STORE_CUSTOMER_ORDERS_FAILED = "store/customers/GET_STORE_CUSTOMER_ORDERS_FAILED";

/**
 * Met à jour les infos clients
 */
export const UPDATE_STORE_CUSTOMER = "store/customers/UPDATE_STORE_CUSTOMER";
export const UPDATE_STORE_CUSTOMER_COMPLETED = "store/customers/UPDATE_STORE_CUSTOMER_COMPLETED";
export const UPDATE_STORE_CUSTOMER_FAILED = "store/customers/UPDATE_STORE_CUSTOMER_FAILED";

/**
 * Ajoute un client
 */
export const POST_STORE_CUSTOMER = "store/customers/POST_STORE_CUSTOMER";
export const POST_STORE_CUSTOMER_COMPLETED = "store/customers/POST_STORE_CUSTOMER_COMPLETED";
export const POST_STORE_CUSTOMER_FAILED = "store/customers/POST_STORE_CUSTOMER_FAILED";

export const STORE_CUSTOMER_SEND_CODE_BY_SMS = "store/customers/STORE_CUSTOMER_SEND_CODE_BY_SMS";
export const STORE_CUSTOMER_SEND_CODE_BY_SMS_COMPLETED = "store/customers/STORE_CUSTOMER_SEND_CODE_BY_SMS_COMPLETED";
export const STORE_CUSTOMER_SEND_CODE_BY_SMS_FAILED = "store/customers/STORE_CUSTOMER_SEND_CODE_BY_SMS_FAILED";

