import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationsOperations } from "state/ducks/notifications";
import { ticketRestaurantOperations } from "state/ducks/store/ticketRestaurant";
import {
  faSpinner,
  faCheck,
  faExclamationTriangle,
  faWallet,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import Autosuggest from 'react-autosuggest';
import swal from 'sweetalert';
import Loader from "views/universal/Loader/Loader";

const AddPoolPage = (props) => {

  require("moment/locale/fr.js");

  const {
    customers,
    isLoadingPage,
    getCustomerPool,
    operationType
  } = props;

  const dispatch = useDispatch();

  const [customerId, setCustomerId] = useState(null);

  const [customerValue, setCustomerValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const [amount, setAmount] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [note, setNote] = useState("");

  const submitPool = (customerId, amount, e, note) => {
    e.preventDefault();
    if(customerId && amount > 0){
      // Rend obligatoire la note pour le débit
      if (note.trim() === '' && operationType === 'debit') {
        return swal({
          title: "Information de suivi importante",
          text: "Merci d'indiquer une note d'information pour l'opération de débit. Ajouter la référence de la commande si cela concerne une commande non payée.",
          icon: "warning",
        });
      }
      swal({
        title: operationType === 'debit' ? "Débiter un client" : "Créditer un client",
        text: operationType === 'debit' ? "Etes-vous sûr de vouloir débiter ce client ?" : "Etes-vous sûr de vouloir créditer ce client ?",
        icon: "warning",
        buttons: {
          cancel: "Annuler",
          suspect: "Suspect & Valider",
          confirm: "Valider",
        },
      })
      .then((confirm) => {
        if (confirm === true) {
          setIsLoading(true);
          let multi = operationType === 'debit' ? -1 : 1;
          dispatch(ticketRestaurantOperations.postPoolOperations(customerId, (multi * amount * 100).toFixed(0), note, false)).then(
            (result) => {
              setIsLoading(false);
              setIsSuccess(true);
              if (operationType === 'debit') {
                dispatch(notificationsOperations.getResponseSuccess('Montant bien débité.'))
              }
              else {
                dispatch(notificationsOperations.getResponseSuccess('Montant bien crédité.'))
              }
              setAmount("");
              setNote("");
              setCustomerId(null);
              setCustomerValue("");
              setTimeout(function () {
                setIsSuccess(false);
              }, 2500);
            },
            (error) => {
              setIsLoading(false);
              setIsError(true)
              setTimeout(function () {
                setIsError(false);
              }, 2000);
            }
          )
        }else if(confirm === 'suspect'){
          setIsLoading(true);
          let multi = operationType === 'debit' ? -1 : 1;
          dispatch(ticketRestaurantOperations.postPoolOperations(customerId, (multi * amount * 100).toFixed(0), note, true)).then(
            (result) => {
              setIsLoading(false);
              setIsSuccess(true);
              if (operationType === 'debit') {
                dispatch(notificationsOperations.getResponseSuccess('Montant bien débité.'))
              }
              else {
                dispatch(notificationsOperations.getResponseSuccess('Montant bien crédité.'))
              }
              setAmount("");
              setNote("");
              setCustomerId(null);
              setCustomerValue("");
              setTimeout(function () {
                setIsSuccess(false);
              }, 2500);
            },
            (error) => {
              setIsLoading(false);
              setIsError(true)
              setTimeout(function () {
                setIsError(false);
              }, 2000);
            }
          )
        } else {

        }
      });
    }

  };

  /**
   * Fonction qui recherche parmis la liste des clients
   */
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let returnCustomers = [];

    if (inputLength !== 0) {
      returnCustomers = customers.filter((customer) => {
        // Isole chaque string apres un espace
        let values = inputValue.split(" ");
        let customerOk = true;
        if (values.length > 0) {
          values.forEach((v) => {
            if (!customer.lastname.toLowerCase().includes(v.toLowerCase()) && !customer.email.toLowerCase().includes(v.toLowerCase()) && !customer.firstname.toLowerCase().includes(v.toLowerCase())) {
              customerOk = false;
            }
          });
        }
        return customerOk;
      });
    }

    return returnCustomers;
  }

  const getSuggestionValue = suggestion => suggestion.lastname+' '+suggestion.firstname;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.lastname+' '+suggestion.firstname+' - '+suggestion.email}
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <>
      {isLoadingPage
        ?
          <Loader items={2} />
        :
          <Form>
            <h3 className="mt-5">{operationType === 'debit' ? '--- Débiter un porte-monnaie pour régularisation de TR' : '+++ Créditer un TR non déclaré' }</h3>
            <Container type={operationType}>
              <Col xs={12} sm={5}>
                <Autosuggest
                  className="form-control"
                  suggestions={suggestions.slice(0, 10)}
                  onSuggestionSelected={(event, {suggestion}) => setCustomerId(suggestion.id)}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  highlightFirstSuggestion={true}
                  inputProps={{
                   value: customerValue,
                   placeholder: 'Nom du client (à sélectionner dans la liste des suggestions)*',
                   onChange: (e, { newValue }) => setCustomerValue(newValue),
                 }}

                />
              </Col>
              <Col xs={12} sm={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    step="any"
                    placeholder="Montant*"
                  />
                  <Form.Control.Feedback type="invalid">Looks good!</Form.Control.Feedback>
                  <InputGroup.Prepend>
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </Col>
              <Col xs={12} sm={2}>
                <Search type="submit" onClick={(e) => submitPool(customerId, amount, e, note)} disabled={(isLoading || !customerId || !amount || !note) && true} state={isError ? 'error' : isSuccess ? 'success' : null}>
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                  ) : isSuccess ? (
                    <FontAwesomeIcon icon={faCheck} size="lg" />
                  ) : isError ? (
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                  ) : (
                    <> <FontAwesomeIcon icon={faPlus} /> {operationType === 'debit' ? 'Débitez ce client' : 'Créditer ce client'}</>
                  )}
                </Search>
              </Col>
              <Col xs={12} sm={2}>
                <Pool type="submit" onClick={(e) => getCustomerPool(customerId, e)} disabled={(isLoading || !customerId) && true  } state={isError ? 'error' : isSuccess ? 'success' : null}>
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                  ) : isSuccess ? (
                    <FontAwesomeIcon icon={faCheck} size="lg" />
                  ) : isError ? (
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                  ) : (
                    <> <FontAwesomeIcon icon={faWallet} /> Voir son porte-monnaie</>
                  )}
                </Pool>
              </Col>
              <Cell xs={12} sm={12} md={12} className="vAlign">
                <Textarea
                  type="textarea"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Noter une information de suivi (obligatoire)*"
                />
              </Cell>
            </Container>

          </Form>
      }
    </>
  );
};

const Container = styled(Row)`
  p {
    margin-bottom: 0rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 5rem;
  position: relative;
  color: #444;
  line-height: 20px;
  background: #fff;
  border-radius: 4px;
  padding: 1rem 0rem 0.5rem 0rem;
  margin-bottom: 1rem;
  border-left : 5px solid ${(props) => props.type === 'debit' ? 'red': 'green'};
`;

const Search = styled.button`
  border: solid 2px ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.black};
  background-color: white;
  padding: 0.5rem;
  color: white;
  margin-bottom: 0rem;
  display: inline-block;
  transition: 300ms;
  width: 100%;
  color: ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.black};
  &:hover {
    border: solid 2px ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.black};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.3;
    &:hover {
      background-color: white !important;
      color: ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.black};
    }
  }
`;

const Pool = styled.button`
  border: solid 2px ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.green};
  background-color: white;
  padding: 0.5rem;
  color: white;
  margin-bottom: 0rem;
  display: inline-block;
  transition: 300ms;
  width: 100%;
  color: ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.green};
  &:hover {
    border: solid 2px ${(props) => props.theme.colors.green};
    background-color: ${(props) => props.theme.colors.green};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.3;
    &:hover {
      background-color: white !important;
      color: ${(props) => props.state === 'error' ? props.theme.colors.red : props.state === 'success' ? props.theme.colors.darkgreen : props.theme.colors.green} !important;
    }
  }
`;

const Cell = styled(Col)`
  border-top: #dedede 1px solid;
  padding: 0.5rem 0.8rem 0rem 0.8rem;
  margin: 0.5rem 0rem;
`;

const Textarea = styled(Form.Control)`
  border: none;
`;

AddPoolPage.propTypes = {

};

export default AddPoolPage;
