import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { customersOperations } from "state/ducks/store/customers";
import { notificationsOperations } from "state/ducks/notifications";

const ToDelete = (props) => {

  const dispatch = useDispatch();

  const {
    customer,
    establishment
  } = props

  const [isLoading, setIsLoading] = useState(false);
  const updateStoreCustomer = (customer, e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(customersOperations.updateStoreCustomer(customer)).then(
      (result) => {
        setIsLoading(false);
        if(establishment){
          dispatch(customersOperations.initPage(establishment));
        }
        dispatch(notificationsOperations.getResponseSuccess('Mise à jour effectué'));
      },
      (error) => {
        setIsLoading(false);
        dispatch(notificationsOperations.getResponseError('Erreur, le statut n\'a pas pu être mis à jour'));
      }
    )
  };

  return (


    <Button
      isTodelete={customer.to_delete}
      disabled={isLoading}
      onClick={(e) => updateStoreCustomer({...customer, to_delete: !customer.to_delete}, e)}
    >
      {isLoading ? (
        <><FontAwesomeIcon icon={faSpinner} size="lg" spin /> &nbsp;&nbsp; Compte client à supprimer (manuellement par les devs)</>
      )
      : (
        <>

          {customer.to_delete
            ?
              <><FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp; Compte client à supprimer (manuellement par les devs)</>
            :
              <><FontAwesomeIcon icon={faCircle} /> &nbsp;&nbsp; Compte client à supprimer (manuellement par les devs)</>
          }

        </>
      )}
    </Button>
  );
};

const Button = styled.button`
  background-color: ${(props) => props.isTodelete ? props.theme.colors.green : '#ccc' };
  transition: 300ms;
  width: auto;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 4px;
  color: white;
  &:hover {
    opacity: 0.8;
    background-color: ${(props) => props.theme.colors.black};
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

ToDelete.propTypes = {};

ToDelete.defaultProps = {};

export default ToDelete;
