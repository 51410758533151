export const theme = {
  colors: {
    darkgreen: "#2f6f3b",
    green: "#429853",
    lightgreen: "#71c181",
    black: "#191919",
    favorite: "#e6005c",
    darkfavorite: "#b30047",
    white: "white",
    grey: "#aaaaaa",
    brown: "#a78b68",
    red: "#f55151",
    orange: "#ff9900"
  },
  fonts: {
    family: {
      main: "BrandonGrotesqueWeb-Regular",
      script: "fabfeltscript-bold",
    },
    size: {
      xs: "0.8rem",
      s: "1rem",
      m: "1.2rem",
      l: "1.4rem",
      xl: "1.6rem",
    },
  },
};
