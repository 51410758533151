export const CLEAR_REAL_PRODUCT_STOCKS = "store/CLEAR_REAL_PRODUCT_STOCKS";
export const GET_REAL_PRODUCT_STOCKS = "store/GET_REAL_PRODUCT_STOCKS";
export const GET_REAL_PRODUCT_STOCKS_COMPLETED =
  "store/GET_REAL_PRODUCT_STOCKS_COMPLETED";
export const GET_REAL_PRODUCT_STOCKS_FAILED =
  "store/GET_REAL_PRODUCT_STOCKS_FAILED";
export const UPDATE_REAL_PRODUCT_STOCKS = "store/UPDATE_REAL_PRODUCT_STOCKS";
export const UPDATE_REAL_PRODUCT_STOCKS_COMPLETED =
  "store/UPDATE_REAL_PRODUCT_STOCKS_COMPLETED";
export const UPDATE_REAL_PRODUCT_STOCKS_FAILED =
  "store/UPDATE_REAL_PRODUCT_STOCKS_FAILED";
export const POST_REAL_PRODUCT_STOCKS = "store/POST_REAL_PRODUCT_STOCKS";
export const POST_REAL_PRODUCT_STOCKS_COMPLETED =
  "store/POST_REAL_PRODUCT_STOCKS_COMPLETED";
export const POST_REAL_PRODUCT_STOCKS_FAILED =
  "store/POST_REAL_PRODUCT_STOCKS_FAILED";
export const GET_REAL_PRODUCT_STOCKS_SEARCH =
  "store/GET_REAL_PRODUCT_STOCKS_SEARCH";

export const CLEAR_REAL_COMPOSITION_PRODUCT_STOCKS = "store/CLEAR_REAL_COMPOSITION_PRODUCT_STOCKS";

export const GET_REAL_COMPOSITION_PRODUCT_STOCKS = "store/GET_REAL_COMPOSITION_PRODUCT_STOCKS";
export const GET_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED =
  "store/GET_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED";
export const GET_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED =
  "store/GET_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED";

export const UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS = "store/UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS";
export const UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED =
  "store/UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED";
export const UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED =
  "store/UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED";

export const POST_REAL_COMPOSITION_PRODUCT_STOCKS = "store/POST_REAL_COMPOSITION_PRODUCT_STOCKS";
export const POST_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED =
  "store/POST_REAL_COMPOSITION_PRODUCT_STOCKS_COMPLETED";
export const POST_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED =
  "store/POST_REAL_COMPOSITION_PRODUCT_STOCKS_FAILED";
  
export const GET_REAL_COMPOSITION_PRODUCT_STOCKS_SEARCH =
  "store/GET_REAL_COMPOSITION_PRODUCT_STOCKS_SEARCH";


export const GET_FORECAST_STOCKS = "store/GET_FORECAST_STOCKS";
export const GET_FORECAST_STOCKS_COMPLETED =
  "store/GET_FORECAST_STOCKS_COMPLETED";
export const GET_FORECAST_STOCKS_FAILED = "store/GET_FORECAST_STOCKS_FAILED";
export const UPDATE_FORECAST_STOCKS = "store/UPDATE_FORECAST_STOCKS";
export const UPDATE_FORECAST_STOCKS_COMPLETED =
  "store/UPDATE_FORECAST_STOCKS_COMPLETED";
export const UPDATE_FORECAST_STOCKS_FAILED =
  "store/UPDATE_FORECAST_STOCKS_FAILED";

export const UPDATE_ACTIVITY_RATE_STOCKS = "store/UPDATE_ACTIVITY_RATE_STOCKS";
export const UPDATE_ACTIVITY_RATE_STOCKS_COMPLETED =
  "store/UPDATE_ACTIVITY_RATE_STOCKS_COMPLETED";
export const UPDATE_ACTIVITY_RATE_STOCKS_FAILED =
  "store/UPDATE_ACTIVITY_RATE_STOCKS_FAILED";

export const GET_PRODUCT_CATEGORIES = "store/GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_COMPLETED =
  "store/GET_PRODUCT_CATEGORIES_COMPLETED";
export const GET_PRODUCT_CATEGORIES_FAILED =
  "store/GET_PRODUCT_CATEGORIES_FAILED";

export const GET_ACTIVITY_TYPES = "store/GET_ACTIVITY_TYPES";
export const GET_ACTIVITY_TYPES_COMPLETED =
  "store/GET_ACTIVITY_TYPES_COMPLETED";
export const GET_ACTIVITY_TYPES_FAILED = "store/GET_ACTIVITY_TYPES_FAILED";

export const GET_PRODUCT_A_LA_CARTES = "store/GET_PRODUCT_A_LA_CARTES";
export const GET_PRODUCT_A_LA_CARTES_COMPLETED =
  "store/GET_PRODUCT_A_LA_CARTES_COMPLETED";
export const GET_PRODUCT_A_LA_CARTES_FAILED =
  "store/GET_PRODUCT_A_LA_CARTES_FAILED";

  export const GET_COMPOSITION_PRODUCT_CATEGORIES = "store/GET_COMPOSITION_PRODUCT_CATEGORIES";
  export const GET_COMPOSITION_PRODUCT_CATEGORIES_COMPLETED =
    "store/GET_COMPOSITION_PRODUCT_CATEGORIES_COMPLETED";
  export const GET_COMPOSITION_PRODUCT_CATEGORIES_FAILED =
    "store/GET_COMPOSITION_PRODUCT_CATEGORIES_FAILED";
