import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { storeOperations } from "../../../../../../state/ducks/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Filters = props => {
  require("moment/locale/fr.js");

  const daysList = [
    { value: 1, label: "Lu" },
    { value: 2, label: "Mar" },
    { value: 3, label: "Merc" },
    { value: 4, label: "Jeu" },
    { value: 5, label: "Ven" }
  ];

  const weeksList = [
    { value: "-2", label: "Semaine-2" },
    { value: "-1", label: "Semaine-1" },
    { value: "0", label: "Semaine en cours" },
    { value: "1", label: "Semaine+1" },
    { value: "2", label: "Semaine+2" }
  ];

  const {
    establishment,
    productCategories,
    activityTypes,
    isLoading,
    resetStockTempo,
    setCategoriesFilter
  } = props;

  const [categories, setCategories] = useState([]);
  const [weeks, setWeeks] = useState([
    { value: "0", label: "Semaine en cours" }
  ]);
  const [types, setTypes] = useState({ value: "spot", label: "Charrette" });
  const [days, setDays] = useState(daysList);

  const dispatch = useDispatch();

  const submitSearch = (
    establishment,
    types,
    categories,
    weeks,
    days,
    offset,
    limit,
    isReset,
    isPrint
  ) => {
    resetStockTempo();
    let typeTransform = null;
    let categoriesTransform = [];
    let weeksTransform = [];
    let daysTransform = [];

    if (types) {
      typeTransform = types.value;
    }
    if (categories) {
      categories.forEach((category, key) => {
        categoriesTransform = [...categoriesTransform, category.value];
      });
    }
    if (weeks) {
      weeks.forEach((week, key) => {
        weeksTransform = [...weeksTransform, week.value];
      });
    }
    if (days) {
      days.forEach((day, key) => {
        daysTransform = [...daysTransform, day.value];
      });
    }

    dispatch(
      storeOperations.getForecastStocks(
        establishment,
        [typeTransform],
        categoriesTransform,
        weeksTransform,
        daysTransform,
        offset,
        limit,
        isReset,
        isPrint
      )
    );
  };

  return (
    <Container>
      <Form>
        <Filter>
          <Col xs={12} sm={2}>
            <Select
              onChange={selectedOption => setTypes(selectedOption)}
              value={types}
              options={activityTypes}
              placeholder="Activités"
            />
          </Col>
          <Col xs={12} sm={3}>
            <Select
              isMulti
              onChange={selectedOption => {
                setCategories(selectedOption);
                setCategoriesFilter(selectedOption);
              }}
              value={categories}
              options={productCategories}
              placeholder="Toutes les catégories"
            />
          </Col>
          <Col xs={12} sm={3}>
            <Select
              isMulti
              onChange={selectedOption => setWeeks(selectedOption)}
              value={weeks}
              options={weeksList}
              placeholder="Semaine en cours"
            />
          </Col>
          {/*<Col xs={12} sm={3}>
            <DateRangePicker
              startDate={date.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={date.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => setDate({ startDate: startDate, endDate: endDate })} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              displayFormat={() => moment.localeData().longDateFormat('L')}
              startDatePlaceholderText='Date début'
              endDatePlaceholderText='Date fin'
              isOutsideRange={() => false}
            />
          </Col>*/}
          <Col xs={12} sm={3}>
            <Select
              isMulti
              onChange={selectedOption => setDays(selectedOption)}
              value={days}
              options={daysList}
              placeholder="Tous les jours"
            />
          </Col>
          <Col xs={12} sm={1}>
            <Search
              onClick={event => {
                event.preventDefault();
                submitSearch(
                  establishment,
                  types,
                  categories,
                  weeks,
                  days,
                  0,
                  15,
                  true,
                  false
                );
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : (
                "Rechercher"
              )}
            </Search>
          </Col>
        </Filter>
      </Form>
    </Container>
  );
};

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Container = styled.div`
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
`;

const Search = styled.button`
  border: solid 1px ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.black};
  padding: 0.5rem;
  color: white;
  display: inline-block;
  transition: 300ms;
  width: 100%;
  &:hover {
    border: solid 1px ${props => props.theme.colors.green};
    background-color: ${props => props.theme.colors.green};
    color: white;
  }
  :disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;

Filters.propTypes = {
  establishment: PropTypes.string,
  activityTypes: PropTypes.array,
  productCategories: PropTypes.array,
  isLoading: PropTypes.bool,
  resetStockTempo: PropTypes.func
};

export default Filters;
