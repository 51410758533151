import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip, Button, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPrint } from "@fortawesome/free-solid-svg-icons";
import PdfButton from "./Pdf/PdfButton";

const HelpAndPrint = props => {
  const { establishment, activityTypes, categoriesFilter } = props;

  // Met tous les types d'activité dans un tableau
  let allTypes = [];
  if (activityTypes.length > 0) {
    activityTypes.forEach(type => {
      allTypes = [...allTypes, type.value];
    });
  }

  return (
    <Container>
      <OverlayTrigger
        trigger="click"
        key={"pdf-top"}
        placement={"top"}
        overlay={
          <Popover id={`popover-positioned-top`}>
            <Popover.Title as="h3">
              Choisir la semaine de stock à générer
            </Popover.Title>
            <Popover.Content>
              <PdfButton
                establishmentId={establishment}
                types={allTypes}
                categoriesFilter={categoriesFilter}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <Action variant="secondary">
          <FontAwesomeIcon icon={faPrint} />
        </Action>
      </OverlayTrigger>
      <OverlayTrigger
        key={"doc-right"}
        placement={"right"}
        overlay={
          <Tooltip id={`tooltip-right`}>
            Voir la documentation sur les prévisionnels
          </Tooltip>
        }
      >
        <a
          href="https://moulindev.freshdesk.com/support/solutions/articles/48001080200-gestion-des-stocks"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Action variant="secondary">
            <FontAwesomeIcon icon={faInfo} />
          </Action>
        </a>
      </OverlayTrigger>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 1.5rem;
  bottom: 1.5rem;
  transition: 300ms;
`;

const Action = styled(Button)`
  background-color: ${props => props.theme.colors.favorite};
  width: 3rem;
  height: 3rem;
  margin: 0 0.2rem;
  border: 2px solid ${props => props.theme.colors.favorite};
  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.favorite};
    border: 2px solid ${props => props.theme.colors.favorite};
  }
`;

HelpAndPrint.propTypes = {
  establishment: PropTypes.string,
  activityTypes: PropTypes.array
};

export default HelpAndPrint;
