import * as actionTypes from "./types";

export const getForecastStocks = (
  establishmentId,
  types,
  categories,
  weeks,
  days,
  offset,
  limit,
  isReset,
  isPrint
) => ({
  type: actionTypes.GET_FORECAST_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/stocks/forecasting.json`,
    method: "GET",
    params: {
      establishment: establishmentId,
      types: types,
      categories: categories,
      weeks: weeks,
      days: days,
      offset: offset,
      limit: limit,
    },
  },
  payload: {
    establishmentId,
    types,
    categories,
    weeks,
    days,
    offset,
    limit,
    isReset,
    isPrint,
  },
});

export const updateForecastStocks = (stocks) => ({
  type: actionTypes.UPDATE_FORECAST_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/stock/forecasting.json`,
    method: "PUT",
    body: { stocks: stocks },
  },
});

export const clearRealProductStocks = () => ({
  type: actionTypes.CLEAR_REAL_PRODUCT_STOCKS,
});

export const getRealProductStocks = (
  date,
  establishment,
  category,
  name,
  isReset
) => ({
  type: actionTypes.GET_REAL_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/stocks.json`,
    method: "GET",
    params: {
      start: date,
      end: date,
      establishment: establishment,
      category: category,
      name: name,
    },
  },
  payload: {
    date,
    category,
    name,
    isReset,
  },
});

export const postRealProductStocks = (
  stockValue,
  type,
  date,
  productId,
  index,
  stockForcast
) => ({
  type: actionTypes.POST_REAL_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/products/${productId}/stocks.json`,
    method: "POST",
    body: {
      tweakStock: stockValue,
      type: type,
      date: date,
      stock: stockForcast,
    },
  },
  payload: {
    stockValue,
    type,
    index,
  },
});

export const updateRealProductStocks = (stockId, stock, type, index) => ({
  type: actionTypes.UPDATE_REAL_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/stocks/${stockId}.json`,
    method: "PUT",
    body: { tweakStock: stock },
  },
  payload: {
    type,
    index,
  },
});

export const clearRealCompositionProductStocks = () => ({
  type: actionTypes.CLEAR_REAL_COMPOSITION_PRODUCT_STOCKS,
});

export const getRealCompositionProductStocks = (
  date,
  establishment,
  category,
  name,
  isReset
) => ({
  type: actionTypes.GET_REAL_COMPOSITION_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/compositionproduct/stocks.json`,
    method: "GET",
    params: {
      start: date,
      end: date,
      establishment: establishment,
      category: category,
      name: name,
    },
  },
  payload: {
    date,
    category,
    name,
    isReset,
  },
});

export const postRealCompositionProductStocks = (
  stockValue,
  type,
  date,
  productId,
  index,
  stockForcast
) => ({
  type: actionTypes.POST_REAL_COMPOSITION_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/compositionproducts/${productId}/stocks.json`,
    method: "POST",
    body: {
      tweakStock: stockValue,
      type: type,
      date: date,
      stock: stockForcast,
    },
  },
  payload: {
    stockValue,
    type,
    index,
  },
});

export const updateRealCompositionProductStocks = (
  stockId,
  stock,
  type,
  index
) => ({
  type: actionTypes.UPDATE_REAL_COMPOSITION_PRODUCT_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/compositionproduct/stocks/${stockId}.json`,
    method: "PUT",
    body: { tweakStock: stock },
  },
  payload: {
    type,
    index,
  },
});

export const updateActivityRateStocks = (
  establishmentId,
  types,
  categories,
  weeks,
  days,
  activityPercentage
) => ({
  type: actionTypes.UPDATE_ACTIVITY_RATE_STOCKS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/stock/activitypercentages.json`,
    method: "PUT",
    body: {
      establishment: establishmentId,
      types: types,
      categories: categories,
      weeks: weeks,
      days: days,
      activityPercentage: activityPercentage,
    },
  },
});

//
export const getProductALaCartes = (
  startDate,
  endDate,
  establishment,
  category,
  name,
  offset,
  limit,
  isReset
) => ({
  type: actionTypes.GET_PRODUCT_A_LA_CARTES,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/admin/store/productalacartes.json`,
    method: "GET",
    params: {
      start: startDate,
      end: endDate,
      establishment: establishment,
      category: category,
      name: name,
      offset: offset,
      limit: limit,
    },
  },
  payload: {
    startDate,
    endDate,
    establishment,
    category,
    name,
    offset,
    limit,
    isReset,
  },
});

export const getProductCategories = () => ({
  type: actionTypes.GET_PRODUCT_CATEGORIES,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/category/product.json`,
    method: "GET",
    params: {
      excludeEmpty: 1,
    },
  },
});

export const getCompositionProductCategories = () => ({
  type: actionTypes.GET_COMPOSITION_PRODUCT_CATEGORIES,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/category/compositionproduct.json`,
    method: "GET",
    params: {
      excludeEmpty: 1,
    },
  },
});

export const getActivityTypes = () => ({
  type: actionTypes.GET_ACTIVITY_TYPES,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/product/config/activitytypes.json`,
    method: "GET",
  },
});
