import React from "react";
import { Row, Col } from "react-bootstrap";
import { Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from "prop-types";

const ResponseCard = (props) => {

  const { title, success, fails } = props;

  return (
    <Paper square elevation={0} className="mt-3">
      {(success.length > 0 || fails.length > 0) &&
        <p>{title}</p>
      }
      <Row className="mt-3">
        <Col xs={6}>
          { success.map((el, key) =>
            <Alert severity={"success"} key={key} className="mb-1">
              <AlertTitle>C'est une réussite !</AlertTitle>
              {el.mail && el.mail}: {el.description && el.description}
            </Alert>
          )}
        </Col>
        <Col xs={6}>
          { fails.map((el, key) =>
            <Alert severity={"error"} key={key} className="mb-1">
              <AlertTitle>Oups, on a un problème : </AlertTitle>
              {el.mail && el.mail}: {el.description && el.description}
            </Alert>
          )}
        </Col>
      </Row>

    </Paper>
  );
};

ResponseCard.propTypes = {
  title: PropTypes.string,
  success: PropTypes.array,
  fails: PropTypes.array
};

ResponseCard.defaultProps = {
  title: '',
  success: [],
  fails: []
};

export default ResponseCard;
