import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Layout from "views/universal/Layout/Layout";
import { savOperations } from "state/ducks/store/sav";
import { Tabs, Tab } from "react-bootstrap";
import HelpDocumentation from "views/universal/Layout/HelpDocumentation"
import { getAllUrlParams, removeURLParameter } from "utils/utils"
import Sms from "./Sms/Sms"
import CreditPool from "./CreditPool/CreditPool"

const Sav = (props) => {

  const urlParams = getAllUrlParams(props.location.search);
  const [topic, setTopic] = useState(urlParams.topic);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(savOperations.initPage());
  }, [dispatch]);

  // useEffect(() => {
  //   let search = removeURLParameter(props.location.search, 'topic');
  //   props.history.push({
  //     pathname: props.location.pathname,
  //     search: search + '&topic=' + topic
  //   })
  // }, [topic]);

  const handleChange = (topicKey) => {
    setTopic(topicKey);
    let search = removeURLParameter(props.location.search, 'topic');
    props.history.push({
      pathname: props.location.pathname,
      search: search + '&topic=' + topicKey
    });
  }

  return (
    <Layout location={props.location.search}>
      <Container>
        <Tabs
          defaultActiveKey={topic}
          onSelect={(k) => handleChange(k)}
        >
          <Tab eventKey="creditpool" title="Créditer des cagnottes">
            <CreditPool/>
          </Tab>
          <Tab eventKey="sms" title="SMS">
            <Sms/>
          </Tab>
        </Tabs>
      </Container>

      <HelpDocumentation title="Documentation sur le sav" url="https://moulindev.freshdesk.com/support/solutions/folders/48000667291"/>
    </Layout>
  );
};

const Container = styled.div`
  padding: 1rem;
`;

Sav.propTypes = {};

Sav.defaultProps = {};

export default Sav;
