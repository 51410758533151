import React from "react";
import PropTypes from "prop-types";
import { css, keyframes } from "emotion";
import { Col, Row } from "react-bootstrap";

const FakeDelivery = (props) => {
  const { items } = props;

  let itemsFake = [];

  for (let i = 0; i < items; i++) {
    itemsFake = [...itemsFake, i];
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  return (
    <div>
      {itemsFake.map((itemFake, i) => (
        <Row
          key={i}
          className={css`
            background: white;
            margin: 0.7rem 0rem;
            border-radius: 0.2rem;
            transition: 400ms;
            animation: ${flash} ${getRandomInt(2, 5)}s ease infinite;
            transform-origin: center bottom;
          `}
        >
          <Col xs={12} md={12} className={css(styles.col)}>
            <h3 className={css(styles.statut)}>
              <span className={css(styles.fake)}>fake</span>
            </h3>
            <p className={css(styles.para)}></p>
            <p className={css(styles.para2)}></p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

const styles = {
  col: {
    padding: "1.0rem",
  },
  statut: {
    backgroundColor: "#dedede",
    height: "11px",
    width: "25%",
  },
  para: {
    marginTop: "0.4rem",
    marginBottom: "0rem",
    backgroundColor: "#dedede",
    height: "13px",
    width: "75%",
  },
  para2: {
    marginTop: "0.4rem",
    marginBottom: "0rem",
    backgroundColor: "#dedede",
    height: "13px",
    width: "45%",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "row",
  },
  center: {
    textAlign: "center",
  },
  noImg: {
    backgroundColor: "#dedede",
    textAlign: "center",
    color: "#ccc",
    wordBreak: "break-word",
  },
  img: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100px",
    textAlign: "center",
  },
  fake: {
    display: "none",
  },
};
const flash = keyframes`
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0.4;
  }
  `;

FakeDelivery.propTypes = {
  items: PropTypes.number,
};

FakeDelivery.defaultProps = {
  items: 1,
};

export default FakeDelivery;
