import React from "react";
import { Text, View } from "@react-pdf/renderer";

export const DayColumns = props => {
  const { start, end, days, styles } = props;

  return (
    <View style={styles.border}>
      <View style={styles.product}>
        <Text style={styles.text}>
          Du {start + " au " + end}
        </Text>
      </View>
      {days.map((day, dayIndex) => (
        <View style={styles.days} key={dayIndex}>
          <Text style={styles.text}>{day}</Text>
        </View>
      ))}
    </View>
  );
};
