import * as actionTypes from "./types";

export const getTips = ( startDate, endDate, establishment) => ({
  type: actionTypes.GET_TIPS,
  meta: {
    async: true,
    blocking: true,
    path: `/v2/store/order/tips.json`,
    method: "GET",
    params: {
      start: startDate,
      end: endDate,
      establishment: establishment,
    },
  },
});
