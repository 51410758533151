import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Col, Row, Form } from "react-bootstrap";
import { operations } from "state/ducks/store/customer";

const Filters = (props) => {

  const [lastname, setLastname] = useState(null);

  const dispatch = useDispatch();

  return (
    <Container>
      <Filter>
        <Col xs={12} sm={4}>
          <Form.Control
            type="text"
            defaultValue={lastname}
            onChange={(e) => {
              dispatch(operations.searchSuspectPoolCustomer(e.target.value));
              setLastname(e.target.value)
            }}
            placeholder="Nom du client"
          />
        </Col>
        <Col xs={12} sm={4}></Col>
        <Col xs={12} sm={4}></Col>
      </Filter>
    </Container>
  );
};

const Filter = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Container = styled.div`
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: linear-gradient(130deg, rgb(66, 152, 83), rgb(144, 163, 69));
`;

Filters.propTypes = {
  establishment: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Filters;
